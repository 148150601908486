import LivoIcon from "assets/LivoLogo";
import React, { useEffect, useState, useRef } from "react";

interface MultipleLineInputProps {
    setInputValue: (value: string) => void;
    inputValue: string;
    placeHolder?: string;
    errorMessage?: string;
    callToActionIcon?: string;
}

export const MultipleLineInput: React.FC<MultipleLineInputProps> = ({
    setInputValue,
    inputValue,
    placeHolder,
    errorMessage,
    callToActionIcon,
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);



    const handleInputValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    };


    const adjustHeight = () => {
        if (textareaRef.current) {
            // Reset the height to auto to calculate the scrollHeight correctly
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        // Use requestAnimationFrame to ensure DOM updates are complete
        requestAnimationFrame(() => {
            adjustHeight();
        });

        // Create and observe the parent container size changes
        const observer = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                adjustHeight();
            });
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        // Clean up the observer on unmount
        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [inputValue]); // Trigger whenever input value changes

    return (
        <div ref={containerRef} className="flex flex-col w-full">
            <div
                className={`flex flex-row box-border ring-[1px] ring-solid rounded-[8px] p-small w-full space-x-small justify-start items-start bg-white self-start 
                    ${errorMessage ? 'ring-[2px] ring-red-500' : 'ring-Divider-Subtle'} focus-within:ring-Action-Secondary focus-within:ring-[2px]`}
            >
                <textarea
                    ref={textareaRef}
                    value={inputValue}
                    onChange={handleInputValue}
                    className="body-regular w-full placeholder-Text-Subtle focus:outline-none placeholder:body-regular placeholder:text-Text-Subtle overflow-y-hidden resize-none"
                    placeholder={placeHolder}

                />
                {callToActionIcon && (
                    <div className="items-center justify-center">
                        <LivoIcon name={callToActionIcon} size={24} color={'#149EF2'} />
                    </div>
                )}
            </div>
            {errorMessage && (
                <p className="info-caption mt-tiny text-Negative-500">
                    {errorMessage}
                </p>
            )}
        </div>
    );
};
