import { CircularProgress } from "@mui/material";
import { ModalContainer } from "components/common/ModalContainer";
import { useEffect, useState } from "react";

import React from "react";
import {
    CreateUpdateFacilityStaff,
    FacilityStaff,
    FacilityStaffConfiguration,
    fetchFacilityStaffConfiguration,
} from "services/facility-staff";
import { HeaderComponent } from "./HeaderComponent";
import { FacilityStaffPersonalData } from "./FacilityStaffPersonalData";
import { FacilityStaffPermissionsComponent } from "./FacilityStaffPermissions";
import { FacilityStaffUnits } from "./FacilityStaffUnits";
import { ActionButton } from "components/common/ActionButton";
import {
    CREATE_STAFF_BUTTON,
    DELETE_USER_BUTTON,
    UPDATE_STATT_BUTTON,
} from "locales/facilitystaff";

interface CreateUpdateFacilityStaffModalProps {
    onSubmit: (facilityStaff: CreateUpdateFacilityStaff, id?: number) => Promise<void>;
    edittingStaff: FacilityStaff | null;
    onClose: () => void;
    isOpen: boolean;
    onDeleteUser: () => void;
    facilityHasUnitsConfigured: boolean;
}

const CreateUpdateFacilityStaffModal: React.FC<CreateUpdateFacilityStaffModalProps> = ({
    onSubmit,
    edittingStaff,
    onClose,
    isOpen,
    onDeleteUser,
    facilityHasUnitsConfigured,
}) => {
    const [facilityStaffConfiguration, setFacilityStaffConfiguration] = useState<FacilityStaffConfiguration | null>(null);
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState<string>(edittingStaff?.firstName || "");
    const [lastName, setLastName] = useState<string>(edittingStaff?.lastName || "");
    const [email, setEmail] = useState<string>(edittingStaff?.email || "");
    const [permissions, setPermissions] = useState<string[]>(edittingStaff?.permissions.map((permission) => permission.value) || []);
    const [units, setUnits] = useState<string[]>(edittingStaff?.units.map((unit) => unit.value) || []);
    const [admin, setIsAdmin] = useState<boolean>(edittingStaff?.facilityAdmin || false);

    const invalidPersonalData = !firstName || !lastName || !email;
    const invalidPermissions = permissions.length === 0;

    const loadConfig = () => {
        setLoading(true);
        fetchFacilityStaffConfiguration()
            .then((response) => {
                setFacilityStaffConfiguration(response!!);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (isOpen) {
            loadConfig();
        }
    }, [isOpen]);

    useEffect(() => {
        setFirstName(edittingStaff?.firstName || "");
        setLastName(edittingStaff?.lastName || "");
        setEmail(edittingStaff?.email || "");
        setPermissions(edittingStaff?.permissions.map((permission) => permission.value) || []);
        setUnits(edittingStaff?.units.map((unit) => unit.value) || []);
    }, [edittingStaff]);


    const resetStaffData = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPermissions([]);
        setUnits([]);
        setIsAdmin(false);
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            style={{ width: "60%", maxWidth: "700px", minWidth: "350px" }}
            onClose={handleClose}
        >
            <div className="flex flex-col bg-white rounded-[16px] mx-auto h-[80vh]">
                {/* Header */}
                <HeaderComponent title={edittingStaff ? "Editar miembro" : "Crear miembro"} onClose={handleClose} />

                {/* Scrollable Content */}
                <div className="flex flex-col overflow-y-auto p-medium" style={{ flexGrow: 1, maxHeight: "calc(80vh - 150px)" }}>
                    {loading ? (
                        <div className="flex items-center justify-center" style={{ height: "100%" }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="flex flex-col w-full">
                            <FacilityStaffPersonalData
                                firstName={firstName}
                                lastName={lastName}
                                email={email}
                                setFirstName={setFirstName}
                                setLastName={setLastName}
                                setEmail={setEmail}
                            />
                            {facilityHasUnitsConfigured ? <FacilityStaffUnits
                                addUnit={(unit: string) => setUnits([...units, unit])}
                                availableUnits={facilityStaffConfiguration?.units || []}
                                removeUnit={(unit: string) => setUnits(units.filter((item) => item !== unit))}
                                units={units}
                            /> : null}
                            <FacilityStaffPermissionsComponent
                                permissions={permissions}
                                togglePermission={(permission: string) => {
                                    if (permissions.includes(permission)) {
                                        setPermissions(permissions.filter((item) => item !== permission));
                                    } else {
                                        setPermissions([...permissions, permission]);
                                    }
                                }}
                                permissionList={facilityStaffConfiguration?.permissions || []}
                                isAdmin={admin}
                                toggleAdmin={() => {
                                    if (admin) {
                                        const initialPermission = facilityStaffConfiguration?.permissions[0]
                                            ? facilityStaffConfiguration.permissions[0].value
                                            : "";
                                        setPermissions([initialPermission]);
                                        setIsAdmin(false);
                                    } else {
                                        const allPermissions = facilityStaffConfiguration?.permissions.map((permission) => permission.value) || [];
                                        setPermissions(allPermissions);
                                        setIsAdmin(true);
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>

                {/* Footer */}
                <div
                    className="flex justify-between items-center p-large border-t border-Divider-Default">
                    {
                        edittingStaff ? <p
                            onClick={onDeleteUser}
                            className="action-regular cursor-pointer py-medium"
                        >
                            {DELETE_USER_BUTTON}
                        </p> : <p>
                            {' '}
                        </p>}
                    <div
                        className="flex justify-end"
                    >
                        <ActionButton
                            isDisabled={invalidPersonalData || invalidPermissions}
                            isLoading={loading}
                            onClick={async () => {
                                setLoading(true);
                                await onSubmit(
                                    {
                                        firstName,
                                        lastName,
                                        email,
                                        permissions,
                                        units,
                                        facilityAdmin: admin,
                                    },
                                    edittingStaff?.id
                                );
                                resetStaffData();
                                setLoading(false);
                            }}
                        >
                            <p className="action-regular text-center py-small">
                                {edittingStaff ? UPDATE_STATT_BUTTON : CREATE_STAFF_BUTTON}
                            </p>
                        </ActionButton>

                    </div>

                </div>
            </div>
        </ModalContainer >
    );
};

export default CreateUpdateFacilityStaffModal;
