import React, { useState } from "react";
import LivoIcon from "assets/LivoLogo";

interface AcceptButtonProps {
    onClick: () => void;
    isDisabled: boolean;
}

export const AcceptButton: React.FC<AcceptButtonProps> = ({
    onClick,
    isDisabled,
}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`
                flex items-center justify-center p-small
                rounded-[80px] bg-white hover:bg-Primary-500 ring-[1px] ring-solid ring-Primary-500`}
        >
            <LivoIcon name="check" size={16} color={isHovered ? "white" :  "#149EF2"} />
        </button>
    );
}
