import { CustomInput } from "components/common/CustomInput";
import { PERSONAL_DATA_TITLE } from "locales/facilitystaff";

interface FacilityStaffPersonalDataProps {
    firstName: string;
    lastName: string;
    email: string;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    setEmail: (email: string) => void;
}

export const FacilityStaffPersonalData: React.FC<FacilityStaffPersonalDataProps> = ({
    firstName,
    lastName,
    email,
    setFirstName,
    setLastName,
    setEmail
}) => {
    return (
        <div>
            <p
                className="subtitle-regular"
            >
                {PERSONAL_DATA_TITLE}
            </p>
            <div
                className="py-medium w-full"
            >
                <CustomInput
                    selectedValue={firstName}
                    setValue={setFirstName}
                    placeHolder="Nombre"
                    label={"Nombre"}
                />
            </div>
            <div
                className="py-medium w-full"
            >
                <CustomInput
                    selectedValue={lastName}
                    setValue={setLastName}
                    placeHolder="Apellido"
                    label={"Apellido"}
                />
            </div>
            <div
                className="py-medium w-full"
            >
                <CustomInput
                    selectedValue={email}
                    setValue={setEmail}
                    placeHolder="Correo"
                    label={"Correo"}
                />

            </div>
        </div>
    )
}