import { FIRST_SHIFTER_LABEL } from "locales/professionalClaim";
import { TagComponent } from "./TagComponent";

interface FirstShifterTagProps {
  className?: string;
}

const FirstShifterTag: React.FC<FirstShifterTagProps> = ({ className }) => {
  return (
    <TagComponent
      label={FIRST_SHIFTER_LABEL}
      className={`text-Neutral-000 bg-Purple-600 ring-Purple-600 rounded-[4px] p-[4px] ${className}`}
      labelClassName="body-sm"
    />
  );
};

export default FirstShifterTag;

