import React from 'react';

interface ExpandableContainerProps {
    children: React.ReactNode;
    width?: string;
}

export const ExpandableContainer: React.FC<ExpandableContainerProps> = ({
    children,
    width = '100%'  // Default width
}) => {
    return (
        <div
            className="flex flex-col transition-width duration-100 ease-in-out" // Add transition classes
            style={{ width }}  // Inline style for dynamic width
        >
            {children}
        </div>
    );
}
