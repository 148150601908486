import { useState } from "react";
import { CustomInput } from "../../components/common/CustomInput"
import { ModalWithCloseButtonContainer } from "../../components/common/ModalWithCloseButton"
import { ActionButton } from "../../components/common/ActionButton";
import { useDispatch } from "react-redux";
import { showToastAction } from "store/actions/appConfigurationActions";
import { resetPasswordRequest } from "services/account";
import { FORGET_PASSOWRD_TITLE, FORGET_PASSWORD_BUTTON, FORGET_PASSWORD_DESCRIPTION, FORGET_PASSWORD_ERROR_MESSAGE, FORGET_PASSWORD_HEADER, FORGET_PASSWORD_SUCCESS_MESSAGE } from "locales/signIn/es";

interface ForgotPasswordModalProps {
    onClose: () => void;
    isOpen: boolean;
}

export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
    onClose,
    isOpen
}) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const resetPassword = () => {
        setLoading(true);
        resetPasswordRequest(email)
            .then(() => {
                setLoading(false);
                dispatch(showToastAction({
                    message: FORGET_PASSWORD_SUCCESS_MESSAGE,
                    severity: 'success'
                }))
                setEmail('')
                onClose();
            })
            .catch(error => {
                setLoading(false);
                onClose();
                setEmail('')
                dispatch(showToastAction({
                    message: FORGET_PASSWORD_ERROR_MESSAGE,
                    severity: 'error'
                }))
            });
    };



    return (
        <ModalWithCloseButtonContainer
            onClose={() => {
                onClose()
                setEmail('')
            }}
            isOpen={isOpen}
            title={FORGET_PASSOWRD_TITLE}
        >
            <div
                className="w-[448px]"
            >
                <div
                    className="flex flex-col p-large"
                >
                    <div
                        className="flex flex-col flex-1 space-y-medium"
                    >
                        <p
                            className="heading-sm text-Text-Subtle"
                        >
                            {FORGET_PASSWORD_HEADER}
                        </p>
                        <CustomInput
                            placeHolder="Correo electrónico"
                            inputType="email"
                            selectedValue={email}
                            setValue={setEmail}
                        />
                    </div>
                    <p
                        className="info-caption text-Text-Subtle pt-medium"
                    >
                        {FORGET_PASSWORD_DESCRIPTION}

                    </p>
                </div>
                <div
                    className="flex flex-col items-end justify-end p-large border-t border-Divider-Default"
                >
                    <div
                        className="flex flex-row self-end"
                    >
                        <ActionButton
                            isLoading={loading}
                            style={{
                                padding: '12px 24px',
                            }}
                            isDisabled={
                                email === ''
                            }
                            onClick={() => {
                                resetPassword()
                            }}
                        >
                            <p
                                className="action-regular text-center"
                            >
                                {FORGET_PASSWORD_BUTTON}
                            </p>
                        </ActionButton>

                    </div>

                </div>
            </div>
        </ModalWithCloseButtonContainer>
    )
}