import LivoIcon from "assets/LivoLogo";
import { NotificationsBadge } from "./NotificationsBadge";

interface SideBarButtonProps {
    name: string,
    isFocused: boolean,
    onClick: () => void,
    notifications?: number
}

export const SideBarButton: React.FC<SideBarButtonProps> = ({
    name,
    isFocused,
    onClick,
    notifications
}) => {
    return (
        <button
            onClick={onClick}
            className={`px-small py-small ${isFocused ? 'bg-Primary-100' : 'hover:bg-Background-Secondary'} rounded-[16px] relative`}>
            <LivoIcon name={name} size={36} color={isFocused ? '#149EF2' : '#ACBBCB'} />
            {notifications ? <div
                className="absolute top-0 right-0 p-[2px]"
            >
                <NotificationsBadge
                    notifications={notifications}
                />
            </div> : null}
        </button>
    );
}