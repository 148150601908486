import LivoIcon from "assets/LivoLogo"
import { ActionButton } from "components/common/ActionButton"
import { ACCEPT_REQUEST_LABEL, REJECT_REQUEST_LABEL } from "locales/shiftClaimDetails/es";

interface ManageClaimContainerProps {
    onAccept: () => void;
    onReject: () => void;
    inverse?: boolean;
    children?: React.ReactNode;
    acceptTitle?: string,
    rejectTitle?: string,
    hideIcons?: boolean
    disableAccept?: boolean
}

export const ManageClaimContainer: React.FC<ManageClaimContainerProps> = ({
    onAccept,
    onReject,
    inverse,
    children,
    acceptTitle,
    rejectTitle,
    hideIcons,
    disableAccept
}) => {
    const mainColor = inverse ? '#FA3D3B' : '#149EF2';
    const secondColor = inverse ? '#149EF2' : '#FA3D3B';
    return (
        <div
            className="flex flex-col w-full p-large shadow-custom rounded-[12px]"
        >
            {children}
            <div
                className="flex space-x-small justify-between w-full mt-small flex-wrap"
            >
                <div
                    className='flex flex-1'
                >
                    <ActionButton
                        isLoading={false}
                        onClick={onAccept}
                        color={mainColor}
                        isDisabled={disableAccept}
                    >
                        <div
                            className="flex items-center space-x-small"
                        >
                            <p
                                className="action-regular"
                            >
                                {acceptTitle || ACCEPT_REQUEST_LABEL}
                            </p>
                            {!hideIcons && <LivoIcon name='check' size={24} color='white' />}
                        </div>

                    </ActionButton>
                </div>
                <div
                    className='flex flex-1'
                >
                    <ActionButton
                        isLoading={false}
                        onClick={onReject}
                        color={secondColor}
                        inverse={true}
                        isDisabled={false}
                    >
                        <div
                            className={`flex items-center space-x-small ${hideIcons ? 'py-tiny' : ''}`}
                        
                        >
                            <p
                                className="action-regular"
                                style={{
                                    color: secondColor
                                }}
                            >
                                {rejectTitle || REJECT_REQUEST_LABEL}
                            </p>
                            {!hideIcons && <LivoIcon name='close' size={24} color={secondColor} />}
                        </div>

                    </ActionButton>

                </div>

            </div>
        </div>

    )
}