import LivoIcon from "assets/LivoLogo"
import { EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_BUTTON, EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE, EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE_2, EMPTY_SHIFTS_STATE_TITLE } from "locales/shiftList"

interface EmptyShiftsStateProps {
    onClick: () => void
}
export const EmptyShiftsState: React.FC<EmptyShiftsStateProps> = ({
    onClick
}) => {
    return (
        <div
            className="p-medium flex flex-col text-center items-center"
        >
            <p
                className="heading-md text-Text-Default mb-small"
            >
                {EMPTY_SHIFTS_STATE_TITLE}
            </p>
            <p
                className="body-regular text-Text-Default"
            >
                {EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE}
                <span
                    className="text-Primary-500 cursor-pointer"
                    onClick={onClick}
                >
                    {EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_BUTTON}
                </span>
                {EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE_2}
            </p>
        </div>
    )
}