import LivoIcon from "assets/LivoLogo"
import { CategoryTag } from "../../components/common/CategoryTag"
import { Category } from "../../types/shifts"

interface SelectCategoryProps {
    categories: Category[]
    onSelectingCategory: (category: Category) => void
}
export const SelectCategory: React.FC<SelectCategoryProps> = ({
    categories,
    onSelectingCategory
}) => {

    return (
        <div
            className="py-xLarge px-large flex flex-col space-y-small justify-start w-full"
        >
            {
                categories.map((category, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => onSelectingCategory(category)}
                            className="flex w-full items-center space-x-small cursor-pointer py-large px-medium rounded-[8px] hover:bg-gray-100 ring-1 ring-solid ring-Divider-Subtle justify-start"
                        >
                            <div
                                className="flex items-center space-x-small w-full"
                            >
                                <CategoryTag
                                    text={category.acronym}
                                />
                                <p
                                    className="body-regular text-Text-Subtle leading-none"
                                >
                                    {category.displayText}
                                </p>
                            </div>
                            <LivoIcon
                                name="chevron-right"
                                size={24}
                                color="#000"
                            ></LivoIcon>
                        </div>
                    )
                })
            }
        </div>
    )
}
