import { useState } from "react";
import { CustomInput } from "../common/CustomInput"
import { ModalWithCloseButtonContainer } from "../common/ModalWithCloseButton"
import { ActionButton } from "../common/ActionButton";
import { useDispatch } from "react-redux";
import { showToastAction } from "../../store/actions/appConfigurationActions";
import { VERIFY_MFA_BUTTON, VERIFY_MFA_CODE_NOT_RECEIVED, VERIFY_MFA_DESCRIPTION, VERIFY_MFA_RESEND } from "../../locales/signIn/es";
import { verifyMFARequest } from "../../services/account";

interface VerifyMFAModalProps {
    onClose: () => void;
    isOpen: boolean;
    email: string;
    onSignIn: (token: string) => void;
    resendEmail: () => void;
}

export const VerifyMFAModal: React.FC<VerifyMFAModalProps> = ({
    onClose,
    isOpen,
    email,
    onSignIn,
    resendEmail
    
}) => {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');

    const dispatch = useDispatch();

    const verifyMFA = () => {
        setLoading(true);
        verifyMFARequest(email, code)
            .then((token) => {
                setLoading(false);
                onSignIn(token!!);
                onClose();
            })
            .catch(error => {
                setLoading(false);
                onClose();
                setCode('');
                dispatch(showToastAction({
                    message: 'No se ha podido verificar la información',
                    severity: 'error'
                }))
            });
    };



    return (
        <ModalWithCloseButtonContainer
            onClose={onClose}
            isOpen={isOpen}
            title={'Verificar correo'}
        >
            <div
                className="w-[448px]"
            >
                <div
                    className="flex flex-col p-large"
                >
                    <div
                        className="flex flex-col flex-1 space-y-medium"
                    >
                        <div
                            className="flex flex-col space-y-small"
                        >
                            <p
                                className="body-regular"
                            >
                                {VERIFY_MFA_DESCRIPTION}
                            </p>
                            <p
                                className="heading-sm"
                            >
                                {email}
                            </p>
                        </div>

                        <CustomInput
                            placeHolder="----"
                            selectedValue={code}
                            setValue={setCode}
                            inputStyle={{
                                textAlign: 'center',
                            }}
                        />
                    </div>
                    <p
                        className="body-regular text-Text-Subtle pt-medium"
                    >
                       {VERIFY_MFA_CODE_NOT_RECEIVED}
                    </p>
                    <button
                        className="action-regular text-Action-Primary flex-1 flex pt-small"
                        onClick={() => resendEmail()}
                    >
                        {VERIFY_MFA_RESEND}
                    </button>
                </div>
                <div
                    className="flex flex-col items-end justify-end p-large border-t border-Divider-Default"
                >
                    <div
                        className="flex flex-row self-end"
                    >
                        <ActionButton
                            isLoading={loading}
                            style={{
                                padding: '12px 24px',
                            }}
                            isDisabled={
                                email === ''
                            }
                            onClick={() => {
                                verifyMFA()
                            }}
                        >
                            <p
                                className="action-regular text-center"
                            >
                                {VERIFY_MFA_BUTTON}
                            </p>
                        </ActionButton>

                    </div>

                </div>
            </div>
        </ModalWithCloseButtonContainer>
    )
}