import { useDispatch } from "react-redux";
import LivoIcon from "../../assets/LivoLogo";
import { AppDispatch } from "store";
import { showToastAction } from "store/actions/appConfigurationActions";
import { COPY_FIELD_SUCCESS } from "locales/shiftClaimDetails/es";

interface CopyButtonProps {
    successMessage?: string;
    text: string;
    style?: any;
    iconSize?: number;
}
export const CopyButton: React.FC<CopyButtonProps> = ({ 
    text,
    style,
    iconSize = 16,
    successMessage
 }) => {
    const dispatch = useDispatch<AppDispatch>()
    return (
        <button
            onClick={() => {
                navigator.clipboard.writeText(text);
                dispatch(showToastAction({
                    message: successMessage || COPY_FIELD_SUCCESS,
                    severity: 'success'
                }))
            }}
        >
              <div
                className="flex items-center justify-center"
                style={style}
            >
                <LivoIcon name={'copy'} size={iconSize} color={ '#149EF2'} />
            </div>
        </button>
    )
}