import { Dispatch } from 'redux';
import { fetchInternalProfessionals } from 'services/api';
import { InternalProfessionalFilter } from 'services/api';
import { InvitationStatus } from 'types/internal';
import { RootState } from "store/types";

export const setPendingRequestsCount = (count: number) => ({
    type: 'SET_PENDING_REQUESTS_COUNT',
    payload: count,
});


export const fetchPendingRequests = () => async (dispatch: Dispatch, getState: () => RootState) => {
    const filter: InternalProfessionalFilter = { invitationStatus: InvitationStatus.PENDING };
    try {
        const response = await fetchInternalProfessionals(filter);
        dispatch(setPendingRequestsCount(response.total));
    } catch (error) {
        console.error(error);
    }
};