import React, { useEffect, useState } from "react";
import LivoIcon from "assets/LivoLogo";


type Option = {
    id: string;
    name: string;
}
interface DropwDownProps {
    setOptionId: (time: string) => void;
    selectedOptionId: string;
    placeHolder?: string;
    errorMessage?: string;
    options?: Option[]; // Added options prop
    disabled?: boolean;
    label?: string;
}

export const DropwDown: React.FC<DropwDownProps> = ({
    setOptionId,
    selectedOptionId,
    placeHolder,
    errorMessage,
    options = [], // Default to an empty array if no options are provided
    disabled,
    label
}) => {
    const selectedOptionName = options.find(option => option.id === selectedOptionId)?.name;

    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option: Option) => {
        setOptionId(option.id);
        setIsOpen(false);
    };


    useEffect(() =>  {
        if (isOpen) {
          document.addEventListener("mousedown", () => setIsOpen(false));
        } else {
          document.removeEventListener("mousedown",  () => setIsOpen(false));
        }
    
        return () => {
          document.removeEventListener("mousedown",  () => setIsOpen(false));
        };
      }, [isOpen]);


    return (
        <div className="flex flex-col relative">
               {label && selectedOptionId && selectedOptionId !== "" ? <div
                className="absolute -top-small left-small bg-white px-tiny"
            >
                <p
                    className="info-overline text-Text-Subtle"
                >
                    {label}
                </p>
            </div> : null}
            <div
                className={`flex flex-row box-border ring-[1px] ring-solid rounded-[8px] px-small py-medium w-full space-x-small justify-between items-center flex-shrink-1 ${!disabled ? 'bg-white' : 'bg-Background-Secondary text-Text-Subtle'} 
                    ${errorMessage ? 'ring-[2px] ring-red-500' : isOpen ? 'ring-[2px] ring-Action-Secondary' : 'ring-Divider-Subtle'} `}

                onClick={() => setIsOpen(!isOpen)}
            >
                <p
                    className={`body-regular w-full ${selectedOptionName && !disabled ? 'text-Text-Default' : 'text-Placeholder-Default'}`}
                >
                    {selectedOptionName || placeHolder}
                </p>
                <div className="items-center justify-center">
                    <LivoIcon name={'chevron-down'} size={24} color={'#ACBBCB'} />
                </div>
            </div>
            {isOpen && options.length > 0 && (
                <ul className="absolute bg-white border-[1px] border-Divider-Default rounded-[8px] mt-tiny w-full max-h-60 overflow-auto z-20 top-full" >
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className="body-regular py-medium px-large cursor-pointer hover:bg-Background-Secondary"
                            onMouseDown={() => handleOptionClick(option)}
                        >
                            {option.name}
                        </li>
                    ))}
                </ul>
            )}
            {errorMessage && (
                <p className="info-caption mt-tiny text-Negative-500">
                    {errorMessage}
                </p>
            )}
        </div>
    );
};
