import FiltersButton from "components/common/FiltersButton";
import { TagComponent } from "components/common/TagComponent";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { FilterConfiguration } from "types/shifts";

interface FiltersRowProps {
    appliedFilter: string;
    setFilter: (filters: string) => void;
    onFiltersButtonClick: () => void;

}
export const FILTER_CONFIGURATIONS: {
    id: string,
    label: string,
    configuration: FilterConfiguration
}[] = [
    {
        id: 'all',
        label: "Todos",
        configuration: {
        }
    },
    {
        id: 'pendingClaims',
        label: "Solicitudes",
        configuration: {
            withPendingClaims: true
        }
    },
    {
        id: 'pendingToFill',
        label: "Pendientes",
        configuration: {
            isFilled: false,
        }
    },
    {
        id: 'filled',
        label: "Cubiertos",
        configuration: {
            isFilled: true,
        }
    }
]
export const FiltersRow: React.FC<FiltersRowProps> = ({
    appliedFilter,
    setFilter,
    onFiltersButtonClick
}) => {
    const filters = useSelector((state: RootState) => state.filterShifts.filters);

    return (
        <div
            className="flex space-x-tiny w-full items-center pb-large flex-wrap space-y-tiny"
        >
            {
                FILTER_CONFIGURATIONS.map((filter, index) => (
                    <TagComponent
                        key={index}
                        label={filter.label}
                        onClick={() => {
                            setFilter(filter.id)
                        }}
                        isSelected={appliedFilter.includes(filter.id)}
                    />
                ))
            }
            {
                filters && filters.length > 0 &&
                <div>
                    <FiltersButton onClick={onFiltersButtonClick}/>
                </div>
            }
        </div>
    )
}