import { modalityTags } from "utils/constants";
import { ShiftModalityEnum } from "../../types";
import LivoIcon from "assets/LivoLogo";

interface ModalityTagProps {
    modality: ShiftModalityEnum;
    style?: any;
    shortTag?: boolean;
}

export const ModalityTag: React.FC<ModalityTagProps> = ({ modality, shortTag, style }) => {
    const modalityProps = modalityTags[modality];
    return shortTag ?
        <div
            className={`items-center p-tiny rounded-[100px] bg-[${modalityProps.backgroundColor}] justify-center w-[24px] h-[24px] ${style}`}
        >
            <LivoIcon name={modalityProps.icon} size={16} color={modalityProps.color} />
        </div>
        :
        <div className={`flex flex-row items-center p-tiny rounded-[4px] border-solid  bg-[${modalityProps.backgroundColor}] ${style}`}>
            <LivoIcon name={modalityProps.icon} size={16} color={modalityProps.color} />
            <p className="subtitle-sm ml-tiny text-Text-Default">
                {modalityProps.displayText}
            </p>
        </div>
}