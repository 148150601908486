import LivoIcon from "assets/LivoLogo";
import { useEffect, useState } from "react";

interface PublishShiftInputProps {
    setValue: (value: string) => void;
    selectedValue: string;
    placeHolder?: string;
    errorMessage?: string;
    label?: string;
}

export const PublishShiftMultipleLineInput: React.FC<PublishShiftInputProps> = ({
    setValue,
    selectedValue,
    placeHolder,
    errorMessage,
    label
}) => {

    const handleInputValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };


    return (
        <div
            className="flex flex-col w-full relative"
        >
            {label && selectedValue && selectedValue !== "" ? <div
                className="absolute -top-small left-small bg-white px-tiny"
            >
                <p
                    className="info-overline text-Text-Subtle"
                >
                    {label}
                </p>
            </div> : null}
            <div
                className={`flex flex-row box-border ring-[1px] ring-solid rounded-[8px] p-small w-full space-x-small justify-start items-start bg-white flex-shrink-1 
                        ${errorMessage ? 'ring-[2px] ring-red-500' : 'ring-Divider-Subtle'} focus-within:ring-Action-Secondary focus-within:ring-[2px]`}

            >
                <textarea
                    value={selectedValue}
                    onChange={handleInputValue}
                    className="body-regular w-full placeholder-Text-Subtle focus:outline-none placeholder:body-regular placeholder:text-Text-Subtle h-[113px] max-h-[300px] min-h-[113px]"
                    placeholder={placeHolder}

                />
            </div>
            {
                errorMessage ? (
                    <p className="info-caption mt-tiny text-Negative-500">
                        {errorMessage}
                    </p>
                ) : null
            }
        </div>
    );
};
