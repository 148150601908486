import LivoIcon from "assets/LivoLogo";
import { useEffect, useState } from "react";

export interface CommonToastProps {
    backgroundColor: string;
    iconColor: string;
    iconName: string;
    message: string;
    onClose: () => void;
    style?: any;
}

export const CommonToast: React.FC<CommonToastProps> = ({
    backgroundColor,
    iconColor,
    iconName,
    message,
    onClose,
    style
}) => {
    const [show, setShow] = useState(true);

    const handleOnClose = () => {
        setTimeout(() => {
            onClose();
        }, 500);
    };
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
            handleOnClose()
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const borderColor = `ring-${iconColor}`;

    return (
        <div
            className={`flex flex-1 py-medium items-center px-small transition-all duration-500 ${show ? 'opacity-100' : 'opacity-0'} rounded-[12px] ring-[1px] ring-solid ${borderColor}`}
            style={{
                backgroundColor: backgroundColor,
                ...style
            }}
        >
            <div className="flex w-full items-center space-x-small">
                <LivoIcon name={iconName} size={24} color={iconColor} />
                <p className="flex body-sm text-Text-Default overflow-hidden whitespace-nowrap text-ellipsis flex-1 truncate">
                    {message}
                </p>
                <button onClick={onClose} className="flex items-center justify-center flex-shrink-0">
                    <LivoIcon name='close' size={24} color='#707A91' />
                </button>
            </div>
        </div>
    );
};
