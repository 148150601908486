import { FilterConfiguration } from "types/shifts"

export const setAppliedFilter = (filter: string) => {
    return {
        type: 'SET_FILTER',
        payload: filter
    }
}

export const setPublishShiftListSelectedShiftId = (shiftId: number | null ) => {
    return {
        type: 'SET_PUBLISH_SHIFT_LIST_SELECTED_SHIFT_ID',
        payload: shiftId
    }
}