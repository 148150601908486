import React, { useState } from "react";
import { createTheme } from "@mui/material";
import '../styles/SignIn.css';
import { PRIMARY, PRIMARY_DARK, SECONDARY, SECONDARY_LIGHT } from "utils/colors";
import { COOKIES_POLICY_LINK, COOKIES_POLICY_LINK_TEXT, EMAIL_PLACEHOLDER, FORGET_PASSWORD, PASSWORD_PLACEHOLDER, PRIVACY_POLICY_LINK, PRIVACY_POLICY_LINK_TEXT, SIGN_IN_BUTTON_LABEL, SIGN_IN_ERROR_MESSAGE, TERMS_AND_CONDITIONS_LINK, TERMS_AND_CONDITIONS_LINK_TEXT, TITLE } from "locales/signIn/es";
import { ApiApplicationError, SignInResponseData, signInRequest } from "services/api";
import { CustomInput } from "components/common/CustomInput";
import { ActionButton } from "components/common/ActionButton";
import { ForgotPasswordModal } from "components/signIn/ForgotPasswordModal";
import { VerifyMFAModal } from "components/signIn/VerifyMFACode";


const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {

    },
    action: {
      active: PRIMARY_DARK,
    },
    primary: {
      main: PRIMARY,
      dark: PRIMARY_DARK
    },
    secondary: {
      main: SECONDARY,
      light: SECONDARY_LIGHT
    }
  },
});

interface SignInProps {
  setToken: (token: string) => void
}

export default function SignIn({ setToken }: SignInProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("")
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false)
  const [mfaEmail, setMfaEmail] = useState("")

  const handleSubmit = () => {
    setIsLoading(true)
    signInRequest({ userName: email, password }).then((token) => {
      setIsLoading(false)
      if (token.mfaEnabled) {
        setMfaEmail(email)
        return
      }
      setToken(token.userToken)
      if (token.errorMessage) {
        setErrorMessage(token.errorMessage)
      } else {
        setErrorMessage("")
      }
    }).catch((error) => {
      setIsLoading(false)
      if (error instanceof ApiApplicationError) {
        setErrorMessage(error.message)
      } else {
        setErrorMessage(SIGN_IN_ERROR_MESSAGE)
      }
    })
  };


  return (

    <div
      className="flex flex-1 h-full w-full justify-center bg-white pt-xLarge "
    >
      <div
        className="flex flex-col justify-start space-y-medium w-[392px]"
      >
        <p
          className="heading-md"
        >
          {TITLE}
        </p>
        <CustomInput
          setValue={setEmail}
          selectedValue={email}
          placeHolder={EMAIL_PLACEHOLDER}
        />
        <CustomInput
          setValue={setPassword}
          selectedValue={password}
          placeHolder={PASSWORD_PLACEHOLDER}
          inputType="password"
        />
        {
          errorMessage && (
            <p
              className="text-red-500 body-regular text-center"
            >
              {errorMessage}
            </p>
          )
        }
        <ActionButton
          onClick={handleSubmit}
          isDisabled={false}
          isLoading={isLoading}
          color={'#375D68'}
        >
          <p
            className="action-sm text-white py-tiny"
          >
            {SIGN_IN_BUTTON_LABEL}
          </p>
        </ActionButton>
        <button
          className="action-regular text-Secondary-500 py-medium"
          onClick={() => setForgotPasswordModalOpen(true)}
        >
          <p>
            {FORGET_PASSWORD}
          </p>
        </button>
        <div className="text-center text-Action-Primary pt-huge">
          <a href={PRIVACY_POLICY_LINK} className="action-regular">{PRIVACY_POLICY_LINK_TEXT}</a>
          <span className="text-Text-Default body-regular"> | </span>
          <a href={TERMS_AND_CONDITIONS_LINK} className="action-regular">{TERMS_AND_CONDITIONS_LINK_TEXT}</a>
          <span className="text-Text-Default body-regular"> | </span>
          <a href={COOKIES_POLICY_LINK} className="action-regular">{COOKIES_POLICY_LINK_TEXT}</a>
        </div>
      </div>
      <ForgotPasswordModal
        isOpen={forgotPasswordModalOpen}
        onClose={() => setForgotPasswordModalOpen(false)}
      />
      <VerifyMFAModal
        isOpen={mfaEmail !== ""}
        onClose={() => setMfaEmail("")}
        email={mfaEmail}
        onSignIn={(token: string) => {
          setToken(token)
        }}
        resendEmail={() => {
            handleSubmit()
        }}
      />
    </div>

  );
}
