import { ShiftCardContainer } from "components/shifts/ShiftCardContainer"
import { ACTIVITY_DETAILS_EMPTY_STATE_SUBTITLE, ACTIVITY_EMPTY_STATE_SUBTITLE, ACTIVITY_EMPTY_STATE_TITLE } from "locales/calendar"

export const EmptyCardComponent = () => {
    return (
        <div
            className={`flex flex-col bg-white rounded-[16px] p-medium`}
            style={{
                minWidth: '200px',
                maxWidth: '1280px'
            }}
        >
            <p
                className="heading-sm text-Text-Default mb-small"
            >
               {ACTIVITY_EMPTY_STATE_TITLE}
            </p>
            <p
                className="body-regular text-Text-Default"
            >
                {ACTIVITY_EMPTY_STATE_SUBTITLE}
            </p>
        </div>
    )
}