import { REMOVE_FAVORITE_PROFESSIONAL_BUTTON_TITLE, REMOVE_FAVORITE_PROFESSIONAL_DESCRIPTION_1, REMOVE_FAVORITE_PROFESSIONAL_DESCRIPTION_2, REMOVE_FAVORITE_PROFESSIONAL_DISMISS_TITLE, REMOVE_FAVORITE_PROFESSIONAL_TITLE } from "locales/professionals/favorite";
import { ConfirmationModal } from "../common/ConfirmationModal";

type RemoveFavoriteProfessionalModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  professionalId: number,
  unfavoriteProfessional: (professionalId: number) => void,
}

export default function RemoveFavoriteProfessionalModal({
  isOpen,
  handleClose,
  professionalId,
  unfavoriteProfessional
}: RemoveFavoriteProfessionalModalProps) {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={REMOVE_FAVORITE_PROFESSIONAL_TITLE}
      dismissTitle={REMOVE_FAVORITE_PROFESSIONAL_DISMISS_TITLE}
      buttonTitle={REMOVE_FAVORITE_PROFESSIONAL_BUTTON_TITLE}
      onDismiss={handleClose}
      onPress={() => unfavoriteProfessional(professionalId)}
      buttonColor="#FF5A59"
    >
      <p className="text-base mb-4">
        {REMOVE_FAVORITE_PROFESSIONAL_DESCRIPTION_1}
      </p>

      <p className="text-base">
        {REMOVE_FAVORITE_PROFESSIONAL_DESCRIPTION_2}
      </p>
    </ConfirmationModal>
  )
}
