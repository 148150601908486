import { useState } from "react";
import { ShiftModalityEnum } from "../../types";
import RemoveFavoriteProfessionalModal from "./RemoveFavoriteProfessionalModal";
import { updateFacilityProfessional } from "services/professionals";
import { ProfessionalProfile } from "types/claims";
import { FAVORITE_PROFESSIONAL_LABEL, VIEW_ALL_FAVORITE_PROFESSIONALS_LABEL } from "locales/professionals/favorite";
import ToggleSwitch from "components/common/ToggleSwitch";
import FavoriteProfessionalsModal from "./FavoriteProfessionalsModal";

interface FavoriteProfessionalCardProps {
  professionalProfile: ProfessionalProfile;
  shiftId?: number;
  claimId?: number;
  className?: string;
}

export default function FavoriteProfessionalCard({
  professionalProfile,
  shiftId,
  claimId,
  className,
}: FavoriteProfessionalCardProps) {
  const { id, favorite, modality } = professionalProfile;

  const [isFavorite, setIsFavorite] = useState(favorite);
  const [removeFavoriteModalOpen, setRemoveFavoriteModalOpen] = useState(false);
  const [favoriteProfessionalsModalOpen, setFavoriteProfessionalsModalOpen] = useState(false);

  if (modality !== ShiftModalityEnum.EXTERNAL || favorite === undefined) {
    return null;
  }

  const removeFavoriteModal = <RemoveFavoriteProfessionalModal
    isOpen={removeFavoriteModalOpen}
    handleClose={() => setRemoveFavoriteModalOpen(false)}
    professionalId={id}
    unfavoriteProfessional={() => {
      updateFacilityProfessional(id.toString(), false);
      setIsFavorite(false);
      setRemoveFavoriteModalOpen(false);
    }}
  />

  const favoriteProfessionalsModal = <FavoriteProfessionalsModal
    isOpen={favoriteProfessionalsModalOpen}
    handleClose={() => setFavoriteProfessionalsModalOpen(false)}
    origin={shiftId && claimId ? { professionalId: professionalProfile.id, shiftId, claimId } : undefined}
  />

  function handleFavoritePress() {
    if (isFavorite) {
      setRemoveFavoriteModalOpen(true);
    } else {
      updateFacilityProfessional(id.toString(), true);
      setIsFavorite(true);
    }
  }

  return (
    <div className={`p-large rounded-small bg-white ${className}`}>
      <div className="flex items-start justify-between">
        <div className="flex flex-col">
          <p className="subtitle-regular mb-tiny">
            {FAVORITE_PROFESSIONAL_LABEL}
          </p>

          <button 
            onClick={() => setFavoriteProfessionalsModalOpen(true)}
            className="action-regular text-Action-Primary"
          >
            {VIEW_ALL_FAVORITE_PROFESSIONALS_LABEL}
          </button>
        </div>

        {isFavorite !== undefined && (
          <ToggleSwitch 
            checked={isFavorite}
            onChange={handleFavoritePress}
          />
        )}
      </div>

      {removeFavoriteModal}
      {favoriteProfessionalsModal}
    </div>
  );
}
