import React, { useState } from "react";
import LivoIcon from "assets/LivoLogo";

interface RejectButtonProps {
    onClick: () => void;
    isDisabled: boolean;
}

export const RejectButton: React.FC<RejectButtonProps> = ({
    onClick,
    isDisabled,
}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`
                flex items-center justify-center p-small
                rounded-[80px] bg-white hover:bg-Negative-500 ring-[1px] ring-solid ring-Negative-200 hover:ring-Negative-500`}
        >
            <LivoIcon name="close" size={16} color={isHovered ? "white" :  "#FA3D3B" } />
        </button>
    );
}
