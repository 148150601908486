export const FAVORITE_PROFESSIONALS_TITLE = 'Profesionales favoritos';
export const MANAGE_FAVORITE_PROFESSIONALS_TITLE = 'Gestionar favoritos';
export const FAVORITE_LABEL = 'Favorito';
export const OPEN_FAVORITE_PROFESSIONALS_LABEL = 'Ver profesionales favoritos';
export const FAVORITE_PROFESSIONAL_LABEL = 'Profesional favorito';
export const VIEW_ALL_FAVORITE_PROFESSIONALS_LABEL = 'Ver todos los favoritos';
export const REMOVE_FAVORITE_PROFESSIONAL_TITLE = '¿Deseas eliminar de favoritos?';
export const REMOVE_FAVORITE_PROFESSIONAL_DISMISS_TITLE = 'Volver';
export const REMOVE_FAVORITE_PROFESSIONAL_BUTTON_TITLE = 'Eliminar';
export const REMOVE_FAVORITE_PROFESSIONAL_DESCRIPTION_1 = 'Al eliminar a este profesional de tus favoritos, no se priorizará en futuras asignaciones de turnos.';
export const REMOVE_FAVORITE_PROFESSIONAL_DESCRIPTION_2 = '¿Estás seguro de que quieres continuar?';
export const ERROR_LOADING_FAVORITE_PROFESSIONALS = 'Error cargando los profesionales favoritos';
export const SEARCHING_PROFESSIONALS_DESCRIPTION = 'Estamos buscando profesionales para cubrir este turno, dando prioridad a tus profesionales favoritos para asegurar la mejor atención posible.';
