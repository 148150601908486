import LivoIcon from "assets/LivoLogo";
import moment from "moment";
import { useEffect, useState } from "react";

interface ScheduleSelectorProps {
    setStartTime: (time: string) => void;
    setEndTime: (time: string) => void;
    selectedStartTime: string;
    selectedEndTime: string;
}


export const ScheduleSelector: React.FC<ScheduleSelectorProps> = ({
    setStartTime,
    setEndTime,
    selectedStartTime,
    selectedEndTime,
}) => {
    const [startInput, setStartInput] = useState(selectedStartTime);
    const [endInput, setEndInput] = useState(selectedEndTime);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setStartInput(selectedStartTime);
        setEndInput(selectedEndTime);
    }, [selectedStartTime, selectedEndTime]);

    const handleStartInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartInput(e.target.value);
    };

    const handleEndInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndInput(e.target.value);
    };

    const handleStartInputBlur = () => {
        setIsFocused(false);
        setStartTime(startInput);
    };

    const handleEndInputBlur = () => {
        setIsFocused(false);
        setEndTime(endInput);
    };

    return (
        <div className="flex flex-row ring-[1px] ring-solid ring-Divider-Subtle rounded-[8px] px-small py-medium w-full space-x-small justify-start items-center focus-within:ring-Action-Secondary focus-within:ring-[2px]">
            <div className="p-[3px] items-center justify-center">
                <LivoIcon name='clock' size={18} color={isFocused ? '#4F989E' :'#ACBBCB'} />
            </div>
            <input
                type="time"
                value={startInput}
                onChange={handleStartInputChange}
                onBlur={handleStartInputBlur}
                className="body-regular focus:outline-none"
                onFocus={() => setIsFocused(true)}
            />
            <LivoIcon name='arrow-right' size={18} color='#ACBBCB' />
            <input
                type="time"
                value={endInput}
                onChange={handleEndInputChange}
                onBlur={handleEndInputBlur}
                className="body-regular focus:outline-none"
                style={{
                    WebkitAppearance: 'none', // Hide the default appearance for WebKit-based browsers
                }}
                onFocus={() => setIsFocused(true)}
            />
        </div>
    );
};
