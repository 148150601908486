interface ToggleSwitchProps {
  checked: boolean;
  onChange: () => void;
}

export default function ToggleSwitch({ checked, onChange }: ToggleSwitchProps) {
  return (
    <button
      onClick={onChange}
      className={`w-[36px] h-[20px] rounded-full transition-colors duration-200 ${
        checked ? 'bg-Blue-500' : 'bg-Background-Tertiary'
      }`}
    >
      <div 
        className={`w-[16px] h-[16px] rounded-full bg-white transform transition-transform duration-200 ${
          checked ? 'translate-x-[18px]' : 'translate-x-[2px]'
        }`}
      />
    </button>
  );
}