import LivoIcon from "../../assets/LivoLogo";
import { useEffect, useRef, useState } from "react";

interface InternalProfessionalHeaderProps {
    onClose: () => void;
    onEdit: () => void;
    onDelete: () => void;
    editable?: boolean;
}

export const InternalProfessionalHeader: React.FC<InternalProfessionalHeaderProps> = ({
    onClose,
    onEdit,
    onDelete,
    editable = true
}) => {
    const [editDeleteModalOpen, setEditDeleteModalOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setEditDeleteModalOpen(false);
            }
        };

        if (editDeleteModalOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editDeleteModalOpen]);

    return (
        <div className="flex items-center w-full px-large">
            <div className="flex items-center justify-start w-full">
                <button onClick={onClose}>
                    <LivoIcon name='close' size={24} color='#707A91' />
                </button>
            </div>

            {editable ? <div className="relative">
                <button
                    ref={buttonRef}
                    onClick={(e) => {
                        e.preventDefault();
                        setEditDeleteModalOpen(!editDeleteModalOpen);
                    }}
                >
                    <LivoIcon name='dots' size={24} color='#707A91' />
                </button>
                {editDeleteModalOpen && (
                    <div
                        ref={modalRef}
                        className="p-medium rounded-[8px] mb-[14px] absolute z-10 bg-white shadow-custom right-full top-0 mr-tiny w-[255px] space-y-[14px]"
                    >

                        <button
                            className="pv-tiny px-small flex w-full justify-between items-center"
                            onClick={() => {
                                onEdit()
                                setEditDeleteModalOpen(false)
                            }}
                        >
                            <p className="body-regular text-Text-Subtle mr-small">
                                Editar información
                            </p>
                            <LivoIcon name='pencil' size={24} color='#707A91' />
                        </button>
                        <button
                            className="pv-tiny px-small flex w-full justify-between items-center"
                            onClick={(e) => {
                                e.preventDefault();
                                onDelete();
                                setEditDeleteModalOpen(false)
                            }}
                        >
                            <p className="body-regular text-Negative-500 mr-small">
                                Dar de baja
                            </p>
                            <LivoIcon name='trash' size={24} color='#FA3D3B' />
                        </button>

                    </div>
                )}
            </div> : null}
        </div>
    );
};
