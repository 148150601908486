export const SIGN_IN_BUTTON_LABEL = "Iniciar sesión";
export const TITLE = "Inicia sesión";
export const EMAIL_PLACEHOLDER = 'Correo electrónico';
export const PASSWORD_PLACEHOLDER = 'Contraseña';
export const PRIVACY_POLICY_LINK_TEXT = 'Política de privacidad';
export const TERMS_AND_CONDITIONS_LINK =
  'https://www.getlivo.com/facility-terms-and-conditions';
export const TERMS_AND_CONDITIONS_LINK_TEXT = 'Términos y condiciones';
export const COOKIES_POLICY_LINK = 'https://www.getlivo.com/cookies-policy';
export const COOKIES_POLICY_LINK_TEXT = 'Política de Cookies';
export const AND = ' y ';
export const PRIVACY_POLICY_LINK = 'https://www.getlivo.com/privacy-policy';
export const FORGET_PASSWORD = '¿Has olvidado tu contraseña?';
export const FORGET_PASSOWRD_TITLE = 'Reestablecer contraseña';
export const FORGET_PASSWORD_HEADER= 'Ingresa tu correo electrónico';
export const FORGET_PASSWORD_DESCRIPTION ='Recibirás un correo con un enlace para reestablecer tu contraseña';
export const FORGET_PASSWORD_BUTTON = 'Solicitar nueva contraseña';
export const FORGET_PASSWORD_SUCCESS_MESSAGE =
  'Email enviado correctamente';
export const FORGET_PASSWORD_ERROR_MESSAGE =
  'No se ha podido enviar el email';

  export const SIGN_IN_ERROR_MESSAGE = 'Error al iniciar sesión';
  export const VERIFY_MFA_DESCRIPTION = 'Ingresa el código que te hemos enviado a: ';
  export const VERIFY_MFA_BUTTON = 'Verificar';
  export const VERIFY_MFA_RESEND = 'Reenviar';
  export const VERIFY_MFA_CODE_NOT_RECEIVED = '¿No has recibido el código?';

