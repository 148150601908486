import LivoIcon from "assets/LivoLogo";
import { TagLabel } from "components/common/TagLabel";
import { DropDownWithInput } from "components/publishShift/DropDownWithInput";
import { ADD_UNIT, NO_UNITS_SELECTED, UNITS_LABEL, UNITS_TO_SHOW } from "locales/facilitystaff";
import { useState } from "react";
import { FacilityUnit } from "services/facility-staff";

interface FacilityStaffUnitsProps {
    addUnit: (unit: string) => void;
    availableUnits: FacilityUnit[];
    removeUnit: (unit: string) => void;
    units: string[];
}

export const FacilityStaffUnits: React.FC<FacilityStaffUnitsProps> = ({
    addUnit,
    availableUnits,
    removeUnit,
    units,
}) => {
    const [isAddingUnit, setIsAddingUnit] = useState(false)
    const mappedUnits = units.map(unit => availableUnits.find(u => u.value === unit)).filter(unit => unit) as FacilityUnit[]
    const selectableUnits = availableUnits.filter(unit => !units.find(u => u === unit.value)).map(unit => ({
        id: unit.value,
        name: unit.displayName
    }))
    return (
        <div>
            <p
                className="subtitle-regular"
            >
                {UNITS_LABEL}
            </p>
            <p
                className="body-regular text-Text-Subtle"
            >
                {UNITS_TO_SHOW}
            </p>
            <div
                className="flex items-center space-x-tiny py-medium"
            >
                {units.length ?
                    mappedUnits.map((unit, index) => (
                        <TagLabel
                            key={index}
                            text={unit.displayName}
                            onRemove={() => removeUnit(unit.value)}
                        />)) :
                    <p
                        className="body-regular text-Text-Subtle"
                    >
                        {NO_UNITS_SELECTED}
                    </p>
                }
            </div>
            <div>
                {selectableUnits.length > 0 ?
                    isAddingUnit ?
                        <div
                            className="mb-medium"
                        >
                            <DropDownWithInput
                                setOptionId={(optionId) => {
                                    addUnit(optionId)
                                    setIsAddingUnit(false)
                                }}
                                selectedOptionId={''}
                                autoFocus={true}
                                placeHolder="Seleccionar unidad"
                                options={selectableUnits}
                            />
                        </div>
                        :
                        <div
                            className="py-[6px]"
                        >
                            <button
                                onClick={() => setIsAddingUnit(true)}
                                className="flex items-center space-x-tiny cursor-pointer py-[6px] pl-medium pr-small rounded-full ring-[2px] ring-Primary-500"
                            >
                                <p
                                    className="body-regular text-Primary-500"
                                >
                                    {ADD_UNIT}
                                </p>
                                <LivoIcon name='plus' size={24} color='#149EF2' />
                            </button>
                        </div>
                    : null
                }
            </div>
        </div>
    )
}