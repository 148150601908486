import { ActionButton } from "components/common/ActionButton";
import LivoIcon from "assets/LivoLogo";
import { FILTER_LABEL, FILTERS_LABEL } from "locales/shiftClaimList/es";
import { useSelector } from "react-redux";
import { RootState } from "store/types";

interface FiltersButtonProps {
    onClick: () => void;
}

export default function FiltersButton ({
    onClick,
}: FiltersButtonProps) {
    const filters = useSelector((state: RootState) => state.filterShifts.filters);
    const numberOfAppliedFilters = filters.filter((f) => f.appliedOptions.length > 0).length;

    return (
        <ActionButton
            onClick={onClick}
            isLoading={false}
            color={"#F4F5F7"}
        >
            <LivoIcon name='filter' size={16} color='#149EF2' />
            <p className="ml-tiny text-Primary-500 action-regular">
                {numberOfAppliedFilters > 0 ? FILTERS_LABEL : FILTER_LABEL}
            </p>
            { numberOfAppliedFilters > 0 &&
            <div className="ml-tiny bg-Secondary-700 subtitle-sm rounded-full w-large h-large flex items-center justify-center">
                <p className="">
                    {numberOfAppliedFilters}
                </p>
            </div>
            }
        </ActionButton>
    )
}
