import LivoIcon from "assets/LivoLogo"
import { EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE, EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE_2, EMPTY_SHIFTS_STATE_TITLE, EMTPY_SHIFTS_STATE_PUBLISH_SHIFT, PAST_EMPTY_SHIFTS_STATE_TITLE } from "locales/calendar"

export const PastEmptyShiftsState = () => {
    return (
        <div
            className="bg-white rounded-[16px] relative pl-small"
            style={{
                minWidth: '176px',
            }}
        >
            <div
                className="p-medium flex flex-col"
            >
                <p
                    className="body-regular text-Text-Default"
                >
                    {PAST_EMPTY_SHIFTS_STATE_TITLE}
                </p>
            </div>

        </div>
    )
}