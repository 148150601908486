import { ClaimRequest, ClaimStatusEnunm, SlotReason } from "types/claims"
import { ProfessionalCardHeader } from "./ProfessionalCardHeader";
import { ProfessionalFacilityExperience } from "./ProfessionalFacilityExperience";
import { ProfessionalLivoReviews } from "./ProfessionalLivoReviews";
import LivoIcon from "assets/LivoLogo";
import { PENDING_CLAIMS_TITLE } from "locales/shiftClaimDetails/es";
import { ManageClaimContainer } from "./ManageClaimContainer";
import { ProfessionalDataBody } from "./ProfessionalProfileDataBody";
import { SlotReasonDetails } from "./SlotReasonDetails";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { fetchClaimInfoAction } from "store/actions/claimActions";

interface ProfessionalClaimDetailsProps {
    shiftId: number;
    claimId: number;
    goBack: () => void;
    onAccept: () => void;
    onReject: () => void;
    onUpdateSlotReason: (slotReason: SlotReason) => void;
}
export const ProfessionalClaimDetails: React.FC<ProfessionalClaimDetailsProps> = ({
    shiftId,
    claimId,
    goBack,
    onAccept,
    onReject,
    onUpdateSlotReason
}) => {
    const { claimRequest, isLoading } = useSelector((state: RootState) => state.claimData);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchClaimInfoAction(shiftId, claimId) as any);
    }, [dispatch, shiftId, claimId]);

    return (
        <div
            className="flex flex-col w-full pb-large"
        >
            <button
                onClick={goBack}
                className="flex w-full space-x-small items-center justify-start px-small mb-small"
            >
                <LivoIcon name='arrow-left' size={24} color='#ACBBCB' />
            </button>
            {isLoading ?
                <div
                    className="flex flex-1 justify-center items-center h-full"
                >
                    <CircularProgress />
                </div> :
                <div
                    className="flex flex-col w-full h-full justify-between pb-large"
                >
                    <div
                        className="flex flex-col w-full space-y-large divide-y-[1px] divide-Divider-Default overflow-y-scroll no-scrollbar"
                    >
                        <div
                            className="flex flex-col w-full"
                        >
                            <ProfessionalCardHeader
                                professionalProfile={claimRequest.professionalProfile}
                                modality={claimRequest.modality}
                            />
                            <ProfessionalDataBody claim={claimRequest} />
                        </div>

                        <ProfessionalFacilityExperience
                            professionalProfile={claimRequest.professionalProfile}
                            shiftId={shiftId}
                            claimId={claimId}
                        />
                        {claimRequest.professionalProfile.professionalReview?.averageRating ? <ProfessionalLivoReviews
                            professionalProfile={claimRequest.professionalProfile}
                        /> : null}
                        {
                            claimRequest.status !== ClaimStatusEnunm.PENDING_APPROVAL && claimRequest.slotReasonOptions && claimRequest.slotReasonOptions.length > 0 ? <SlotReasonDetails
                                onUpdateSlotReason={onUpdateSlotReason}
                                slotReason={claimRequest.slotReason}
                                slotReasonOptions={claimRequest.slotReasonOptions}
                                claimId={claimId}
                                shiftId={shiftId}
                                slotReasonCommentDisplayed={claimRequest.slotReasonCommentDisplayed}
                            /> : null
                        }
                    </div>

                    {claimRequest.status === ClaimStatusEnunm.PENDING_APPROVAL ?
                        <div
                            className="flex flex-col w-full px-medium"
                        >
                            <ManageClaimContainer
                                onAccept={onAccept}
                                onReject={onReject}
                            >
                            </ManageClaimContainer>
                        </div>
                        : null}
                </div>
            }

        </div>
    )
}