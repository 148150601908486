import LivoIcon from "assets/LivoLogo"
import { EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_BUTTON, EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE, EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE_2, EMPTY_SHIFTS_STATE_TITLE, FILTERED_EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_BUTTON, FILTERED_EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE, FILTERED_EMPTY_SHIFTS_STATE_TITLE } from "locales/shiftList"

interface FilteredShiftsEmptyStateProps {
    onClick: () => void
}
export const FilteredShiftsEmptyState: React.FC<FilteredShiftsEmptyStateProps> = ({
    onClick
}) => {
    return (
        <div
            className="p-medium flex flex-col text-center"
        >
            <p
                className="heading-md text-Text-Default mb-small"
            >
                {FILTERED_EMPTY_SHIFTS_STATE_TITLE}
            </p>
            <p
                className="body-regular text-Text-Default"
            >
                {FILTERED_EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE}
            </p>
            <p
                className="text-Primary-500 cursor-pointer"
                onClick={onClick}
            >
                {FILTERED_EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_BUTTON}
            </p>
        </div>
    )
}