import { ShiftDetailsSection } from "components/shiftDetails/ShiftDetailsSection"
import { FILTER_CONFIGURATIONS } from "components/shiftlist/FiltersRow"
import { ShiftListComponent } from "components/shiftlist/ShiftListComponent"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApiApplicationError } from "services/api"
import { fetchShifts } from "services/shifts-calendar"
import { showToastAction, toggleInternetConnection } from "store/actions/appConfigurationActions"
import { setPublishShiftListSelectedShiftId } from "store/actions/publishShiftListActions"
import { RootState } from "store/types"
import { Shift } from "types"
import { DayShift } from "types/shifts"

export const PublishedShiftList: React.FC = () => {
    const [loadingShifts, setLoadingShifts] = useState(true)
    const [dayShifts, setDayShifts] = useState<DayShift[]>([])
    const { appliedFilter, selectedShiftId } = useSelector((state: RootState) => state.publishShiftList)
    const dispatch = useDispatch()

    const loadData = async () => {
        const filterConfiguration = FILTER_CONFIGURATIONS.find((filter) => filter.id === appliedFilter)
        return fetchShifts(
            moment().format("YYYY-MM-DD"),
            undefined,
            'ASC',
            filterConfiguration?.configuration
        ).then((response) => {
            setDayShifts(response)
        }).catch((error) => {
            dispatch(setPublishShiftListSelectedShiftId(null))
            if (error instanceof ApiApplicationError) {
                if (error.cause === 'NO_INTERNET') {
                    dispatch(toggleInternetConnection(false))
                } else {
                    dispatch(showToastAction({
                        message: error.message,
                        severity: 'error'
                    }))
                }
            }
        })
    }

    const loadShifts = async () => {
        setLoadingShifts(true)
        await loadData().then(() => {
            setLoadingShifts(false)
        }).catch((error) => {
            console.error(error)
        })
    }


    useEffect(() => {
        loadShifts()
    }, [appliedFilter])

    return (
        <div
            className="flex h-full space-x-medium justify-between"
        >
            <ShiftListComponent
                dayShifts={dayShifts}
                loading={loadingShifts}
                reloadData={loadShifts}
                shadowReload={loadData}
            />
            <ShiftDetailsSection
                reloadShifts={loadData}
                selectedShiftId={selectedShiftId}
                setSelectedShiftId={(id: number | null) => {
                    dispatch(setPublishShiftListSelectedShiftId(id))
                }}
            />
        </div>

    )
}