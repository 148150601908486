
import { CalendarDayShifts } from "components/calendar/CalendarDayShifts"
import { CalendarSummary } from "components/calendar/CalendarSummary"
import { ShiftDetailsSection } from "components/shiftDetails/ShiftDetailsSection"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { ShiftSummary, fetchShifts, fetchShiftsSummary } from "services/shifts-calendar"
import { setDateSelected, setSelectedShiftId } from "store/actions"
import { RootState } from "store/types"
import { Shift } from "types/shifts"
import { showToastAction, toggleInternetConnection } from "store/actions/appConfigurationActions"
import { ApiApplicationError } from "services/api"
import FilterShiftsModal from "components/shiftlist/FilterShiftsModal"
import { getOptionToValue } from "types/common/shiftFilters"
import moment from "moment";

export const Calendar: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [loadingShifts, setLoadingShifts] = useState(true);
    const [shifts, setShifts] = useState<Shift[]>([]);
    const { selectedDate, selectedShiftId } = useSelector((state: RootState) => state.calendarSummary);
    const [shiftSummary, setShiftSummary] = useState<ShiftSummary[]>([]);
    const [loadingShiftSummary, setLoadingShiftSummary] = useState(true);
    const [holiday, setHoliday] = useState<boolean>(false);
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const { filters } = useSelector((state: RootState) => state.filterShifts);
    const [currentMonth, setCurrentMonth] = useState(moment(selectedDate).startOf('month').format('YYYY-MM-DD'));

    const filtersApplied = filters.reduce((acc, filter) => {
        acc[filter.key] = filter.appliedOptions.map(getOptionToValue(filter));
        return acc;
    }, {} as { [key: string]: string[] });
    const categoryFilter = filtersApplied['category'];
    const unitFilter = filtersApplied['unit'];
    const loadData = async () => {
        return fetchShifts(
            selectedDate,
            selectedDate,
        ).then((response) => {
            setShifts(response.length ? response[0].shifts : []);
            setHoliday(response.length ? response[0].holiday || false : false);
        }).catch((error) => {
            dispatch(setSelectedShiftId(null))
            if (error instanceof ApiApplicationError) {
                if (error.cause === 'NO_INTERNET') {
                    dispatch(toggleInternetConnection(false))
                } else {
                    dispatch(showToastAction({
                        message: error.message,
                        severity: 'error'
                    }))
                }
            }
        });
    };

    const loadShifts = async () => {
        setLoadingShifts(true);
        await loadData().then(() => {
            setLoadingShifts(false);
        }).catch((error) => {
        });
    };

    const shadowLoadShiftSummary = async (fromDate: string, toDate: string) => {
        return fetchShiftsSummary(
            fromDate,
            toDate,
            categoryFilter,
            unitFilter
        ).then((response) => {
            setShiftSummary(response);
        }).catch((error) => {
        })
    };
    
    const reloadData = () => {
        const firstDayOfMonth = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
        const lastDayOfMonth = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
        
        loadShifts();
        shadowLoadShiftSummary(firstDayOfMonth, lastDayOfMonth);
    };
    useEffect(() => {
        const firstDayOfMonth = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
        const lastDayOfMonth = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
    
        setLoadingShiftSummary(true);
        shadowLoadShiftSummary(firstDayOfMonth, lastDayOfMonth).then(() => {
            setLoadingShiftSummary(false);
        });
    }, [currentMonth, categoryFilter?.join(','), unitFilter?.join(',')]);

    useEffect(() => {
        loadShifts();
        dispatch(setSelectedShiftId(null))
        if (selectedDate) {
            const newUrl = `${location.pathname}?date=${selectedDate}`;
            const currentUrl = `${location.pathname}${location.search}`;
            if (newUrl !== currentUrl) {
                navigate(newUrl);
            }
            
        }
    }, [selectedDate]);

    return (
        <div className="content space-y-medium">
            <div className="flex h-full space-x-medium justify-between overflow-y-hidden">
                <CalendarSummary
                    currentMonth={currentMonth} 
                    onMonthChange={setCurrentMonth}                  
                    onDayPress={(day: string) => {
                        dispatch(setDateSelected(day));
                    }}
                    shiftSummary={shiftSummary}
                    loading={loadingShiftSummary}
                    openFilter={() => setFilterModalOpen(true)}
                />
                <div className="flex flex-1 w-full overflow-x-hidden">
                    <CalendarDayShifts
                        shifts={shifts}
                        date={selectedDate.toString()}
                        loading={loadingShifts}
                        reloadData={reloadData}
                        shadowReload={reloadData}
                        holiday={holiday}
                    />
                    <ShiftDetailsSection
                        reloadShifts={reloadData}
                        selectedShiftId={selectedShiftId}
                        setSelectedShiftId={(id: number | null) => {
                            dispatch(setSelectedShiftId(id));
                        }}
                    />
                </div>
            </div>
            <FilterShiftsModal
                isOpen={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
            />
        </div>
    );
};
