import moment from "moment";
import React, { ReactNode, useEffect } from "react";
import { ShiftSummary } from "services/shifts-calendar";
import { CalendarDayItem } from "./CalendarDayItem";
import { getMonthName, timeConfiguration } from "utils";
import LivoIcon from "assets/LivoLogo";

const getDaysInMonth = (date: string) => {
    const lastDay = moment(date).endOf('month').date();
    return Array.from({ length: lastDay }, (_, i) => i + 1);
};

interface CalendarMonthProps {
    onDayPress: (day: string) => void;
    calendarSummary: ShiftSummary[];
    monthToRender: string;
    daysSelected: string[];
    onMonthChange: (month: string) => void;
    disablePastDates?: boolean;
}

export const CalendarMonth: React.FC<CalendarMonthProps> = ({
    onDayPress,
    calendarSummary,
    monthToRender,
    daysSelected,
    onMonthChange,
    disablePastDates
}) => {

    const daysInMonth = getDaysInMonth(monthToRender);
    const firstDayOfWeek = (moment(monthToRender).startOf('month').day() + 6) % 7;
    const lastDayOfWeek = moment(monthToRender).endOf('month').day();

    let days: ReactNode[] = [];

    for (let i = -firstDayOfWeek; i < 0; i++) {
        const currentDay = moment(monthToRender).clone().startOf('month').add(i, 'day');

        days.push(
            <CalendarDayItem
                key={`empty-initial-${i}`}
                day={currentDay.date().toString()}
                isToday={false}
                onPress={() => onDayPress(currentDay.format('YYYY-MM-DD'))}
                isOtherMonth={true}
                isSelected={false}
                hasAlert={calendarSummary.find((calendarSummaryDay) => currentDay.format('YYYY-MM-DD') === calendarSummaryDay.date)?.hasAlert || false}
                hasShifts={false}
                disabled={disablePastDates && currentDay.isBefore(moment(), 'day')}
            />
        );
    }

    days = days.concat(
        daysInMonth.map((day, index) => {
            const currentDay = moment(monthToRender).startOf('month').add(day - 1, 'day');
            const todayData = calendarSummary.find((calendarSummaryDay) => currentDay.format('YYYY-MM-DD') === calendarSummaryDay.date);
            return (
                <CalendarDayItem
                    key={index}
                    day={day.toString()}
                    isToday={moment(currentDay).isSame(moment(), 'day')}
                    onPress={() => onDayPress(currentDay.format('YYYY-MM-DD'))}
                    hasAlert={todayData?.hasAlert || false}
                    isSelected={daysSelected.some((daySelected) => moment(currentDay).isSame(moment(daySelected), 'day'))}
                    isOtherMonth={false}
                    hasShifts={todayData && todayData.totalShifts > 0 || false}
                    holiday={todayData?.holiday}
                    disabled={disablePastDates && currentDay.isBefore(moment(), 'day')}
                />
            );
        })
    );

    let offset = 0;
    while (days.length < 42) {
        const currentDay = moment(monthToRender).add(1, 'month').startOf('month').add(offset, 'day');
        const todayData = calendarSummary.find((calendarSummaryDay) => currentDay.format('YYYY-MM-DD') === calendarSummaryDay.date);

        days.push(
            <CalendarDayItem
                key={`empty-final-${days.length}`}
                day={currentDay.date().toString()}
                isToday={false}
                onPress={() => onDayPress(currentDay.format('YYYY-MM-DD'))}
                isOtherMonth={true}
                isSelected={false}
                hasAlert={todayData?.hasAlert || false}
                hasShifts={false}
                disabled={disablePastDates && currentDay.isBefore(moment(), 'day')}
            />
        );
        offset++;
    }

    const weeks = Array.from({ length: Math.ceil(days.length / 7) }, (_, i) =>
        days.slice(i * 7, i * 7 + 7)
    );

    const numberOfRows = weeks.length;

    const renderedWeeks = weeks.map((week, index) => (
        <div key={`week-${index}`} className={`flex flex-row space-x-small`}>
            {week}
        </div>
    ));

    return (
        <div className="flex flex-col p-small items-start  self-start">
            <div
                className="flex flex-row mb-small space-x-tiny justify-between items-center w-full"
            >
                <h1 className="heading-sm">
                    {getMonthName(monthToRender)}
                </h1>
                <div
                    className="flex flex-row space-x-tiny"
                >
                    <button
                        className="p-tiny"
                        onClick={()=> {
                            const previousMonth = moment(monthToRender).subtract(1, 'month').format('YYYY-MM-DD');
                            onMonthChange(previousMonth);
                        }}
                    >
                        <LivoIcon name='chevron-left' size={24} color='#149EF2' />
                    </button>
                    <button
                        className="p-tiny"
                        onClick={()=> {
                            const nextMonth = moment(monthToRender).add(1, 'month').format('YYYY-MM-DD');
                            onMonthChange(nextMonth);
                        }}
                    >
                        <LivoIcon name='chevron-right' size={24} color='#149EF2' />
                    </button>
                </div>
            </div>

            <div
                className="flex flex-row space-x-small mb-small items-start justify-start"
            >
                {
                    timeConfiguration.dayNamesShort.map((day, index) => (
                        <p
                            key={index}
                            className="info-overline text-Text-Subtle text-center w-[24px]"
                        >
                            {day}
                        </p>
                    ))
                }

            </div>
            <div
                className="flex flex-col space-y-tiny items-start justify-start"
            >
                {renderedWeeks}
            </div>
        </div>

    );
};
