import { useEffect, useState } from "react"
import { ModalContainer } from "./ModalContainer"
import { SingleSelectItem } from "./SingleSelectItem"
import { CustomInput } from "./CustomInput"
import { CircularProgress } from "@mui/material"
import { ActionButton } from "./ActionButton"
import { ACCEPT, GO_BACK } from "../../locales/common"
import { MultiSelectItem } from "./MultiSelectItem"


interface MultiSelectModalProps {
    goBack: () => void,
    onSubmit: (selectedOptions: string[]) => void,
    isOpen: boolean,
    title: string,
    loading?: boolean
    options: {
        label: string,
        value: string
    }[],
    goBackText?: string
    buttonText?: string
    initialSelection?: string[]
}

export const MultiSelectModal: React.FC<MultiSelectModalProps> = ({
    goBack,
    onSubmit,
    isOpen,
    title,
    loading,
    options,
    goBackText = GO_BACK,
    buttonText = ACCEPT,
    initialSelection = []
}) => {

    const [selectedOptions, setSelectedOptions] = useState<string[]>(initialSelection)
    
    const handleGoBack = () => {
        setSelectedOptions([])
        goBack()
    }

    const validSelectedOption = selectedOptions.length > 0

    useEffect(() => {
        setSelectedOptions(initialSelection)
    }, [initialSelection])

    return (
        <ModalContainer
            isOpen={isOpen}
            onClose={handleGoBack}
        >

            <div
                className="rounded-[16px] shadow-custom w-[410px] bg-white"
            >
                <div
                    className="p-medium"
                >

                    <p
                        className="heading-md mb-large"
                    >
                        {title}
                    </p>
                    <div
                        className="flex flex-col p-y-large"
                    >
                        {!loading ?
                            options.map((option, index) => (
                                <div
                                    className="flex flex-col w-full p-small"
                                >
                                    <MultiSelectItem
                                        key={index}
                                        option={
                                            option.label
                                        }
                                        onClick={() => {
                                            const updatedSelection = [...selectedOptions];
                                            if (updatedSelection.includes(option.value)) {
                                                const index = updatedSelection.indexOf(option.value);
                                                updatedSelection.splice(index, 1);
                                            } else {
                                                updatedSelection.push(option.value);
                                            }
                                            setSelectedOptions(updatedSelection);
                                        }}
                                        checked={selectedOptions.includes(option.value)}
                                    />
                                    
                                </div>
                            )) :
                            <div
                                className="flex flex-col w-full p-small"
                            >
                                <CircularProgress />
                            </div>
                        }
                    </div>
                    <div
                        className="flex flex-row items-center mt-large"
                    >
                        <button
                            onClick={() => handleGoBack()}
                            className="flex flex-1 text-center text-Action-Primary items-center justify-center  py-large px-small"
                        >
                            <p
                                className="action-regular w-full"
                            >
                                {
                                    goBackText
                                }
                            </p>

                        </button>

                        <button
                            className={`flex flex-1 py-large px-small rounded-[100px] text-center ${validSelectedOption ? "bg-Primary-500 text-Text-Inverse" : "bg-Background-Secondary text-Text-Subtle"}`}
                            disabled={!validSelectedOption}
                            onClick={() => onSubmit(selectedOptions)}
                        >
                            <p
                                className="w-full action-regular"
                            >
                                {buttonText}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    )
}