import LivoIcon from "assets/LivoLogo";
import { CapacityItem } from "./CapacityItem"

interface CapacitySelectorProps {
    selectedCapacity: number;
    maxCapacity: number;
    setCapacity: (capacity: number) => void;
}

export const CapacitySelector: React.FC<CapacitySelectorProps> = ({
    selectedCapacity,
    maxCapacity,
    setCapacity,
}) => {
    return (
        <div className="flex flex-row space-x-large w-full h-[48px]">
            {Array.from({ length: maxCapacity }, (_, i) => i + 1).map((capacity) => (
                <CapacityItem
                    key={capacity}
                    isSelected={capacity === selectedCapacity}
                    capacity={capacity.toString()}
                    onPress={() => setCapacity(capacity)}
                />
            ))}
        </div>
    )
}