import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import { ShiftTimeInDayEnum } from "types/shifts"
import { ShiftPublicationRequest, fetchPublishShiftConfiguration, publishShift } from "services/publish-shift"
import { PublishShiftConfigurationDTO, ShiftTimeConfigDTO } from "types/publish-shift"
import { useDispatch } from "react-redux"
import { setDateSelected } from "store/actions"
import { SHIFT_TIME_IN_DAY_DEFINITIONS } from "utils"
import { ShiftModalityEnum } from "types"
import { ShiftConfiguration } from "./BaseShiftForm"
import PublishEditModal from "./PublishEditModal"
import { showToastAction } from "store/actions/appConfigurationActions"

interface PublishShiftModalProps {
    onClose: () => void;
    isOpen: boolean;
    selectedDate: string;
    shiftTimeInDay: ShiftTimeInDayEnum;
    reloadData: () => void;
    shiftDetails?: ShiftConfiguration;
}

export const PublishShiftModal: React.FC<PublishShiftModalProps> = ({
    onClose,
    isOpen,
    shiftTimeInDay,
    selectedDate,
    reloadData,
    shiftDetails
}) => {
    // select the the start time according to shift time in day

    const isOpenClass = isOpen ? 'block' : 'hidden';
    const dispatch = useDispatch()


    const publishShiftFunction = (shiftRequest: ShiftPublicationRequest): Promise<void> => {
        return publishShift(shiftRequest).then(() => {
            onClose()
            dispatch(showToastAction({
                message: 'Turno creado con éxito',
                severity: 'success'
            }))
            if (moment(shiftRequest.startTime).isSame(selectedDate, 'day')) {
                reloadData()
            } else {
                dispatch(setDateSelected(moment(shiftRequest.startTime).format('YYYY-MM-DD')))
            }
        })
    }

    return (
        <PublishEditModal
            shiftDetails={shiftDetails || null}
            shiftTimeInDay={shiftTimeInDay}
            selectedDate={selectedDate}
            onSubmit={(publishShiftRequest: ShiftPublicationRequest) =>
                publishShiftFunction(publishShiftRequest)
            }
            onClose={() => {
                onClose()
            }}
            isOpen={isOpen}
        />)

}