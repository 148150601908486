import { ShiftModalityEnum } from "types";
import { VisibilityItem } from "./VisibitiliyItem";
import LivoIcon from "assets/LivoLogo";

interface VisibilitySelectorProps {
    selectedVisibility: ShiftModalityEnum[];
    setVisibility: (visibility: ShiftModalityEnum[]) => void;
    errorMessage?: string;
}

export const VisibilitySelector: React.FC<VisibilitySelectorProps> = ({
    selectedVisibility,
    setVisibility,
    errorMessage
}) => {
    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-row space-x-medium items-center w-full">

                <VisibilityItem
                    isSelected={selectedVisibility.includes(ShiftModalityEnum.EXTERNAL)}
                    modality={ShiftModalityEnum.EXTERNAL}
                    onPress={() => {
                        if (selectedVisibility.includes(ShiftModalityEnum.EXTERNAL)) {
                            setVisibility(selectedVisibility.filter((visibility) => visibility !== ShiftModalityEnum.EXTERNAL));
                        } else {
                            setVisibility([...selectedVisibility, ShiftModalityEnum.EXTERNAL]);
                        }

                    }}
                />
                <VisibilityItem
                    isSelected={selectedVisibility.includes(ShiftModalityEnum.INTERNAL)}
                    modality={ShiftModalityEnum.INTERNAL}
                    onPress={() => {
                        if (selectedVisibility.includes(ShiftModalityEnum.INTERNAL)) {
                            setVisibility(selectedVisibility.filter((visibility) => visibility !== ShiftModalityEnum.INTERNAL));
                        } else {
                            setVisibility([...selectedVisibility, ShiftModalityEnum.INTERNAL]);
                        }
                    }
                    }
                />
            </div>
            {
                errorMessage ? (
                    <p className="info-caption mt-tiny text-Negative-500">
                        {errorMessage}
                    </p>
                ) : null
            }
        </div>
    );
}