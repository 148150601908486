import moment from "moment"
import { CalendarSummaryAction } from "store/types"
import queryString from 'query-string';

const initialState = {
    selectedDate: selectedDayOrToday(),
    selectedShiftId: null
}

const calendarSummaryReducer = (state = initialState, action: CalendarSummaryAction) => {
    switch (action.type) {
        case 'SET_SELECTED_DATE': 
            return {
                ...state,
                selectedDate: action.payload
            }
        case 'SET_SELECTED_SHIFT_ID':// migrate to SET_CALENDAR_SELECTED_SHIFT_ID for clarity
            return {
                ...state,
                selectedShiftId: action.payload
            }
        default:
            return state
    }
}

function selectedDayOrToday() {
    const parsed = queryString.parse(window.location.search);
    return parsed.date || moment().format('YYYY-MM-DD');
}

export default calendarSummaryReducer;