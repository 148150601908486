export const PROFESSIONAL_PROFILE_TITLE = 'Detalles de la cobertura';
export const PROFESSIONAL_DETAILS_TITLE = 'Datos del profesional';
export const EXPERIENCE_IN_FACILITY_TITLE = 'Experiencia en el centro';
export const EXPERIENCE_IN_LIVO_TITLE = 'Experiencia en Livo';
export const REPLACEMENT_REASON_TITLE = 'Motivo de reemplazo';
export const CANCEL_CLAIM_REASON_TITLE = 'Motivo de cancelación';
export const COMPENSATION_TYPE_TITLE = 'Tipo de solicitud';
export const NO_REVIEWS_TITLE = 'Sin calificar';
export const TOTAL_REVIEWS_TITLE = 'Total';
export const TOTAL_REVIEWS_SUBTITLE = (num: number) => `en ${num} valoraciones`;
export const TOTAL_SHIFTS_SUBTITLE = (num: number) => `${num} turnos`;
export const TOTAL_SHIFTS_SUBTITLE_IN_FACILITY = 'en tu hospital';
