import { DataFieldSubmission, FacilityDataFieldDefinition, InternalProfessional } from "../../types/internal"
import { ProfessionalDataField } from "../../types/claims"
import LivoIcon from "../../assets/LivoLogo"
import { PROFESSIONAL_DATA_TITLE } from "../../locales/professionalClaim"
import { ManageClaimContainer } from "../../components/shiftDetails/professionalClaim/ManageClaimContainer"
import { useState } from "react"
import { FacilityDataField } from "./FacilityDataField"
import { ProfessionalCardHeader } from "components/shiftDetails/professionalClaim/ProfessionalCardHeader"
import { EditProfessionalCardHeader } from "./EditProfessionalCardHeader"
import { Category } from "types/shifts"
import { RootState } from "store/types"
import { useSelector } from "react-redux"



interface EditInternalProfessionalAttributesProps {
    internalProfessionalAttributes: ProfessionalDataField[],
    acceptTitle?: string,
    rejectTitle?: string,
    hideIcons?: boolean,
    onAccept: (newAttributes: DataFieldSubmission[], newCategory: Category) => void,
    onReject: () => void,
    dataFieldDefinitions: FacilityDataFieldDefinition[]
    professional: InternalProfessional
}

export const EditInternalProfessionalAttributes: React.FC<EditInternalProfessionalAttributesProps> = ({
    internalProfessionalAttributes,
    acceptTitle,
    rejectTitle,
    hideIcons,
    onAccept,
    onReject,
    dataFieldDefinitions,
    professional
}) => {

    const attributesFromDetails = internalProfessionalAttributes.map((attribute) => ({
        key: attribute.key,
        selectedValues: attribute.values?.length ? attribute.values.map(value => value.value) : [attribute.value]
    }))
    const [dataFieldSubmissions, setDataFieldSubmissions] = useState<DataFieldSubmission[]>(attributesFromDetails)
    const [selectedCategory, setSelectedCategory] = useState<Category>(professional.category)
    const validEntry = dataFieldSubmissions.length === dataFieldDefinitions.length && dataFieldSubmissions.every((attribute) => attribute.selectedValues.length > 0)
    const { accountInfo } = useSelector((state: RootState) => state.account)
    return (
        <div
            className="flex flex-col w-full h-full"
        >
            <EditProfessionalCardHeader
                professionalProfile={professional}
                modality={null}
                selectedCategory={selectedCategory}
                availableCategories={accountInfo?.facility.categories || []}
                setSelectedCategory={setSelectedCategory}
            />
            <div
                className="flex flex-col w-full p-medium justify-between h-full"
            >

                {
                    <div
                        className="flex flex-col w-full h-full"
                    >
                        <div
                            className="flex flex-col w-full h-full"
                        >
                            <div
                                className="flex flex-row space-x-small mb-large"
                            >
                                <LivoIcon name='id-badge-2' size={24} color='#ACBBCB' />
                                <p
                                    className="body-regular"
                                >
                                    {PROFESSIONAL_DATA_TITLE}
                                </p>
                            </div>
                            <div
                                className="flex flex-col space-y-large"
                            >
                                {
                                    dataFieldDefinitions.filter(field => field.editable).map((dataField, index) => {
                                        const originalAttribute = attributesFromDetails.find((attribute) => attribute.key === dataField.key)
                                        const hasChanged = originalAttribute && originalAttribute.selectedValues.join() !== dataFieldSubmissions.find((attribute) => attribute.key === dataField.key)?.selectedValues.join()
                                        return (
                                            <FacilityDataField
                                                key={index}
                                                facilityDataFieldDefinition={dataField}
                                                dataFieldSubmission={dataFieldSubmissions.find((attribute) => attribute.key === dataField.key)}
                                                setDataFieldSubmission={(dataFieldSubmission) => {
                                                    const newDataFieldSubmissions = dataFieldSubmissions.filter((attribute) => {
                                                        return attribute.key !== dataField.key
                                                    })
                                                    newDataFieldSubmissions.push(dataFieldSubmission)
                                                    setDataFieldSubmissions(newDataFieldSubmissions);
                                                }}
                                                hasChanged={hasChanged}
                                            />)
                                    }
                                    )
                                }
                            </div>
                        </div>
                        <ManageClaimContainer
                            onAccept={() => onAccept(dataFieldSubmissions, selectedCategory)}
                            onReject={onReject}
                            hideIcons={hideIcons}
                            acceptTitle={acceptTitle}
                            rejectTitle={rejectTitle}
                            disableAccept={!validEntry}
                        />
                    </div>
                }

            </div>
        </div>
    )
}