import React, { useEffect, useState } from "react"
import { ModalContainer } from "components/common/ModalContainer"
import { HeaderComponent } from "components/publishShift/HeaderComponent";
import { MANAGE_FAVORITE_PROFESSIONALS_TITLE } from "locales/professionals/favorite";
import { CircularProgress } from "@mui/material";
import { fetchFacilityProfessionals } from "services/professionals";
import { FacilityProfessionalsDTO } from "components/widgets/professionals/types";
import { FacilityProfessionals } from "components/widgets/professionals/FacilityProfessionals";
import { ClaimSummary } from "store/types";

interface FavoriteProfessionalsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  origin?: ClaimSummary;
}

const FavoriteProfessionalsModal: React.FC<FavoriteProfessionalsModalProps> = ({
  isOpen,
  handleClose,
  origin,
}) => {
  const initialFacilityProfessionals: FacilityProfessionalsDTO = {
    professionals: [],
    placeholder: {
      input: '',
      professionalsList: ''
    }
  }
  const [facilityProfessionals, setFacilityProfessionals] = useState<FacilityProfessionalsDTO>(initialFacilityProfessionals);
  const [loading, setLoading] = useState(false);

  const loadFacilityProfessionals = () => {
    setLoading(true)
    fetchFacilityProfessionals().then((response) => {
      setFacilityProfessionals(response)
      setLoading(false)
    }).catch((error) => {
      console.error(error)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (isOpen) {
      loadFacilityProfessionals()
    }
  }, [isOpen])

  return (
    <ModalContainer
      isOpen={isOpen}
      style={{ width: '40%', maxWidth: '500px'}}
      onClose={handleClose}
    >
      <div className="flex flex-col h-full bg-white rounded-[16px] mx-auto">
        <div className="flex-none">
          <HeaderComponent
            title={MANAGE_FAVORITE_PROFESSIONALS_TITLE}
            onClose={handleClose}
          />
        </div>

        {loading ? (
          <div className="h-[600px] w-[700px] flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto rounded-b-[16px]">
            <FacilityProfessionals
              professionals={facilityProfessionals.professionals}
              placeholder={facilityProfessionals.placeholder}
              origin={origin}
              className="max-h-[600px] p-large"
            />
          </div>
        )}
      </div>
    </ModalContainer>
  )
}

export default React.memo(FavoriteProfessionalsModal);
