import { ClaimRequest, ProfessionalProfile } from "types/claims"
import LivoIcon from "assets/LivoLogo";
import { EXPERIENCE_LABEL, LICECNSE_NUMBER_LABEL, PROFESSIONAL_DATA_TITLE, VIEW_CV_LABEL } from "locales/professionalClaim";

interface ProfessionalDataBodyProps {
    claim: ClaimRequest
}
export const ProfessionalDataBody: React.FC<ProfessionalDataBodyProps> = ({
    claim
}) => {

    return (

        <div
            className="flex flex-col w-full p-medium"
        >
            <div
                className="flex flex-row space-x-small mb-large"
            >
                <LivoIcon name='id-badge-2' size={24} color='#ACBBCB' />
                <p
                    className="body-regular"
                >
                    {PROFESSIONAL_DATA_TITLE}
                </p>
            </div>
            <div
                className="flex flex-row space-x-small justify-between mb-small"
            >
                <p
                    className="body-regular text-[#707A91]"
                >
                    {LICECNSE_NUMBER_LABEL}
                </p>
                <p
                    className="subtitle-regular text-Text-Default"
                >
                    {claim.professionalProfile.licenseNumber}
                </p>
            </div>
            <div
                className="flex flex-row space-x-small justify-between"
            >
                <div
                    className="flex"
                >
                    <p
                        className="body-regular text-[#707A91]"
                    >
                        {EXPERIENCE_LABEL}:
                    </p>
                </div>
                <div
                    className="flex"
                    onClick={() => {
                        // open cv url in another tab

                        window.open(claim.professionalProfile.professionalCV, '_blank')
                    }}

                >
                    <p

                        className="subtitle-regular text-Action-Primary cursor-pointer"
                    >
                        {VIEW_CV_LABEL}
                    </p>
                </div>

            </div>
        </div>

    )
}