import { useState } from 'react';
import { FacilityProfessionalsDTO } from 'components/widgets/professionals/types';
import { ProfessionalProfile } from 'types/claims';
import ProfessionalProfileCard from './ProfessionalProfileCard';
import { SearchBar } from 'components/facilityStaff/SearchBar';
import { ClaimSummary } from 'store/types';


interface FacilityProfessionalsProps extends FacilityProfessionalsDTO {
  origin?: ClaimSummary;
  className?: string;
}

const renderItem = (item: ProfessionalProfile, origin?: ClaimSummary) => (
  <ProfessionalProfileCard
    {...item}
    origin={origin}
    key={item.id}
    className="bg-white mt-small p-medium rounded-[8px]"
  />
);

export function FacilityProfessionals({
  professionals,
  placeholder,
  origin,
  className,
}: FacilityProfessionalsProps) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredProfessionals = professionals.filter(professional => {
    const fullName = `${professional.firstName} ${professional.lastName}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  return (
    <div className={`flex flex-col flex-1 bg-gray-50 ${className}`}>
      <SearchBar 
        searchText={searchQuery} 
        setSearchText={setSearchQuery} 
        searchPlaceHolder={placeholder.input}
      />

      {filteredProfessionals.length === 0 ? (
        <div className="flex-1 p-4 justify-start mt-4">
          <p className="text-center text-base">
            {placeholder.professionalsList}
          </p>
        </div>
      ) : (
        <div className="my-4 overflow-y-auto scrollbar-hide">
          {filteredProfessionals.map(professional => renderItem(professional, origin))}
        </div>
      )}
    </div>
  );
}
