import LivoIcon from "assets/LivoLogo";
import { SHIFT_CARD_PENDING_CLAIM_MULTIPLE, SHIFT_CARD_PENDING_CLAIM_SINGLE, SHIFT_CARD_TAG_FILLED } from "locales/shiftClaimList/es";

interface ShiftCardTagProps {
    totalPendingClaims: number;
    isFilled: boolean;
    largeTag?: boolean;
    isSelected?: boolean;
}
export const ShiftCardTag: React.FC<ShiftCardTagProps> = ({
    totalPendingClaims,
    isFilled,
    largeTag,
    isSelected
}) => {

    const emptyTag = (
        <div
            className="items-center justify-center flex-col rounded-[100px] p-[2px] opacity-0"
        >
            <p
                className="subtitle-sm text-white w-[16px] text-center"
            >
            </p>
        </div>
    )

    if (isSelected) {
        return emptyTag
    }

    if (isFilled) {
        const tagComponent = <LivoIcon name="circle-check-filled" size={24} color="#33B240" />
        return tagComponent

    } else if (totalPendingClaims > 0) {
        const tagComponent = (
            <div
                className="flex items-center justify-center flex-col bg-Negative-500 rounded-[100px] p-[2px]"
            >
                <p
                    className="subtitle-sm text-white w-[16px] text-center"
                >
                    {totalPendingClaims}
                </p>
            </div>
        )

        return tagComponent
    }

    return (
        <div
            className="items-center justify-center flex-col rounded-[100px] p-[2px] opacity-0"
        >
            <p
                className="subtitle-sm text-white w-[16px] text-center"
            >
            </p>
        </div>
    )
}