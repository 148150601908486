import { Shift } from "types/shifts"
import { SHIFT_TIME_IN_DAY_DEFINITIONS, formatTime } from "utils";
import { ShiftCardActions } from "./ShiftCardActions";


interface ShiftCardContainerProps {
    shift: Shift;
    onClick?: () => void;
    isSelected?: boolean;
    children: React.ReactNode;
    actionComponents?: {
        iconName: string;
        onClick: (shift: Shift) => void;
    }[];
}

export const ShiftCardContainer: React.FC<ShiftCardContainerProps> = ({
    shift,
    isSelected,
    onClick,
    children,
    actionComponents
}) => {
    const shiftTimeInDay = SHIFT_TIME_IN_DAY_DEFINITIONS[shift.shiftTimeInDay]
    const cardColor = `bg-[${shiftTimeInDay.color}]`
    const actionComponentsWithShifts = actionComponents?.map((actionComponent) => ({
        iconName: actionComponent.iconName,
        onClick: () => {
            actionComponent.onClick(shift)
        }
    }))
    return (
        <div
            className="relative group"
        >
            <div
                onClick={onClick}
                className={`flex flex-row ${isSelected ? 'bg-Action-Secondary text-Text-Inverse' : 'bg-white'} rounded-[16px] relative ${onClick ? 'cursor-pointer' : ''} overflow-hidden`}
                style={{
                    minWidth: '200px',
                    maxWidth: '1280px'
                }}
            >
                <div
                    className={`w-[8px] ${cardColor} flex overflow-hidden`}
                >
                </div>
                {children}

            </div>
            {
                actionComponentsWithShifts ?
                    <div
                        className="flex items-center justify-end absolute -top-[8px] right-[16px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                        <ShiftCardActions
                            actionComponents={actionComponentsWithShifts}
                        />
                    </div> : null
            }
        </div>

    )
}