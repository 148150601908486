import LivoIcon from "../../../assets/LivoLogo";
import { PROFESSIONAL_DATA_TITLE } from "../../../locales/professionalClaim";



interface InternalProfessionalAttributesProps {
    attributes: {
        label: string;
        value: string;
    }[]

}
export const InternalProfessionalAttributes: React.FC<InternalProfessionalAttributesProps> = ({
    attributes
}) => {

    return (

        <div
            className="flex flex-col w-full p-medium"
        >
            <div
                className="flex flex-row space-x-small mb-large"
            >
                <LivoIcon name='id-badge-2' size={24} color='#ACBBCB' />
                <p
                    className="body-regular"
                >
                    {PROFESSIONAL_DATA_TITLE}
                </p>
            </div>

            <div
                className="flex flex-col space-y-small"
            >
                {
                    attributes.map((attribute, index) => (
                        <div
                            key={index}
                            className="flex flex-row space-x-small justify-between"
                        >
                            <p
                                className="body-regular text-[#707A91]"
                            >
                                {attribute.label}:
                            </p>
                            <p
                                className="subtitle-regular text-Text-Default"
                            >
                                {attribute.value}
                            </p>
                        </div>
                    ))
                }
            </div>

        </div>

    )
}