import LivoIcon from "assets/LivoLogo";


interface TagLabelProps {
    text: string;
    color?: string;
    style?: any;
    backgroundColor?: string;
    onRemove?: () => void;
    closeColor?: string;
}

export const TagLabel: React.FC<TagLabelProps> = ({ 
    text,
    color,
    backgroundColor='#EEEFF3',
    closeColor='#707A91',
    onRemove,
    style,

}) => {
    return (
        <div
            className={`flex flex-row items-center px-[6px] py-[2px] rounded-[4px] space-x-tiny ${style}`}
            style={{
                backgroundColor: backgroundColor,
                color: color
            }}
            >
            <p className="info-caption text-Text-Default text-nowrap">
                {text}
            </p>
            {
                onRemove ?
                <div
                    onClick={onRemove}
                    className="flex items-center justify-center cursor-pointer"
                >
                    <LivoIcon name="close" size={24} color={closeColor} />
                </div> : null
            }
        </div>
    );
}