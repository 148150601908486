import { ClaimRequest, ClaimStatusEnunm } from "types/claims"
import { ProfessionalCardHeader } from "./ProfessionalCardHeader";
import { ProfessionalFacilityExperience } from "./ProfessionalFacilityExperience";
import { ProfessionalLivoReviews } from "./ProfessionalLivoReviews";
import LivoIcon from "assets/LivoLogo";
import { CANCELLATION_REQUEST_REASON, PENDING_CLAIMS_TITLE } from "locales/shiftClaimDetails/es";
import { ManageClaimContainer } from "./ManageClaimContainer";
import { CompensationOptionComponent } from "./CompensationOption";
import { InternalProfessionalAttributes } from "./InternalProfileAttributes";
import { INTERNAL_ASSIGNED_UNIT, INTERNAL_CONTRACT_TYPE, INTERNAL_IDENTIFICATION_NUMBER } from "locales/professionalClaim";
import { SlotReasonDetails } from "./SlotReasonDetails";
import { useEffect, useRef, useState } from "react";
import { deleteClaimRequest } from "services/claims";
import { CancelInternalProfessionalClaimModal } from "./CancelInternalProfessionalClaimModal";
import { ShiftTimeStatusEnum } from "types/shifts";


interface InternalProfessionalClaimDetailsProps {
    claim: ClaimRequest;
    goBack: () => void;
    onAccept: () => void;
    onReject: () => void;
    acceptCancellationRequest: (claimId: number) => void;
    rejectCancellationRequest: (claimID: number) => void;
    onUpdateSlotReason: () => void;
    shiftId: number;
    onDelete: (reason:string) => void;
    shiftStatus: ShiftTimeStatusEnum;
}
export const InternalProfessionalClaimDetails: React.FC<InternalProfessionalClaimDetailsProps> = ({
    claim,
    goBack,
    onAccept,
    onReject,
    acceptCancellationRequest,
    rejectCancellationRequest,
    onUpdateSlotReason,
    shiftId,
    onDelete,
    shiftStatus
    
}) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setMenuOpen(false);
                setCancelModalOpen(false);
            }
        };

        if (menuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuOpen]);


    const handleConfirmDelete = (reason: string) => {
        onDelete(reason); 
    };
  

    return (
        <div
            className="flex flex-col w-full"
        >   <div className="flex items-center w-full px-large justify-between" >
                <button
                    onClick={goBack}
                >
                    <LivoIcon name='arrow-left' size={24} color='#707A91' />
                </button>
                { shiftStatus === ShiftTimeStatusEnum.UPCOMING && (
                <div className="relative">
                <button
                    ref={buttonRef}
                    onClick={(e) => {
                        e.preventDefault();
                        setMenuOpen(!menuOpen);
                       }}
                >
                    <LivoIcon name='dots' size={24} color='#707A91' />
                </button>
                
                    {menuOpen && (
                        <div 
                        ref={modalRef}
                        className="p-medium rounded-[8px] mb-[14px] absolute z-10 bg-white shadow-custom right-full top-0 mr-tiny w-[255px] space-y-[14px]"
                        >
                            <button className="pv-tiny px-small flex w-full justify-between items-center"                            
                            onClick={() => { 
                                setCancelModalOpen(true);
                            }}>
                                <p className="body-regular text-Negative-500 mr-small">
                                    {"Revocar Puesto"}
                                </p>
                                <LivoIcon name="circle-minus" size={24} color="#FA3D3B" />
                            </button>
                            
                            <CancelInternalProfessionalClaimModal
                                isOpen={cancelModalOpen}
                                onClose={() => setCancelModalOpen(false)}
                                onConfirm={handleConfirmDelete}
                                claim={claim}
                                shiftId={shiftId}
                                goBack={goBack}
                                onDelete={onDelete}
                        />
                        </div>
                    )}
                    </div>
                  )}
            </div>
            <div
                className="flex flex-col w-full h-full justify-between pb-large"
            >
                <div
                    className="flex flex-col w-full space-y-large divide-y-[1px] divide-Divider-Default overflow-y-scroll no-scrollbar"
                >
                    <div
                        className="flex flex-col w-full"
                    >

                        <ProfessionalCardHeader
                            professionalProfile={claim.professionalProfile}
                            modality={claim.modality}
                        />
                        {claim.professionalProfile.internal && <InternalProfessionalAttributes
                            attributes={
                                [
                                    ...claim.professionalProfile.internal.dataFields.map(datafield => ({
                                        label: datafield.label,
                                        value: datafield.displayText
                                    })
                                    )
                                ]

                            }
                        />}
                    </div>
                    {claim.compensationOption && <CompensationOptionComponent
                        compensationOption={claim.compensationOption}
                    />}
                    {
                        claim.status !== ClaimStatusEnunm.PENDING_APPROVAL && claim.slotReasonOptions && claim.slotReasonOptions.length > 0 ? <SlotReasonDetails
                            onUpdateSlotReason={onUpdateSlotReason}
                            slotReason={claim.slotReason}
                            slotReasonOptions={claim.slotReasonOptions}
                            claimId={claim.id}
                            shiftId={shiftId}
                            slotReasonCommentDisplayed={claim.slotReasonCommentDisplayed}
                        /> : null
                    }
                </div>
                <div 
                    className="flex flex-col w-full"
                >
                    {claim.status === ClaimStatusEnunm.PENDING_APPROVAL ?
                        <div
                            className="flex flex-col w-full px-medium"
                        >
                            <ManageClaimContainer
                                onAccept={onAccept}
                                onReject={onReject}
                            >
                            </ManageClaimContainer>
                        </div> :
                        claim.cancellationRequest ?
                            <div
                                className="flex flex-col w-full px-medium mb-large"
                            >
                                <ManageClaimContainer
                                    onAccept={() => acceptCancellationRequest(claim.id)}
                                    onReject={() => rejectCancellationRequest(claim.id)}
                                >
                                    <div
                                        className="flex flex-col w-full space-y-tiny"
                                    >
                                        <p
                                            className="body-regular text-Text-Subtle"
                                        >
                                            {CANCELLATION_REQUEST_REASON}
                                        </p>
                                        <p
                                            className="body-regular text-Text-Default"
                                        >
                                            {claim.cancellationRequest.reason}
                                        </p>
                                    </div>
                                </ManageClaimContainer>
                            </div>
                            : null

                    }

                </div>
            </div>
        </div>
    )
}