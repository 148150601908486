import { SingleSelectModal } from "../../components/common/SingleSelectModal";
import { useState } from "react";
import { DataFieldOption, FacilityDataFieldDefinition } from "../../types/internal";
import LivoIcon from "assets/LivoLogo";
import { DropDownWithInput } from "components/publishShift/DropDownWithInput";


interface SingleSelectDataFieldProps {
    dataFieldDefinition: FacilityDataFieldDefinition;
    selectedValue: string;
    setSelectedValue: (selectedValue: string) => void;
    title: string;
    hasChanged?: boolean;
}

export const SingleSelectDataField: React.FC<SingleSelectDataFieldProps> = ({
    dataFieldDefinition,
    selectedValue,
    setSelectedValue,
    title,
    hasChanged
}) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
        <div
            className="w-full"
        >
            <DropDownWithInput
                label={dataFieldDefinition.label}
                hasChanged={hasChanged}
                selectedOptionId={selectedValue}
                setOptionId={(optionId) => {
                    setSelectedValue(optionId)
                }}
                placeHolder={dataFieldDefinition.label}
                options={dataFieldDefinition.options.map((option) => ({
                    id: option.value,
                    name: option.displayText
                }))
                }
            />

        </div>
    )
}