import { CalendarMonth } from "components/calendar/CalendarMonth";
import moment from "moment";
import React, { useEffect } from "react";
import { CalendarSummary } from "types/publish-shift";

interface DateSelectorProps {
    setDates: (dates: string[]) => void;
    selectedDates: string[];
    calendarSummary: CalendarSummary[];
}

export const DateSelector: React.FC<DateSelectorProps> = ({
    setDates,
    selectedDates,
    calendarSummary

}) => {
    const lastSelectedDate = selectedDates.length > 0 ? selectedDates[selectedDates.length - 1] : moment().format('YYYY-MM-DD')
    const selectedMonth = moment(lastSelectedDate).startOf('month').format('YYYY-MM-DD');
    const [currentMonth, setCurrentMonth] = React.useState<string>(selectedMonth)
    useEffect(() => {
        if (moment(lastSelectedDate).month() !== moment(currentMonth).month()) { // change month on when the selected date is on another month
            const newSelectedMonth = moment(selectedMonth).startOf('month').format('YYYY-MM-DD')
            setCurrentMonth(newSelectedMonth)
        }
    }, [lastSelectedDate])

    return (
        <CalendarMonth
            onDayPress={(day: string) => {
                if (selectedDates.includes(day)) {
                    if (selectedDates.length > 1) {
                        setDates(selectedDates.filter((date) => date !== day))
                    }
                } else {
                    setDates([...selectedDates, day])
                }
            }}
            calendarSummary={calendarSummary.map((day) => ({
                ...day,
                hasAlert: false,
                totalShifts: 0,
            }))}
            monthToRender={currentMonth}
            daysSelected={selectedDates}
            onMonthChange={(month) => { setCurrentMonth(month) }}
            disablePastDates={true}
        />
    )
}