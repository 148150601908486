import { useEffect, useRef, useState } from "react";
import { InternalProfessionalFilter } from "../../services/api";
import { FacilityDataFieldDefinition, InternalProfessional, InvitationStatus } from "../../types/internal";
import { CircularProgress } from "@mui/material";
import { InternalProfessionalCard } from "./InternalProfessionalCard";
import { NO_PROFESSIONALS_SUBTITLE, NO_PROFESSIONALS_TITLE, NO_REQUESTS_TITLE, PROFESSIONALS_PAGE_TITLE, PROFESSIONAL_TABLE_NAME_HEADER, SEARCH_PLACEHOLDER} from "../../locales/internalProfessionalPage";
import { ProfessionalFilter } from "./ProfessionalFilter";
import { EmptyShiftsState } from "./EmptyState";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchBar } from "./SearchBar";


interface InternalProfessionalsListComponentProps {
    professionals: InternalProfessional[];
    loading: boolean;
    selectedFilter: InternalProfessionalFilter;
    selectedProfessionalId: number | null,
    setSelectedProfessionalId: (id: number) => void
    loadNextPage: () => void
    searchQuery: string,
    setSearchQuery: (text: string) => void
    hasMoreData: boolean
    totalProfessionals: number |null
    pendingRequests: InternalProfessional[],
    dataFieldDefinitions: FacilityDataFieldDefinition[]
}

export const InternalProfessionalsListComponent: React.FC<InternalProfessionalsListComponentProps> = ({
    professionals,
    loading,
    selectedProfessionalId,
    setSelectedProfessionalId,
    loadNextPage,
    hasMoreData,
    searchQuery,
    setSearchQuery,
    totalProfessionals,
    pendingRequests,
    dataFieldDefinitions

}) => {
    const [invitationStatusFilter, setInvitationStatusFilter] = useState('all')
    const displayProfessionals = invitationStatusFilter === 'pending' ? pendingRequests : professionals

    const scrollableDivRef = useRef<HTMLDivElement | null>(null);

    const scrollToTop = () => {
      if (scrollableDivRef.current) {
        console.log('scrolling to top')
        scrollableDivRef.current.scrollTo({
          top: 0,
        });
      }
    };
    
    useEffect(() => {
        scrollToTop();
        }
    , [searchQuery, invitationStatusFilter])
    return (
        <div
            className="flex flex-1 pt-[24px] pb-[24px] justify-center max-h-max"
        >
            <div
                className="flex flex-col flex-1 w-full px-medium  max-w-[1500px]"
            >
                <div
                    className="flex space-x-small items-center mb-large"
                >
                    <p
                        className="heading-md text-ellipsis text-nowrap"
                    >
                        {PROFESSIONALS_PAGE_TITLE}
                    </p>
                    <p
                        className="body-large text-Text-Subtle "
                    >
                    {invitationStatusFilter !== 'pending' && totalProfessionals !== null ? `(${totalProfessionals})` : ''}
                </p>
                </div>
                <SearchBar
                    searchText={searchQuery}
                    setSearchText={setSearchQuery}
                    searchPlaceHolder={SEARCH_PLACEHOLDER}
                    />
                <div className="flex flex-col h-fit w-full mt-large"> 
                <ProfessionalFilter
                    appliedFilter={invitationStatusFilter}
                    setFilter={(filter: any) => setInvitationStatusFilter(filter)}
                    nPendingClaims={ invitationStatusFilter !== 'pending' && totalProfessionals !== null ? pendingRequests.length : null}
                />
                </div>
                <div
                    className="flex flex-col h-fit w-full"
                >
                    <div
                        className="flex border-b-[1px] border-Divider-Default text-left mb-large px-small items-center py-small text-Text-Subtle space-x-small"
                    >
                        <div
                            className="flex-[2] flex table-header "
                        >
                            {PROFESSIONAL_TABLE_NAME_HEADER}
                        </div>
                        {
                            dataFieldDefinitions.map((dataField, index) =>
                                <div
                                    key={index}
                                    className="flex-1 flex table-header"
                                >
                                    {dataField.label}
                                </div>
                            )
                        }
                    </div>
                    {loading ? (
                        <div
                            className="flex flex-1 justify-center items-center h-fit"
                        >
                            <CircularProgress />
                        </div>
                    ) :
                    displayProfessionals.length === 0 ?
                            invitationStatusFilter === 'pending' ?
                                <EmptyShiftsState
                                    title={NO_REQUESTS_TITLE}
                                /> :
                                <EmptyShiftsState
                                    title={NO_PROFESSIONALS_TITLE}
                                    subtitle={NO_PROFESSIONALS_SUBTITLE} />
                            :

                            <div
                                id="scrollableDiv"
                                className="space-y-small w-full pb-100 no-scrollbar"
                                ref={scrollableDivRef}
                                style={{
                                    overflow: 'scroll',
                                    height: 'calc(100vh - 370px)', // this is needed to know where the scroll list finishes, if not the the will overflow and the sroll won't happen on the infinite scroll
                                  }}
                            >
                                <InfiniteScroll
                                    dataLength={displayProfessionals.length}
                                    next={() => loadNextPage()}
                                    hasMore={hasMoreData}
                                    loader={<div />}
                                    scrollableTarget="scrollableDiv"
                                    className="space-y-small"
                                    scrollThreshold={0.7}
                                    >
                                    {displayProfessionals.map((item) => (
                                        <InternalProfessionalCard
                                            key={item.id}
                                            professional={item}
                                            isSelected={selectedProfessionalId === item.id}
                                            onClick={() => setSelectedProfessionalId(item.id)}
                                            notifications={item.invitationStatus === InvitationStatus.PENDING ? 1 : undefined}
                                            dataFieldDefinitions={dataFieldDefinitions}
                                        />
                                    ))}
                                </InfiniteScroll>
                            </div>

                    }
                    <div
                        className='h-large' />
                </div>
            </div>

        </div >
    )
}