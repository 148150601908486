import LivoIcon from "assets/LivoLogo"
import { formatTime } from "utils"

interface ScheduleComponentProps {
    startTime: string;
    finishTime: string;
    style?: string
}

export const ScheduleComponent: React.FC<ScheduleComponentProps> = ({
    startTime,
    finishTime,
    style
}) => {
    return (
        <div
            className={"flex items-center flex-row justify-start " + style}
        >
            <p>
                {formatTime(startTime)}
            </p>
            <LivoIcon name='arrow-right' size={16} color='#C6D0DB' />
            <p>
                {formatTime(finishTime)}
            </p>
        </div>
    )
}