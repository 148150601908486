import { Typography, Box, Card, CardMedia, IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';
import { EXPIRED_DOCUMENT_LABEL, PENDING_DOCUMENT_LABEL } from 'locales/shiftClaimList/es';

interface FileThumbnailProps {
    fileUrl?: string;
    label: string;
    isExpired?: boolean;
}

const FileThumbnail = ({ fileUrl, label, isExpired = false }: FileThumbnailProps) => {
    const getFileExtension = (filename: string) => {
        return filename.split("?")[0].split('.').pop()?.toLowerCase() || 'unknown';
    };

    const fileExtension = fileUrl ? getFileExtension(fileUrl) : '';

    return (
        <Card sx={{ width: 150, margin: 2, textAlign: 'center', position: 'relative' }}>
            {fileUrl ? (
                <Box sx={{ position: 'relative', height: 100 }}>
                    <CardMedia
                        component={fileExtension === 'pdf' ? "iframe" : 'img'}
                        height="100"
                        image={fileUrl}

                    />
                    {isExpired && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                bgcolor: 'rgba(0, 0, 0, 0.6)', // Dark overlay
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="body2">
                                {EXPIRED_DOCUMENT_LABEL}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box
                    sx={{
                        height: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: '#EEEFF3',
                        color: '#707A91',
                    }}
                >
                    <Typography variant="body2">
                        {PENDING_DOCUMENT_LABEL}
                    </Typography>
                </Box>
            )}
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    aria-label="Download"
                    onClick={() => {
                        if (fileUrl) {
                            window.open(fileUrl, '_blank');
                        }
                    }}
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 0,
                        justifyContent: 'left',
                        pr: 1,
                        color: fileUrl ? 'inherit' : 'text.secondary',
                        bgcolor: '#FFFFFF',
                    }}
                    disabled={!fileUrl}
                >
                    <Download sx={{ color: fileUrl ? '#149EF2' : '#ACBBCB' }}/>
                    <Typography variant="body2" component="div" noWrap sx={{ marginLeft: 1, color: '#2C3038' }}>
                        {label}
                    </Typography>
                </IconButton>
            </Box>
        </Card>
    );
};

export default FileThumbnail;

