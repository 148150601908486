

interface CategoryTagProps {
    text: string;
    style?: any;
}

export const CategoryTag: React.FC<CategoryTagProps> = ({
    text,
    style }) => {
    return (
        <div className="p-1 rounded border border-[#848da9] justify-start items-center gap-[9.60px] flex bg-white">
            <div className=" body-regular leading-none text-Text-Default">{text}</div>
        </div>
    );
}