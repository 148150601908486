import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ADDRESS_COPIED, ADDRESS_LABEL, BANK_ACCOUNT_NUMBER_COPIED, BANK_ACCOUNT_NUMBER_LABEL, CONTACT_INFORMATION_LABEL, COPY_ALL_FIELDS, copyProfessionalFields, DEDUCTION_PERCENTAGE_COPIED, DEDUCTION_PERCENTAGE_LABEL, EMAIL_COPIED, EMAIL_LABEL, EXPIRED_DOCUMENT_LABEL, LEGAL_INFORMATION_TITLE, LICENSE_NUMBER_LABEL, NATIONAL_ID, NATIONAL_ID_COPIED, PHONE_NUMBER_COPIED, PHONE_NUMBER_LABEL, PROFESSIONAL_PROFILE_TITLE, SOCIAL_SECURITY_NUMBER_COPIED, SOCIAL_SECURITY_NUMBER_LABEL, UNAVAILABLE_LEGAL_INFORMATION } from 'locales/shiftClaimDetails/es';
import { ProfessionalInitProfile, ProfessionalLegalProfile } from 'types';
import { Avatar, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LivoIcon from 'assets/LivoLogo';
import { showToastAction } from 'store/actions/appConfigurationActions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import BoldTitleAndValue from './common/BoldTitleAndValue';
import FirstShifterTag from './common/FirstShifterTag';
import { PENDING_LABEL } from 'locales/shiftClaimList/es';

export default function ProfessionalProfileCard({ professionalInitProfile, professionalLegalProfile }: { professionalInitProfile: ProfessionalInitProfile, professionalLegalProfile?: ProfessionalLegalProfile }) {
  const dispatch = useDispatch<AppDispatch>()

  const BoxRow = ({ children }: { children: React.ReactNode }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      {children}
    </Box>
  )
  const legalDocumentationNotAvailable = (
    <Typography variant='body2' color='text.secondary' gutterBottom>
      {UNAVAILABLE_LEGAL_INFORMATION}
    </Typography>
  )

  const deductionPercentage = professionalLegalProfile?.deductionPercentage ? professionalLegalProfile.deductionPercentage.toString() + '%' : '';
  const fieldsToCopy = copyProfessionalFields(professionalInitProfile, professionalLegalProfile);
  const legalDocumentation = professionalLegalProfile && (
    <div>
      <Typography variant="h5" sx={{ mb: 2, fontSize: 20 }}>
        {LEGAL_INFORMATION_TITLE}
      </Typography>
      <BoxRow>
        <BoldTitleAndValue
          title={NATIONAL_ID}
          value={professionalLegalProfile.nationalId}
          placeholder={PENDING_LABEL}
          annotation={professionalLegalProfile.nationalIdDocExpired ? EXPIRED_DOCUMENT_LABEL: undefined}
          copyText={professionalLegalProfile.nationalId}
          copyTextSuccess={NATIONAL_ID_COPIED}
        />
      </BoxRow>
      <BoxRow>
        <BoldTitleAndValue
          title={ADDRESS_LABEL}
          value={professionalLegalProfile.address}
          placeholder={PENDING_LABEL}
          copyText={professionalLegalProfile.address}
          copyTextSuccess={ADDRESS_COPIED}
        />
      </BoxRow>
      <BoxRow>
        <BoldTitleAndValue
          title={BANK_ACCOUNT_NUMBER_LABEL}
          value={professionalLegalProfile.bankAccountNumber}
          placeholder={PENDING_LABEL}
          copyText={professionalLegalProfile.bankAccountNumber}
          copyTextSuccess={BANK_ACCOUNT_NUMBER_COPIED}
        />
      </BoxRow>
      <BoxRow>
        <BoldTitleAndValue
          title={DEDUCTION_PERCENTAGE_LABEL}
          value={deductionPercentage}
          placeholder={PENDING_LABEL}
          copyText={deductionPercentage}
          copyTextSuccess={DEDUCTION_PERCENTAGE_COPIED}
        />
      </BoxRow>
      <BoxRow>
        <BoldTitleAndValue
          title={SOCIAL_SECURITY_NUMBER_LABEL}
          value={professionalLegalProfile.socialSecurityNumber}
          placeholder={PENDING_LABEL}
          copyText={professionalLegalProfile.socialSecurityNumber}
          copyTextSuccess={SOCIAL_SECURITY_NUMBER_COPIED}
        />
      </BoxRow>
    </div>
  )
  return (
    <Card sx={{ m: 1, flex: 1, minWidth: 400 }}>
      <CardContent>
        <div
          className='justify-between flex flex-row items-center'
        >
          <Typography sx={{ fontSize: 14, mb: 2 }} color="text.secondary" gutterBottom>
            {PROFESSIONAL_PROFILE_TITLE}
          </Typography>
          <button
            onClick={() => {
              navigator.clipboard.writeText(fieldsToCopy);
              dispatch(showToastAction({
                message: 'Información copiada con éxito',
                severity: 'success'
              }))
            }}
            className='flex flex-row items-center py-small pr-large pl-medium ring-1 ring-solid ring-[#848DA9] rounded-[100px]'
          >
            <p
              className='action-regular text-Primary-500 mr-tiny'
            >
              {COPY_ALL_FIELDS}
            </p>
            <LivoIcon name={'copy'} size={16} color={'#149EF2'} />
          </button>
        </div>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={professionalInitProfile.profilePictureUrl}
              sx={{ width: 70, height: 70, marginBottom: 2, mr: 2 }}
            >
              {professionalInitProfile.profilePictureUrl ? null : <PersonIcon />}
            </Avatar>
            <Box>
              <Typography variant="h5" component="div">
                {professionalInitProfile.firstName} {professionalInitProfile.lastName} {professionalInitProfile.secondLastName}
              </Typography>

              { professionalInitProfile.firstShifter &&
                <FirstShifterTag/>
              }

              <BoldTitleAndValue title={LICENSE_NUMBER_LABEL} value={professionalInitProfile.licenseNumber} placeholder={PENDING_LABEL}/>
              { professionalLegalProfile &&
                <BoldTitleAndValue
                  title={NATIONAL_ID}
                  value={professionalLegalProfile.nationalId}
                  placeholder={PENDING_LABEL}
                  annotation={professionalLegalProfile.nationalIdDocExpired ? EXPIRED_DOCUMENT_LABEL: undefined}
                  copyText={professionalLegalProfile.nationalId}
                  copyTextSuccess={NATIONAL_ID_COPIED}
                />
              }
            </Box>

          </Box>
        </Box>
        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" sx={{ mb: 2, fontSize: 20 }}>
            {CONTACT_INFORMATION_LABEL}
          </Typography>
          <BoxRow>
            <BoldTitleAndValue
              title={EMAIL_LABEL}
              value={professionalInitProfile.email}
              copyText={professionalInitProfile.email}
              copyTextSuccess={EMAIL_COPIED}
            />
          </BoxRow>
          <BoxRow>
            <BoldTitleAndValue
              title={PHONE_NUMBER_LABEL}
              value={professionalInitProfile.phoneNumber}
              copyText={professionalInitProfile.phoneNumber}
              copyTextSuccess={PHONE_NUMBER_COPIED}
            />
          </BoxRow>
        </Box>
        {professionalLegalProfile ? legalDocumentation : legalDocumentationNotAvailable}
      </CardContent>
    </Card>
  );
}