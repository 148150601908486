import { ActivityShiftListComponent } from "components/activity/ActivityShiftListComponent"
import { ShiftDetailsSection } from "components/shiftDetails/ShiftDetailsSection"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchActionableShifts } from "services/activity"
import { ApiApplicationError } from "services/api"
import { fetchShifts } from "services/shifts-calendar"
import { setActivityShiftListSelectedShiftId, setActivityShiftListShifts } from "store/actions/activityShiftListActions"
import { showToastAction, toggleInternetConnection } from "store/actions/appConfigurationActions"
import { RootState } from "store/types"
import { Shift } from "types/shifts"

export const ActivityShiftList: React.FC = () => {
    const [loadingShifts, setLoadingShifts] = useState(true)
    const { selectedShiftId, shifts } = useSelector((state: RootState) => state.activityShiftList)
    const dispatch = useDispatch()

    const loadShifts = async () => {
        setLoadingShifts(true)
        await fetchActionableShifts().then((response) => {
            dispatch(setActivityShiftListShifts(response))
            setLoadingShifts(false)
        }).catch((error) => {
            dispatch(setActivityShiftListShifts([]))
            dispatch(setActivityShiftListSelectedShiftId(null))
            if(error instanceof ApiApplicationError) {
                if (error.cause === 'NO_INTERNET') {
                    dispatch(toggleInternetConnection(false))
                } else {
                    dispatch(showToastAction({
                        message: error.message,
                        severity: 'error'
                    }))
                }
            }
            setLoadingShifts(false)
        })
    }

    const loadData = async () => {
        return fetchActionableShifts().then((response) => {
            dispatch(setActivityShiftListShifts(response))
        }).catch((error) => {
            dispatch(setActivityShiftListSelectedShiftId(null))
            if (error instanceof ApiApplicationError) {
                if (error.cause === 'NO_INTERNET') {
                    dispatch(toggleInternetConnection(false))
                } else {
                    dispatch(showToastAction({
                        message: error.message,
                        severity: 'error'
                    }))
                }
            }
        })
    }

    useEffect(() => {
        loadShifts()
    }, [])

    useEffect(() => {
        if (!selectedShiftId && shifts.length > 0) {
            dispatch(setActivityShiftListSelectedShiftId(shifts[0].id))
        } else if (shifts.length === 0) {
            dispatch(setActivityShiftListSelectedShiftId(null))
        }
    }, [shifts.length])

    return (
        <div
            className="flex h-full space-x-medium justify-between overflow-y-hidden"
        >
            <ActivityShiftListComponent
                shifts={shifts}
                loading={loadingShifts}
            />
            <ShiftDetailsSection
                reloadShifts={loadData}
                selectedShiftId={selectedShiftId}
                setSelectedShiftId={(id: number | null) => {
                    dispatch(setActivityShiftListSelectedShiftId(id))
                }}
            />
        </div>

    )
}