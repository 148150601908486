import { CircularProgress } from "@mui/material";
import { CustomInput } from "components/common/CustomInput";
import { SingleSelectItem } from "components/common/SingleSelectItem"
import { CANCEL_SHIFT_MODAL_BUTTON, CANCEL_SHIFT_MODAL_GO_BACK, CANCEL_SHIFT_MODAL_TITLE, REJECT_PROFESSIONAL_MODAL_BUTTON } from "locales/shiftClaimDetails/es";
import { useEffect, useState } from "react"
import { fetchShiftClaimRejectReasons } from "services/claims";
import { fetchShiftCancelReasons } from "services/shifts-calendar";
import { SpecializationDTO } from "types/shifts"

interface RejectProfessionalReasonsModalProps {
    goBack: () => void,
    rejectProfessional: (reason: string, details: string) => void,
    isOpen: boolean,
    title: string
}

export const RejectProfessionalReasonsModal: React.FC<RejectProfessionalReasonsModalProps> = ({
    goBack,
    rejectProfessional,
    isOpen,
    title
}) => {
    const isOpenClass = isOpen ? 'block' : 'hidden';
    const [reasons, setReasons] = useState<SpecializationDTO[]>([])
    const [selectedReason, setSelectedReason] = useState<string | null>(null)
    const [details, setDetails] = useState('')
    const validSelectedReason = selectedReason !== null && (selectedReason !== 'OTHER' || details.length > 0);
    const [loading, setLoading] = useState(true)

    const handleRejectProfessional = () => {
        if (validSelectedReason) {
            rejectProfessional(selectedReason!!, details)
        }
    }

    const handleGoBack = () => {
        setSelectedReason('')
        goBack()
    }

    useEffect(() => {
        if (isOpen) {
            setLoading(true)
            fetchShiftClaimRejectReasons()
                .then((response) => {
                    setReasons(response)
                    setLoading(false)
                })
                .catch((error) => {
                    goBack()
                })
        }

    }, [isOpen]);

    return (
        <div className={`flex justify-center fixed z-50 inset-0 overflow-y-auto ${isOpenClass} items-center`}>
            <div className="fixed inset-0 bg-black opacity-50 -z-50"></div>

            <div
                className="rounded-[16px] shadow-custom w-[410px] bg-white"
            >
                <div
                    className="p-medium"
                >

                    <p
                        className="heading-md mb-large"
                    >
                        {title}
                    </p>
                    <div
                        className="flex flex-col p-y-large"
                    >
                        {!loading ?
                            reasons.map((reason) => (
                                <div
                                    className="flex flex-col w-full p-small"
                                >
                                    <SingleSelectItem
                                        option={
                                            reason.translations.es
                                        }
                                        onClick={() => setSelectedReason(reason.name)}
                                        checked={selectedReason === reason.name}
                                    />
                                    {
                                        selectedReason === 'OTHER' && reason.name === 'OTHER' &&
                                        <div
                                            className="pt-small"
                                        >
                                            <CustomInput
                                                placeHolder="Detalles"
                                                selectedValue={details}
                                                setValue={setDetails}
                                            ></CustomInput>
                                        </div>
                                    }
                                </div>
                            )) :
                            <div
                                className="flex flex-col w-full p-small"
                            >
                                <CircularProgress />
                            </div>
                        }
                    </div>
                    <div
                        className="flex flex-row items-center mt-large"
                    >
                        <button
                            onClick={() => handleGoBack()}
                            className="flex flex-1 text-center text-Action-Primary items-center justify-center  py-large px-small"
                        >
                            <p
                                className="action-regular w-full"
                            >
                                {CANCEL_SHIFT_MODAL_GO_BACK}
                            </p>

                        </button>
                        <button
                            className={`flex flex-1 py-large px-small rounded-[100px] text-center ${validSelectedReason ? "bg-Negative-400 text-Text-Inverse" : "bg-Background-Secondary text-Text-Subtle"}`}
                            disabled={!validSelectedReason}
                            onClick={() => handleRejectProfessional()}
                        >
                            <p
                                className="w-full action-regular"
                            >
                                {REJECT_PROFESSIONAL_MODAL_BUTTON}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}