import React, { useState } from "react";
import { ProfessionalProfile } from "types/claims";
import { ShiftModalityEnum } from "types";
import { updateFacilityProfessional } from "services/professionals";
import RemoveFavoriteProfessionalModal from "components/professionals/RemoveFavoriteProfessionalModal";
import { ProfilePicture } from "components/shiftDetails/ProfilePicture";
import ToggleSwitch from "components/common/ToggleSwitch";
import { TOTAL_SHIFTS_SUBTITLE, TOTAL_SHIFTS_SUBTITLE_IN_FACILITY } from "locales/professionals/profile";
import { useDispatch, useSelector } from "react-redux";
import { ClaimSummary, RootState } from "store/types";
import { fetchClaimInfoAction } from "store/actions/claimActions";

interface ProfessionalProfileCardProps extends ProfessionalProfile {
  origin?: ClaimSummary;
  className?: string;
}

export default function ProfessionalProfileCard({
  id,
  firstName,
  lastName,
  profilePictureUrl,
  favorite,
  totalPerformedShifts,
  modality,
  origin,
  className,
}: ProfessionalProfileCardProps) {
  const [isFavorite, setIsFavorite] = useState(favorite);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const removeFavoriteModal = <RemoveFavoriteProfessionalModal
    isOpen={modalOpen}
    handleClose={() => setModalOpen(false)}
    professionalId={id}
    unfavoriteProfessional={() => {
      updateFacilityProfessional(id.toString(), false);
      setIsFavorite(false);
      setModalOpen(false);
      if (origin?.professionalId === id && origin?.shiftId && origin?.claimId) {
        dispatch(fetchClaimInfoAction(origin.shiftId, origin.claimId) as any);
      }
    }}
  />

  function handleFavoritePress() {
    if (isFavorite) {
      setModalOpen(true);
    } else {
      updateFacilityProfessional(id.toString(), true);
      setIsFavorite(true);
      if (origin?.professionalId === id && origin?.shiftId && origin?.claimId) {
        dispatch(fetchClaimInfoAction(origin.shiftId, origin.claimId) as any);
      }
    }
  }

  return (
    <div className={`flex justify-between ${className}`}>
      <div className="flex">
        <div className="w-12 h-12 relative">
          <ProfilePicture profilePictureUrl={profilePictureUrl} modality={modality || null} size={48} />
        </div>

        <div className="ml-4">
          <h3 className="text-lg font-semibold">
            {firstName} {lastName}
          </h3>

          <div className="flex flex-row items-center">
            <p className="subtitle-sm">
              {TOTAL_SHIFTS_SUBTITLE(totalPerformedShifts)}
            </p>

            <p className="info-caption ml-tiny text-Grey-800">
              {TOTAL_SHIFTS_SUBTITLE_IN_FACILITY}
            </p>
          </div>
        </div>
      </div>

      {isFavorite !== undefined && (
        <ToggleSwitch checked={isFavorite} onChange={handleFavoritePress} />
      )}

      {removeFavoriteModal}
    </div>
  );
}
