import { useEffect, useState } from "react";
import LivoIcon from "../../../assets/LivoLogo"
import { SlotReason, SlotReasonOption, ClaimRequest } from "../../../types/claims"
import { DropDownWithInput } from "components/publishShift/DropDownWithInput";
import { MultipleLineInput } from "components/common/MultipleLineInput";
import { ActionButton } from "components/common/ActionButton";
import { updateClaimSlotReason } from "services/claims";
import { useDispatch } from "react-redux";
import { showToastAction } from "store/actions/appConfigurationActions";
import { CANCEL_SLOT_REASON_BUTTON, SLOT_REASON_LABEL, SLOT_REASON_UPDATE_ERROR, SLOT_REASON_UPDATE_SUCCESS, UPDATE_SLOT_REASON_BUTTON } from "locales/shiftClaimDetails/es";

interface SlotReasonDetailsProps {
    slotReasonOptions: SlotReasonOption[]
    slotReason: SlotReason | null;
    claimId: number;
    shiftId: number;
    slotReasonCommentDisplayed :boolean;
    onUpdateSlotReason: (slotReason: SlotReason) => void;
}
export const SlotReasonDetails: React.FC<SlotReasonDetailsProps> = ({
    slotReason,
    slotReasonOptions,
    claimId,
    shiftId,
    slotReasonCommentDisplayed,
    onUpdateSlotReason
}) => {
    const [newComment, setNewComment] = useState(slotReason?.comment || '')
    const [newSlotReason, setNewSlotReason] = useState(slotReason?.value || '')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()  

    const isEditting = newComment !== slotReason?.comment || newSlotReason !== slotReason?.value

    const onCancelEdit = () => {
        setNewComment(slotReason?.comment || '')
        setNewSlotReason(slotReason?.value || '')
    }
    const updateClaimSlotReasonCall = () => {
        setLoading(true)
        updateClaimSlotReason(shiftId, claimId, newSlotReason, newComment).then(() => {
            setLoading(false)
            onUpdateSlotReason({
                value: newSlotReason,
                comment: newComment,
                displayText: slotReasonOptions.find((reason) => reason.value === newSlotReason)?.displayText || ''
            })
            dispatch(showToastAction(
                {
                    message: SLOT_REASON_UPDATE_SUCCESS,
                    severity: 'success'
                }
            ))
        }).catch((error) => {
            setLoading(false)
            dispatch(showToastAction(
                {
                    message: SLOT_REASON_UPDATE_ERROR,
                    severity: 'error'
                }
            ))
            })
    
    }
    
    return (
        <div
            className="flex flex-col space-y-medium p-medium"
        >
            <div
                className="flex flex-row items-center space-x-small"
            >
                <LivoIcon name='replace' size={24} color='#ACBBCB' />
                <p
                    className="body-regular"
                >
                    {SLOT_REASON_LABEL}
                </p>
            </div>
            <DropDownWithInput
                options={slotReasonOptions.map((reason) => {
                    return {
                        id: reason.value,
                        name: reason.displayText
                    }
                })}
                selectedOptionId={newSlotReason}
                setOptionId={(value) => setNewSlotReason(value)}
                placeHolder='Motivo de reemplazo'
                callToActionIcon={!isEditting ? 'pencil' : undefined}
            />
            <div>
                <MultipleLineInput
                    setInputValue={setNewComment}
                    inputValue={newComment}
                    placeHolder="Comentario adicional"
                    callToActionIcon={!isEditting ? 'pencil' : undefined}
                />
            </div>
            {isEditting ? <div
                className="flex flex-row items-center space-x-large"
            >
                <button
                    onClick={() => {
                        onCancelEdit()
                    }}
                    className="flex flex-1 text-center text-Negative-500 items-center justify-center  py-medium px-xLarge"
                >
                    <p
                        className="action-regular w-full"
                    >
                        {CANCEL_SLOT_REASON_BUTTON}
                    </p>

                </button>
                <ActionButton
                    onClick={() => {
                        updateClaimSlotReasonCall()
                    }}
                    isDisabled={newSlotReason.length === 0}
                    isLoading={loading}
                    style={{
                        flex: 1,
                    }}
                >
                    <p
                        className="w-full action-regular py-tiny"
                    >
                        {UPDATE_SLOT_REASON_BUTTON}
                    </p>
                </ActionButton>
            </div> : null}
        </div>
    )

}