import LivoIcon from "assets/LivoLogo"
import { CategoryTag } from "components/common/CategoryTag"
import { ModalContainer } from "components/common/ModalContainer"
import { HeaderComponent } from "components/publishShift/HeaderComponent"
import { SelectCategory } from "components/publishShift/SelectCategory"
import { useState } from "react"
import { Category } from "types/shifts"

interface EditCategoryComponentProps {
    selectedCategory: Category,
    availableCategories: Category[],
    setSelectedCategory: (category: Category) => void
    hasChanged?: boolean
}
export const EditCategoryComponent: React.FC<EditCategoryComponentProps> = ({
    selectedCategory,
    availableCategories,
    setSelectedCategory,
    hasChanged
}) => {
    const [selectCategoryModalOpen, setSelectCategoryModalOpen] = useState(false)

    const selectCategoryModal = (
        <ModalContainer
            isOpen={selectCategoryModalOpen}
        >
            <div
                className="flex flex-col bg-Background-Primary  rounded-[16px]"
            >
                <HeaderComponent
                    title={'Cambiar profesión'}
                    onClose={() => setSelectCategoryModalOpen(false)}
                />
                <div
                    className="w-[700px] flex  justify-start"
                >
                    <SelectCategory
                        categories={availableCategories}
                        onSelectingCategory={(category) => {
                            setSelectedCategory(category)
                            setSelectCategoryModalOpen(false)
                        }} />
                </div>
            </div>
        </ModalContainer>
    )
    return (
        <>
            <div
                onClick={() => setSelectCategoryModalOpen(true)}
                className="flex w-full items-center space-x-small cursor-pointer py-medium px-small rounded-[8px] hover:bg-gray-100 ring-1 ring-solid ring-Divider-Subtle justify-start"
            >
                <div
                    className="flex items-center space-x-small w-full"
                >
                    {hasChanged ? <div
                        className="w-small h-small bg-Action-Primary rounded-full"
                    /> : null}
                    {selectedCategory ? <CategoryTag
                        text={selectedCategory.acronym}
                    /> : null}
                    <p
                        className="body-regular text-Text-Subtle leading-none"
                    >
                        {selectedCategory?.displayText || 'Seleccionar profesión'}
                    </p>
                </div>
                <LivoIcon
                    name="chevron-down"
                    size={24}
                    color="#000"
                ></LivoIcon>
            </div>
            {selectCategoryModal}
        </>
    )
}