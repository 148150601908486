import { CategoryTag } from "components/common/CategoryTag";
import { ModalityTag } from "components/internal/ModalityTag";
import { ShiftModalityEnum } from "types";
import { Category } from "types/shifts";

interface CategoryAndVisibilityTagsProps {
    category?: Category;
    internalVisible: boolean;
    externalVisible: boolean;
    noMargin?: boolean;
}

export const CategoryAndVisibilityTags: React.FC<CategoryAndVisibilityTagsProps> = ({
    category,
    internalVisible,
    externalVisible,
    noMargin
}) => {
    return (
        category || internalVisible || externalVisible ? <div
            className={`flex flex-row items-center space-x-tiny ${!noMargin ? 'mb-[4px]' : ''}`}
        >
            {category ? <CategoryTag
                text={category.acronym} /> : null}
            {internalVisible ?
                <ModalityTag
                    modality={ShiftModalityEnum.INTERNAL}
                    shortTag={true}
                /> : null}
            {
                externalVisible ?
                    <ModalityTag
                        modality={ShiftModalityEnum.EXTERNAL}
                        shortTag={true}
                    />
                    : null
            }
        </div> : null
    );
}