import LivoIcon from "assets/LivoLogo"


interface SingleSelectItemProps {
    checked: boolean,
    onClick: () => void,
    option: string,
    disclaimer?: string
}

export const SingleSelectItem: React.FC<SingleSelectItemProps> = (
    {
        checked,
        onClick,
        option,
        disclaimer
    }
) => {
    return (
        <button
            className="flex justify-between items-center m-x-tiny"
            onClick={onClick}
        >
            <div className="flex flex-col text-left justify-start">
                <p
                    className={checked ? 'subtitle-regular text-Action-Primary' : 'body-regular text-Text-Subtle'}
                >

                    {
                        option
                    }
                </p>
                <p
                    className="body-sm text-Text-Subtle"
                >
                    {
                        disclaimer
                    }
                </p>
            </div>
            {

            }
            <LivoIcon name={checked ? 'radio-filled' : 'circle'} size={24} color={checked ? '#149EF2' : '#848DA9'} />

        </button>
    )
}