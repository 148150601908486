import { Category, Shift, Unit } from "types/shifts";

export const accesors: {
  [key: string]: (value: any) => {
    displayName: string;
    value: string;
  } } = {
  unit: (shift: Shift) => ({
    displayName: shift.unit ? shift.unit : "",
    value: shift.unit ? shift.unit : "",
  }),
  category: (shift: Shift) => ({
    displayName: shift.category ? shift.category.displayText : "",
    value: shift.category ? shift.category.code : "",
  })
}

const optionToDisplayTextMap: {
  [key: string]: (option: any) => string
} = {
  'unit': (unit: Unit) => unit.displayName,
  'category': (category: Category) => category.displayText,
};

const optionToValueMap: {
  [key: string]: (option: any) => string
} = {
  'unit': (unit: Unit) => unit.value,
  'category': (category: Category) => category.code,
};

export type ShiftFilterOption = Category | Unit;

export interface ShiftFilter {
  key: string
  name: string;
  options: ShiftFilterOption[];
  selectedOptions: ShiftFilterOption[];
  appliedOptions: ShiftFilterOption[];
}

export function evaluateItem(
  filter: ShiftFilter,
  item: Shift,
): boolean {
  const appliedOptionsMapped = filter.appliedOptions.map(optionToValueMap[filter.key]);
  const itemValue = accesors[filter.key](item).value;
  return itemValue == "" || appliedOptionsMapped.includes(itemValue);
}

export function applyFilter(
  filter: ShiftFilter,
  items: Shift[],
): Shift[] {
  return filter.appliedOptions.length > 0 ? items.filter((item) => evaluateItem(filter, item)) : items;
}

export function getOptionToDisplayText(filter: ShiftFilter): (option: any) => string {
  return optionToDisplayTextMap[filter.key];
}

export function getOptionToValue(filter: ShiftFilter): (option: any) => string {
  return optionToValueMap[filter.key];
}