import LivoIcon from "assets/LivoLogo";
import { FacilityPermission } from "services/facility-staff";

interface FacilityStaffPermissionCardProps {
    isEnabled: boolean;
    permission: FacilityPermission;
    togglePermission: () => void;
}
export const FacilityStaffPermissionCard: React.FC<FacilityStaffPermissionCardProps> = ({
    isEnabled,
    permission,
    togglePermission,
}) => {
    return (
        <div
            onClick={togglePermission}
            className={`cursor-pointer flex rounded-[8px] p-medium items-center space-x-tiny ${isEnabled ? 'ring-Action-Primary ring-[2px]' : ' ring-Divider-Default ring-[1px]'}`}
        >
            <div
                className="w-full"
            >
                <p
                    className="body-regular"
                >
                    {permission.title}
                </p>
                <p
                    className="info-caption text-Text-Subtle"
                >
                    {permission.description}
                </p>
            </div>
            <div
                onClick={togglePermission}
                className="items-center justify-center"
            >
                <LivoIcon name={isEnabled ? 'square-check-filled' : 'square'} size={24} color={isEnabled ? '#149EF2' : '#C6D0DB'} />
            </div>
        </div>
    )
}
