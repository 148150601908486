import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { NUSS, CERTIFICATE_LABEL, EXTRA_CERTIFICATE_1, EXTRA_CERTIFICATE_2, EXTRA_CERTIFICATE_3, CURRICULUM_LABEL, DEDUCTION_PERCENTAGE_DOCUMENT_LABEL, DOWNLOAD_ALL_ERROR, DOWNLOAD_ALL_FILES, IBAN_DOCUMENT_LABEL, LEGAL_DOCUMENTATION_TITLE, NATIONAL_ID, NON_CONVICTION_CERTIFICATE_LABEL, PAYMENT_RECEIPT_LABEL, PROFILE_PICTURE_DOCUMENT_LABEL, UNAVAILABLE_LEGAL_DOCUMENTATION, UNCOMPLETE_DOCUMENTATION_DISCLAIMER } from 'locales/shiftClaimDetails/es';
import { ProfessionalInitProfile, ProfessionalLegalProfile } from 'types';
import { Box, CircularProgress } from '@mui/material';
import FileThumbnail from './FileThumbnail';
import LivoIcon from 'assets/LivoLogo';
import { downloadZipFile } from 'services/api';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { showToastAction } from 'store/actions/appConfigurationActions';
import { allFalse, allNonEmpty } from 'utils/utils';


function hasAllRequiredDocuments(professionalInitProfile: ProfessionalInitProfile, professionalLegalProfile: ProfessionalLegalProfile): boolean {
    const documentUrls = Object.entries({...professionalInitProfile, ...professionalLegalProfile})
        .filter(([key, value]) => (key.endsWith('Url') || key.endsWith('CV')) && key !== 'socialSecurityNumberDocumentUrl')
        .map(([_, value]) => value as string);
    return allNonEmpty(...documentUrls) && allFalse(professionalLegalProfile.nationalIdDocExpired);
}


interface LegalDocumentationCardProps {
    professionalInitProfile: ProfessionalInitProfile,
    professionalLegalProfile?: ProfessionalLegalProfile
    shiftClaimId: number
}

export default function LegalFilesCard({ professionalInitProfile, professionalLegalProfile, shiftClaimId }: LegalDocumentationCardProps) {

    const [downloadingFile, setDownloadingFile] = React.useState(false)
    const legalDocumentationNotAvailable = (
        <Typography variant='body1' color='text.secondary' gutterBottom>
            {UNAVAILABLE_LEGAL_DOCUMENTATION}
        </Typography>
    )
    const dispatch = useDispatch<AppDispatch>()
    return (
        <Card sx={{ m: 1, minWidth: 600, flex: 1 }}>
            <CardContent>

                <div
                    className='justify-between flex flex-row items-center'
                >
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {LEGAL_DOCUMENTATION_TITLE}
                    </Typography>
                    {downloadingFile ?
                        <div
                            className='flex flex-row items-center px-large'
                        >
                            <CircularProgress size={24} color="primary" />
                        </div>
                        :
                        <button
                            onClick={() => {
                                setDownloadingFile(true)
                                downloadZipFile(shiftClaimId)
                                    .then(() => {
                                        setDownloadingFile(false)
                                    })
                                    .catch((error) => {
                                        setDownloadingFile(false)
                                        dispatch(showToastAction({
                                            message: DOWNLOAD_ALL_ERROR,
                                            severity: 'error'
                                        }))
                                    })
                            }}
                            className='flex flex-row items-center py-small pr-large pl-medium ring-1 ring-solid ring-[#848DA9] rounded-[100px]'
                        >
                            <p
                                className='action-regular text-Primary-500 mr-tiny'
                            >
                                {DOWNLOAD_ALL_FILES}
                            </p>
                            <LivoIcon name={'download'} size={16} color={'#149EF2'} />
                        </button>}
                </div>
                {professionalLegalProfile ?
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            !hasAllRequiredDocuments(professionalInitProfile, professionalLegalProfile) &&
                            <p className='body-regular mt-[24px] text-Text-Default'>
                                {UNCOMPLETE_DOCUMENTATION_DISCLAIMER}
                            </p>
                        }

                        <FileThumbnail fileUrl={professionalLegalProfile.nationalIdUrl} label={NATIONAL_ID} isExpired={professionalLegalProfile.nationalIdDocExpired} />
                        {professionalLegalProfile.socialSecurityNumberDocumentUrl && <FileThumbnail fileUrl={professionalLegalProfile.socialSecurityNumberDocumentUrl} label={NUSS} />} 
                        <FileThumbnail fileUrl={professionalLegalProfile.noConvictionCertificateUrl} label={NON_CONVICTION_CERTIFICATE_LABEL} />
                        <FileThumbnail fileUrl={professionalLegalProfile.paymentReceiptUrl} label={PAYMENT_RECEIPT_LABEL} />
                        <FileThumbnail fileUrl={professionalLegalProfile.certificateUrl} label={CERTIFICATE_LABEL} />
                        {professionalLegalProfile.extraCertificateUrl1 && <FileThumbnail fileUrl={professionalLegalProfile.extraCertificateUrl1} label={EXTRA_CERTIFICATE_1} />}
                        {professionalLegalProfile.extraCertificateUrl2 && <FileThumbnail fileUrl={professionalLegalProfile.extraCertificateUrl2} label={EXTRA_CERTIFICATE_2} />}
                        {professionalLegalProfile.extraCertificateUrl3 && <FileThumbnail fileUrl={professionalLegalProfile.extraCertificateUrl3} label={EXTRA_CERTIFICATE_3} />}
                        <FileThumbnail fileUrl={professionalInitProfile.professionalCV} label={CURRICULUM_LABEL} />
                        <FileThumbnail fileUrl={professionalLegalProfile.bankAccountDocumentUrl} label={IBAN_DOCUMENT_LABEL} />
                        <FileThumbnail fileUrl={professionalLegalProfile.incomeRetentionChangeRequestUrl} label={DEDUCTION_PERCENTAGE_DOCUMENT_LABEL} />
                        <FileThumbnail fileUrl={professionalInitProfile.profilePictureUrl} label={PROFILE_PICTURE_DOCUMENT_LABEL} />
                    </Box> : legalDocumentationNotAvailable
                }
            </CardContent>
        </Card>
    );
}
