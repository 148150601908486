import { HOLIDAY_TAG } from "locales/shiftClaimDetails/es";

export const HolidayTag = ({ holiday }: { holiday?: boolean }) => {
    if (!holiday) {
        return null;
    }
    return (
        <div className="ring-[2px] px-[2px] ring-Purple-400 rounded-[4px]">
            <p
                className="info-caption text-Purple-900"
            >
                {HOLIDAY_TAG}
            </p>
        </div>
    );
}