import React, { useEffect, useState } from "react";
import { fetchLegalReviewList } from "../services/api";
import { ShiftClaimDetails } from "../types";
import Table from '@mui/material/Table';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { formatDateToYYYYMMDD } from "utils";
import '../styles/ShiftClaimListScreen.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography";
import { FacilityReviewStatusEnum, reviewStatusLabelProps } from "utils/constants";
import { Box, Chip, CircularProgress, Container, Link, TablePagination } from "@mui/material";
import { CONFIRMED_SHIFTS, HEADER_TITLE, PAST_SHIFTS, PROFESSIONAL_TABLE_TITLE, FACILITY_NAME_TITLE, SHIFT_DAY_TABLE_TITLTE, SPECIALIZATION_TABLE_TITLE, STATUS_TABLE_TITLE, UPCOMING_SHIFTS, PRICE_TABLE_TITLE, PENDING_LABEL, ID_TABLE_TITLE } from "locales/shiftClaimList/es";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import FirstShifterTag from "components/common/FirstShifterTag";
export const PAGE_SIZE = 10;


export const ShiftClaimListScreen: React.FC = () => {
  const [shiftClaimsData, setShiftClaimsData] = useState<ShiftClaimDetails[]>([]);
  const [displayFacilityName, setDisplayFacilityName] = useState<Boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("DEFAULT"); // Default tab
  const [page, setPage] = useState(0);
  const [totalShifts, setTotalShifts] = useState(0)
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue)
    setPage(0)
  }
  const navigate = useNavigate();

  const today = new Date();
  const dayBeforeToday = new Date(today);
  dayBeforeToday.setDate(dayBeforeToday.getDate() - 1);

  const filters = {
    PAST: {
      page: page + 1,
      toDate: formatDateToYYYYMMDD(dayBeforeToday),
      sortOrder: 'DESC',
      size: PAGE_SIZE
    },
    CONFIRMED: {
      page: page + 1,
      facilityReviewStatuses: [FacilityReviewStatusEnum.CONFIRMED],
      fromDate: formatDateToYYYYMMDD(today),
      sortOrder: 'ASC',
      size: PAGE_SIZE
    },
    DEFAULT: {
      page: page + 1,
      facilityReviewStatuses: [FacilityReviewStatusEnum.UNAVAILABLE, FacilityReviewStatusEnum.IN_REVIEW],
      fromDate: formatDateToYYYYMMDD(today),
      sortOrder: 'ASC',
      size: PAGE_SIZE
    }
  }


  const fetchShiftData = async () => {
    const filter = filters[selectedTab as keyof typeof filters]
    setLoading(true);
    fetchLegalReviewList(filter)
      .then(shiftClaimResponse => {
        setShiftClaimsData(shiftClaimResponse.rows);
        setTotalShifts(shiftClaimResponse.total);
        setDisplayFacilityName(shiftClaimResponse.rows.some((shiftClaim) => shiftClaim.facilityName !== null));
        setLoading(false);;
      })
      .catch(error => {
        setShiftClaimsData([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchShiftData();
  }, [selectedTab, page]);



  const getStatusLabel = (facilityReviewStatus: string) => {
    const statusLabelProp = reviewStatusLabelProps[facilityReviewStatus as keyof typeof reviewStatusLabelProps]
    return (
      <Chip label={statusLabelProp.label} color={statusLabelProp.colorTheme} />
    )
  }


  const content = loading ? (
    <Container sx={{ display: 'flex', flex: 1, alignItems: 'center', minHeight: '30em', minWidth: '100%', justifyContent: 'center' }}>
      <CircularProgress />
    </Container>
  ) :
    (
      <TableContainer component={Paper} className="table">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>{SHIFT_DAY_TABLE_TITLTE}</TableCell>
              {displayFacilityName && <TableCell>{FACILITY_NAME_TITLE}</TableCell>}
              <TableCell>{PROFESSIONAL_TABLE_TITLE}</TableCell>
              <TableCell>{ID_TABLE_TITLE}</TableCell>
              <TableCell>{SPECIALIZATION_TABLE_TITLE}</TableCell>
              <TableCell>{PRICE_TABLE_TITLE}</TableCell>
              <TableCell>{STATUS_TABLE_TITLE}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shiftClaimsData
              .map((claim) => (
                <TableRow key={claim.shiftClaimId} sx={{
                  cursor: 'pointer',
                }}
                  hover
                  component={Link}
                  href={`/shift-claim/${claim.shiftClaimId}`}
                  underline="none"
                  >
                  <TableCell className="link-text">
                    {formatDateToYYYYMMDD(new Date(claim.shift.startTime))}
                  </TableCell>
                  {displayFacilityName && <TableCell>{claim.facilityName}</TableCell>}
                  <TableCell>
                    <div className="flex flex-row items-center justify-between">
                      <Typography noWrap variant="body2" sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '20ch'
                      }}>
                        {claim.professionalInitProfile.firstName} {claim.professionalInitProfile.lastName}
                      </Typography>

                      { claim.professionalInitProfile.firstShifter &&
                        <FirstShifterTag className="ml-[4px]"/>
                      }
                    </div>
                  </TableCell>
                  <TableCell>{claim.professionalLegalProfile?.nationalId ?? PENDING_LABEL}</TableCell>
                  <TableCell>{claim.shift.specialization.translations.es}</TableCell>
                  <TableCell>{claim.shift.formattedTotalPay} </TableCell>
                  <TableCell>
                    {getStatusLabel(claim.facilityReviewStatus)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalShifts}
          page={page}
          rowsPerPageOptions={[]}
          onPageChange={(event, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={PAGE_SIZE}
          labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
        />
      </TableContainer>
    )

  const theme = useTheme()
  return (
    <div
    className="flex content overflow-auto"
  >
    <Container sx={{ pt: 2, pb: 2, height: '100vh'}}>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          {HEADER_TITLE}
        </Typography>
      </Box>
      <div className="table-container">
        <Tabs value={selectedTab} onChange={handleTabChange} component={Paper} sx={{ mb: 2 }} variant="fullWidth" >
          <Tab label={UPCOMING_SHIFTS} value="DEFAULT" />
          <Tab label={CONFIRMED_SHIFTS} value="CONFIRMED" />
          <Tab label={PAST_SHIFTS} value="PAST" />
        </Tabs>
        {content}
      </div>
    </Container>
    </div>
  )
};

