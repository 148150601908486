import { CircularProgress } from "@mui/material"
import { ActivityShiftCard } from "./ActivityShiftCard"
import { ACTIVITY_TITLE } from "locales/calendar"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef } from "react"
import { RootState } from "store/types"
import { Shift } from "types/shifts"
import { setActivityShiftListSelectedShiftId } from "store/actions/activityShiftListActions"
import { EmptyDetailsComponent } from "./EmptyDetailsComponent"
import { EmptyCardComponent } from "./EmptyCardComponent"

interface ActivityShiftListProps {
    loading: boolean
    shifts: Shift[]
}
export const ActivityShiftListComponent: React.FC<ActivityShiftListProps> = ({
    loading,
    shifts
}) => {
    const { selectedShiftId } = useSelector((state: RootState) => state.activityShiftList) // change for activty
    const dispatch = useDispatch()

    const shiftRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    useEffect(() => {
        if (selectedShiftId && shiftRefs.current[selectedShiftId]) {
            shiftRefs.current[selectedShiftId]?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selectedShiftId]);

    return (
        <>
            <div
                className="flex flex-1 pt-[24px] overflow-y-auto"
            >
                <div
                    className="flex flex-col w-full h-full px-medium"
                >
                    <div
                        className="flex w-full justify-between items-center mb-large space-x-small"

                    >

                        <p
                            className="heading-md text-ellipsis text-nowrap"
                        >
                            {ACTIVITY_TITLE}
                        </p>
                    </div>
                    <div
                        className="flex flex-col w-full space-y-small h-full"
                    >
                        {loading ? (
                            <div
                                className="flex flex-1 justify-center items-center h-full"
                            >
                                <CircularProgress />
                            </div>
                        ) : shifts.length === 0 ? (
                            <div
                                className="flex flex-col"

                            >
                                <EmptyCardComponent />
                            </div>
                        ) :
                            <div className={"flex flex-col  space-y-small"}>
                                {shifts
                                    .map((shift, index) => {
                                        return <div key={index}
                                            ref={(el: any) => {
                                                shiftRefs.current[shift.id] = el
                                            }}
                                        >
                                            <ActivityShiftCard
                                                shift={shift as Shift}
                                                onClick={() => {
                                                    dispatch(setActivityShiftListSelectedShiftId(shift.id)) // change for activity
                                                }}
                                                isSelected={selectedShiftId === shift.id}
                                            />
                                        </div>
                                    })}
                            </div>
                        }
                    </div>

                </div>

            </div >
            
            {shifts.length === 0 && !loading ?
                <div
                    className="flex w-2/3 border-l-[1px] border-Divider-Default"
                >
                    <EmptyDetailsComponent />
                </div> : null
            }
        </>)
  
}