import { CircularProgress } from "@mui/material";
import { EmptyShiftsState } from "components/calendar/EmptyShiftsState";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { ActionComponentIdEnum, DayShift, Shift, ShiftTimeInDayEnum } from "types/shifts";
import { ShiftListCard } from "./ShiftListCard";
import { formatDate, formatDateWithToday } from "utils";
import { setPublishShiftListSelectedShiftId } from "store/actions/publishShiftListActions";
import { HolidayTag } from "components/shiftDetails/HolidayTag";

interface DayShiftComponentProps {
    dayShift: DayShift;
    actionComponents: {
        iconName: string,
        onClick: (shift: Shift) => void,
        id: ActionComponentIdEnum
    }[];
}
export const DayShiftComponent: React.FC<DayShiftComponentProps> = ({
    dayShift,
    actionComponents
}) => {

    const { selectedShiftId } = useSelector((state: RootState) => state.publishShiftList)
    const shiftRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    useEffect(() => {
        if (selectedShiftId && shiftRefs.current[selectedShiftId]) {
            shiftRefs.current[selectedShiftId]?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selectedShiftId]);

    const dispatch = useDispatch()
    return (
        <div
            className="flex flex-col w-full"
        >
            <div
                className="flex items-center space-x-small mb-small"
            >
                <p
                    className="subtitle-regular"
                >
                    {formatDateWithToday(dayShift.date)}
                </p>
                <HolidayTag
                    holiday={dayShift.holiday}
                />
            </div>

            <div className={"flex flex-col  space-y-small"}>
                {dayShift.shifts
                    .map((shift, index) => {
                        return <div key={index}
                            ref={(el: any) => {
                                shiftRefs.current[shift.id] = el
                            }}
                        >
                            <ShiftListCard
                                shift={shift as Shift}
                                onClick={() => {
                                    dispatch(setPublishShiftListSelectedShiftId(shift.id))
                                }}
                                isSelected={selectedShiftId === shift.id}
                                actionComponents={actionComponents}
                            />
                        </div>
                    })}
            </div>
        </div>
    )
}