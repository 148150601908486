import { ClaimRequest } from "./claims";

export interface SpecializationDTO {
  name: string;
  translationDefault: string;
  translations: {
    es: string;
  };
}
export enum ShiftTimeInDayEnum {
  MORNING = 'DAY_SHIFT',
  EVENING = 'EVENING_SHIFT',
  NIGHT = 'NIGHT_SHIFT',

}
export enum ShiftTimeStatusEnum {
  "PAST" = "PAST",
  "ONGOING" = "ONGOING",
  "UPCOMING" = "UPCOMING"
}

export type Unit = {
  displayName: string;
  value: string;
}

export type Category = {
  code: string;
  acronym: string;
  displayText: string;
  visibleForLivoPool: Boolean;
  visibleForLivoInternal: Boolean;
}

export interface Shift {
  id: number;
  shiftTimeInDay: ShiftTimeInDayEnum;
  date: string;
  status: string;
  capacity: number;
  specialization: SpecializationDTO;
  totalAcceptedClaims: number;
  totalPendingClaims: number;
  hourRate: string;
  totalPay: string;
  formattedTotalPay: string;
  currency: string;
  startTime: string;
  finishTime: string;
  details?: string;
  shiftTimeStatus: ShiftTimeStatusEnum;
  unit: string;
  internalVisible: boolean;
  externalVisible: boolean;
  livoPoolOnboarded: boolean;
  livoInternalOnboarded: boolean;
  totalCancellationRequests: number;
  claims: ClaimRequest[];
  recurrentDates: string[];
  cancellableShiftDates: string[];
  externalId: string;
  shouldShowSlotReasonList: boolean;
  skills: {
    displayText: string;
    value: string;
  }[]
  category: Category
  title: string;
  holiday?: boolean;
  unitVisible: boolean;
}


export interface DayShift {
  date: string;
  holiday?: boolean;
  shifts: Shift[];
  hasAlert: boolean;
}

export interface FilterConfiguration {
  withPendingClaims?: boolean;
  isFilled?: boolean;
  units?: string[];
}

export enum ActionComponentIdEnum {
  EDIT = 'EDIT',
  COPY = 'COPY',
}
