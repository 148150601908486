import LivoIcon from "assets/LivoLogo";
import { FAVORITE_LABEL } from "locales/professionals/favorite";

export default function FavoriteTag() {
  return (
    <div className="flex items-center flex-row">
      <LivoIcon name="heart-filled" size={12} color="#FF5A59"/>
      <span className="subtitle-sm ml-tiny">
        {FAVORITE_LABEL}
      </span>
    </div>
  );
}
