import LivoIcon from "assets/LivoLogo"

interface InformationRowProps {
    iconName: string;
    children: React.ReactNode;
    iconColor?: string;
    style?: any;
}


export const InformationRow: React.FC<InformationRowProps> = ({
    iconName,
    children,
    iconColor,
    style
}) => {
    return (
        <div
            className="flex flex-row w-full space-x-small items-center"
            style={style}
        >
            <div
                className="flex items-center justify-center"
            >
                <LivoIcon name={iconName} size={24} color={iconColor ? iconColor : '#ACBBCB'} />
            </div>
            <div
                className="flex flex-1 items-center space-x-small text-Text-Default body-sm"
            >
            {children}

            </div>
        </div>
    )
}