import { InformationRow } from "components/common/InformationRow";
import { ModalContainer } from "components/common/ModalContainer";
import { DELETE_USER_BUTTON, DELETE_USER_MODAL_TITLE } from "locales/facilitystaff";
import { CANCEL_LABEL } from "locales/internalProfessionalPage";

interface DeleteUserModalProps {
    onDelete: () => Promise<void>;
    onClose: () => void;
    isOpen: boolean;
    userName: string;
    email: string;
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
    onDelete,
    onClose,
    isOpen,
    userName,
    email
}) => {

    return (<ModalContainer
        isOpen={isOpen}
        onClose={onClose}
    >

        <div
            className="rounded-[16px] shadow-custom w-[410px] bg-white"
        >
            <div
                className="px-xLarge py-medium space-y-large"
            >
                <p
                    className="heading-md mb-large"
                >
                    {DELETE_USER_MODAL_TITLE}
                </p>
                <div
                    className="flex flex-col p-medium ring-[1px] ring-Divider-Strong rounded-[8px] space-y-medium"
                >
                    <InformationRow
                        iconName="user"
                        style={{
                            paddingVertical: '8px'
                        }}
                    >
                        <p
                            className="body-regular"
                        >
                            {userName}
                        </p>
                    </InformationRow>
                    <InformationRow
                        iconName="mail"
                        style={{
                            paddingVertical: '8px'
                        }}
                    >
                        <p
                            className="body-regular"
                        >
                            {email}
                        </p>
                    </InformationRow>

                </div>
                <div
                    className="flex flex-row items-center"
                >
                    <button
                        onClick={() => onClose()}
                        className="flex flex-1 text-center text-Action-Primary items-center justify-center  py-large px-small"
                    >
                        <p
                            className="action-regular w-full"
                        >
                            {CANCEL_LABEL}
                        </p>

                    </button>
                    <button
                        className={`flex flex-1 py-large px-small rounded-[100px] text-center bg-Negative-400 text-Text-Inverse`}
                        onClick={() => onDelete()}
                    >
                        <p
                            className="w-full action-regular"
                        >
                           {DELETE_USER_BUTTON}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    </ModalContainer>)
}