import { CircularProgress } from "@mui/material"
import { ExpandableContainer } from "../../components/common/ExpandableContainer"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../store"
import { DataFieldSubmission, FacilityDataFieldDefinition, InternalProfessional, InvitationStatus } from "../../types/internal"
import { InternalProfessionalHeader } from "./InternalProfessionalHeader"
import { acceptProfessionalRequest, deleteProfessional, fetchInternalProfessionalInfo, rejectProfessionalRequest, updateInternalProfessionalInfo } from "../../services/internal"
import { InternalProfessionalDetails } from "./InternalProfessionalDetails"
import { showToastAction } from "store/actions/appConfigurationActions"
import { ApiApplicationError } from "services/api"
import { Category } from "types/shifts"


interface InternalProfessionalManagementProps {
    selectedProfessionalId: number | null,
    setSelectedProfessionalId: (id: number | null) => void,
    handleProfessionalRequest: () => void,
    editProfessionalDetails: (id: number, data: InternalProfessional) => void,
    onDeleteProfessional: (id: number) => void,
    dataFieldDefinitions: FacilityDataFieldDefinition[]
}
export const InternalProfessionalManagement: React.FC<InternalProfessionalManagementProps> = ({
    selectedProfessionalId,
    setSelectedProfessionalId,
    handleProfessionalRequest,
    editProfessionalDetails,
    onDeleteProfessional,
    dataFieldDefinitions
}) => {
    const [professionalDetails, setProfessionalDetails] = useState<InternalProfessional | null>(null)
    const [loading, setLoading] = useState(false)
    const [isEditting, setIsEditting] = useState(false)

    const dispatch = useDispatch<AppDispatch>()

    const loadData = async () => {
        if (!selectedProfessionalId) {
            return
        }
        setLoading(true)
        await fetchInternalProfessionalInfo(selectedProfessionalId)
            .then((data) => {
                setLoading(false)
                if (data) {
                    setProfessionalDetails(data)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.error(error)
            })
    }

    useEffect(() => {
        loadData()
        setIsEditting(false)
    }, [selectedProfessionalId])

    const flex = "30%"
    const handleUpdateProfessional = async (newConfiguration: DataFieldSubmission[], newCategory: Category) => {
        setLoading(true)
        await updateInternalProfessionalInfo(
            professionalDetails!!.id,
            {
                dataFields: newConfiguration,
                category: newCategory.code
            }
        ).then(async (updatedProfile: InternalProfessional) => {
            setLoading(false)
            dispatch(showToastAction({
                message: 'Cambios guardados',
                severity: 'success'
            }))
            setIsEditting(false);
            setLoading(true)
            await loadData();
           editProfessionalDetails(
                professionalDetails!!.id,
                updatedProfile
            );
        }).catch((error) => {
            setLoading(false)
            setIsEditting(false);
            console.log(error)
            dispatch(showToastAction({
                message: error instanceof ApiApplicationError ? error.message : 'Error al guardar cambios',
                severity: 'error'
            }))
        })
    }

    const handleAcceptProfessional = async (newConfiguration: DataFieldSubmission[], newCategory: Category) => {
        setLoading(true)
        await acceptProfessionalRequest(
            professionalDetails!!.id,
            {
                dataFields: newConfiguration,
                category: newCategory.code
            }
        ).then(async () => {
            setLoading(false)
            dispatch(showToastAction({
                message: 'Profesional añadido',
                severity: 'success'
            }))
            setLoading(true)
            await loadData();
            await handleProfessionalRequest();
        }).catch((error) => {
            setLoading(false)
            dispatch(showToastAction({
                message: error instanceof ApiApplicationError ? error.message : 'Error al aceptar al profesional',
                severity: 'error'
            }))
        })
    }

    const handleRejectProfessional = async () => {
        setLoading(true)
        await rejectProfessionalRequest(
            professionalDetails!!.id

        ).then(async () => {
            setLoading(false)
            dispatch(showToastAction({
                message: 'Has rechazado la solicitud',
                severity: 'success'
            }))
            setIsEditting(false);
            setLoading(true)
            setSelectedProfessionalId(null)
            await handleProfessionalRequest();
        }).catch((error) => {
            setLoading(false)
            dispatch(showToastAction({
                message: error instanceof ApiApplicationError ? error.message : 'Error al rechazar la solicitud',
                severity: 'error'
            }))
        })
    }

    const handleDeleteProfessional = async () => {
        setLoading(true)
        await deleteProfessional(
            professionalDetails!!.id

        ).then(async () => {
            setLoading(false)
            dispatch(showToastAction({
                message: 'Has dado de baja al profesional',
                severity: 'success'
            }))
            setSelectedProfessionalId(null)
            setIsEditting(false)
            onDeleteProfessional(professionalDetails!!.id);
        }).catch((error) => {
            setLoading(false)
            dispatch(showToastAction({
                message: error instanceof ApiApplicationError ? error.message : 'Error al eliminar al profesional',
                severity: 'error'
            }))
        })
    }


    return (
        <ExpandableContainer
            width={selectedProfessionalId ? flex : '0%'}
        >
            <div
                className={`flex bg-white h-full border-l-[1px] border-solid border-Divider-Subtle`}
            >
                {loading && selectedProfessionalId ?

                    <div
                        className="flex flex-col items-center justify-center h-full w-full"
                    >
                        <CircularProgress />
                    </div>
                    :
                    selectedProfessionalId && professionalDetails ?

                        <div
                            className="flex flex-1 flex-col  pt-[24px] overflow-y-scroll w-full h-full no-scrollbar space-y-small"
                        >
                            <InternalProfessionalHeader
                                onClose={() => {
                                    setSelectedProfessionalId(null)
                                }}
                                onDelete={() => {
                                    handleDeleteProfessional()
                                }}
                                onEdit={() => {
                                    setIsEditting(true)
                                }}
                                editable={professionalDetails.invitationStatus === InvitationStatus.ACCEPTED}
                            />


                            <InternalProfessionalDetails
                                professional={professionalDetails}
                                onEdit={(newDetails: DataFieldSubmission[], newCategory: Category) => {
                                    handleUpdateProfessional(newDetails, newCategory)
                                }}
                                onCancelEdit={() => {
                                    setIsEditting(false)
                                }}
                                isEditting={isEditting}
                                onAcceptProfessional={(newDetails: DataFieldSubmission[], newCategory: Category) => {
                                    handleAcceptProfessional(newDetails, newCategory)
                                }}
                                onRejectProfessional={handleRejectProfessional}
                                dataFieldDefinitions={dataFieldDefinitions}
                            />
                        </div>
                        : null}
            </div >
        </ExpandableContainer>

    )
}