export const USER_NAME_LABEL = 'Usuario';
export const UNITS_LABEL = 'Unidades';
export const EMAIL_LABEL = 'Correo';
export const PERMISSIONS_LABEL = 'Permisos';
export const SEARCH_PLACEHOLDER = 'Buscar...';
export const FACILITY_STAFF_PAGE_TITLE = 'Administración';
export const UPDATE_STATT_BUTTON = 'Guardar cambios';
export const UPDATE_STAFF_SUCCESS = 'Cambios guardados con éxito';
export const UPDATE_STAFF_ERROR = 'Error al guardar los cambios';
export const CREATE_STAFF_SUCCESS = 'Usuario creado con éxito';
export const CREATE_STAFF_ERROR = 'Error al crear el usuario';
export const CREATE_STAFF_BUTTON = 'Crear usuario';
export const ADMIN_PERMISSIONS_TEXT = 'Permisos de administrador';
export const PERSONAL_DATA_TITLE = 'Datos';
export const UNITS_TO_SHOW = 'Se mostrarán turnos de:';
export const NO_UNITS_SELECTED = 'Todas las unidades';
export const ADD_UNIT = 'Asignar unidad';
export const ADD_USER_BUTTON = 'Añadir usuario';
export const DELETE_USER_BUTTON = 'Eliminar usuario';
export const DELETE_USER_SUCCESS = 'Usuario eliminado con éxito';
export const DELETE_USER_ERROR = 'Error al eliminar el usuario';
export const DELETE_USER_MODAL_TITLE = '¿Estás seguro de que quieres eliminar este usuario?';
