
import { DropwDown } from "./DropDown";
import { HOURLY_RATE, TOTAL_RATE } from "locales/publishShift";
import { CustomInput } from "components/common/CustomInput";

interface PriceSelectorProps {
    setPrice: (price: string) => void;
    selectedPrice: string;
    selectedModality: string;
    setSelectedModality: (modality: string) => void;
    errorMessage?: string;
    isDisabled?: boolean;
};

export const PriceSelector: React.FC<PriceSelectorProps> = ({
    setPrice,
    selectedPrice,
    selectedModality,
    setSelectedModality,
    errorMessage,
    isDisabled
}) => {

    return (
        <div className="flex flex-col w-full">
            <div
                className="flex flex-row space-x-medium"
                style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
            >
                <div className="w-1/2">
                    <CustomInput
                        setValue={setPrice}
                        selectedValue={selectedPrice}
                        placeHolder="Precio"
                        endingLabel="€"
                        errorMessage={errorMessage}
                        inputType="number"
                        disabled={isDisabled}
                        label={"Precio"}
                    />
                </div>
                <div className="w-1/2">

                    <DropwDown
                        setOptionId={setSelectedModality}
                        selectedOptionId={selectedModality}
                        placeHolder="Modalidad"
                        options={[
                            { id: 'hourly', name: HOURLY_RATE },
                            { id: 'total', name: TOTAL_RATE },
                        ]}
                        disabled={isDisabled}
                        label={"Modalidad"}
                    />
                </div>
            </div>
        </div>
    )
}