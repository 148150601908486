import LivoIcon from "assets/LivoLogo"
import { EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE, EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE_2, EMPTY_SHIFTS_STATE_TITLE, EMTPY_SHIFTS_STATE_PUBLISH_SHIFT } from "locales/calendar"

interface EmptyShiftsStateProps {
    date: string,
    shiftTimeInDay: string,
    onClick: () => void
}
export const EmptyShiftsState: React.FC<EmptyShiftsStateProps> = ({
    date,
    shiftTimeInDay,
    onClick
}) => {
    return (
        <div
            className="bg-white rounded-[16px] relative pl-small"
            style={{
                minWidth: '176px',
            }}
        >
            <div
                className="p-medium flex flex-col"
            >
                <p
                    className="body-regular text-Text-Default"
                >
                    {EMPTY_SHIFTS_STATE_TITLE}&nbsp;
                </p>
                <p
                    className="body-regular text-Text-Default"
                >
                    {EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE}
                    <span
                        className="text-Primary-500 cursor-pointer"
                        onClick={onClick}
                    >
                        +
                    </span>
                    {EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE_2}
                </p>
            </div>

        </div>
    )
}