import LivoIcon from "assets/LivoLogo";
import { ShiftTimeInDayEnum } from "types/shifts";
import { SHIFT_TIME_IN_DAY_DEFINITIONS } from "utils";

interface ShiftInDaySelectorProps {
    shiftTimeInDay: string;
    setShiftTimeInDay: (shiftTimeInDay: string) => void;
}

export const ShiftTimeInDaySelector: React.FC<ShiftInDaySelectorProps> = ({
    shiftTimeInDay,
    setShiftTimeInDay
}) => {
    return (
        <div
            className="flex flex-col border-[1px] border-solid border-Divider-Subtle rounded-[8px] p-small w-full space-y-medium self-start"
        >
            {Object.keys(SHIFT_TIME_IN_DAY_DEFINITIONS).map((key, index) => {
                const props = SHIFT_TIME_IN_DAY_DEFINITIONS[key as ShiftTimeInDayEnum]
                return (
                    <button
                        key={index}
                        onClick={() => setShiftTimeInDay(props.hashKey)}
                        className="flex flex-row justify-between items-center space-x-tiny"
                    >
                        <div
                            className="flex flex-row space-x-tiny items-center"

                        >
                            <LivoIcon name={props.icon} size={18} color={props.color} />
                            <p
                                className="body-regular"
                            >
                                {props.name}
                            </p>
                        </div>
                        {
                            <LivoIcon name={shiftTimeInDay === props.hashKey ? 'radio-filled' : 'circle'} size={18} color={shiftTimeInDay === props.hashKey ? '#149EF2' : '#ACBBCB'} />
                        }
                    </button>
                )
            })}
        </div>
    )
}