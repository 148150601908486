import { ProfessionalProfile } from "types/claims"
import { ProfilePicture } from "../ProfilePicture"
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { ShiftModalityEnum } from "types";
import { InternalProfessional } from "types/internal";
import { CategoryTag } from "components/common/CategoryTag";

interface ProfessionalCardHeaderProps {
    professionalProfile: ProfessionalProfile | InternalProfessional
    modality: ShiftModalityEnum | null;
}
export const ProfessionalCardHeader: React.FC<ProfessionalCardHeaderProps> = ({
    professionalProfile,
    modality
}) => {
    const accountInfo = useSelector((state: RootState) => state.account.accountInfo);

    return (

        <div
            className="flex flex-col justify-center items-center w-full space-y-small"
        >
            <ProfilePicture profilePictureUrl={professionalProfile?.profilePictureUrl} modality={modality} size={64} />
            <div
                className="flex flex-col items-center w-full"
            >
                <p className="heading-md text-Text-Default">
                    {professionalProfile?.firstName} {professionalProfile.lastName
                    }</p>
                {professionalProfile.category ?
                    <div
                        className="flex items-center space-x-small"
                    >
                        <CategoryTag text={professionalProfile.category.acronym} />
                        <p
                            className="body-regular text-Text-Subtle"
                        >
                            {professionalProfile.category.displayText}
                        </p>
                    </div>
                    : null}
            </div>

        </div>
    )
}