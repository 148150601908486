interface CalendarDayItemProps {
    day: string;
    isToday: boolean;
    onPress: () => void;
    isOtherMonth: boolean;
    isSelected: boolean;
    hasAlert?: boolean;
    hasShifts: boolean;
    disabled?: boolean;
    holiday?: boolean;
}

export const CalendarDayItem: React.FC<CalendarDayItemProps> = ({
    day,
    isToday,
    onPress,
    isOtherMonth,
    isSelected,
    hasAlert,
    hasShifts,
    disabled,
    holiday
}) => {
    const todayClass = 'border-[1px] border-solid border-Divider-Default'
    const selectedClass = holiday ? 'bg-[#B1A4E0] text-white' : 'bg-Primary-500 text-white'
    const otherMonthClass = 'text-gray-400'
    const holidayClass = 'ring-solid ring-[2px] ring-[#B1A4E0]'
    return (
        <button
            className={`flex
                items-center
                justify-center
                rounded-[4px]
                p-tiny
                relative
                box-border
                h-[24px]
                w-[24px]
                ${!isSelected ? 'hover:bg-Background-Secondary' : ''}
                ${isSelected ? selectedClass : holiday ? holidayClass : isToday ? todayClass : isOtherMonth ? otherMonthClass : ''}
            `}
            disabled={disabled}
            onClick={onPress}
        >
            <div
            >
                <p
                    className={`
                    
                    body-sm
                    w-[16px]
                    text-center
                    ${hasShifts && !isSelected ? 'subtitle-regular text-Action-Primary' : ''}
                    ${disabled ? 'line-through text-Text-Light' : ''}
                `}
                >
                    {day}
                </p>
                {hasAlert && !isSelected ?
                    <div
                        className="absolute top-[4px] right-[2px] bg-Negative-500 rounded-full w-[4px] h-[4px]"
                    />
                    : null}
            </div>

        </button>
    )
}