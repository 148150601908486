import { CircularProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { FacilityStaff } from "../../services/facility-staff";
import { FacilityStaffCard } from "./FacilityStaffCard";
import { EmptyShiftsState } from "../../components/internalProfessionals/EmptyState";
import { SearchBar } from "./SearchBar";
import { ADD_USER_BUTTON, EMAIL_LABEL, FACILITY_STAFF_PAGE_TITLE, PERMISSIONS_LABEL, SEARCH_PLACEHOLDER, UNITS_LABEL, USER_NAME_LABEL } from "locales/facilitystaff";
import LivoIcon from "assets/LivoLogo";
import { useEffect, useRef, useState } from "react";


interface FacilityStaffListComponentProps {
    facilityStaff: FacilityStaff[];
    loading: boolean;
    loadNextPage: () => void
    hasMoreData: boolean,
    searchQuery: string,
    setSearchQuery: (text: string) => void
    onEdit: (facilityStaff: FacilityStaff) => void
    onCreate: () => void
    facilityHasUnitsConfigured?: boolean
}

export const FacilityStaffListComponent: React.FC<FacilityStaffListComponentProps> = ({
    facilityStaff,
    loading,
    loadNextPage,
    hasMoreData,
    searchQuery,
    setSearchQuery,
    onEdit,
    onCreate,
    facilityHasUnitsConfigured
}) => {

    const scrollableDivRef = useRef<HTMLDivElement | null>(null);

    const scrollToTop = () => {
      if (scrollableDivRef.current) {
        console.log('scrolling to top')
        scrollableDivRef.current.scrollTo({
          top: 0,
        });
      }
    };
    
    useEffect(() => {
        scrollToTop();
        }
    , [searchQuery])

    return (
        <div
            className="flex flex-1 pt-[24px] pb-[24px] no-scrollbar justify-center overflow-y-hidden"

        >
            <div
                className="flex flex-col flex-1 w-full px-medium space-y-medium max-w-[1500px]"

            >

                <div
                    className="flex space-x-small items-center w-full justify-between"
                >
                    <p
                        className="heading-md text-ellipsis text-nowrap"
                    >
                        {FACILITY_STAFF_PAGE_TITLE}
                    </p>
                    <button
                        onClick={() => onCreate()}
                        className="flex items-center space-x-tiny cursor-pointer py-small pl-large pr-medium rounded-full ring-[1px] ring-Divider-Default"
                    >
                        <p
                            className="body-regular text-Primary-500"
                        >
                            {ADD_USER_BUTTON}
                        </p>
                        <LivoIcon name='user-plus' size={24} color='#149EF2' />
                    </button>
                </div>
                <SearchBar
                    searchText={searchQuery}
                    setSearchText={setSearchQuery}
                    searchPlaceHolder={SEARCH_PLACEHOLDER}
                />
                <div
                    className="flex flex-col h-fit w-full"
                >
                    <div
                        className="flex border-b-[1px] border-Divider-Default text-left mb-large px-small items-center py-small text-Text-Subtle space-x-small mr-[20px] "
                    >
                        <div
                            className="flex-1 flex table-header "
                        >
                            {USER_NAME_LABEL}
                        </div>
                        <div
                            className="flex-1 flex table-header "
                        >
                            {EMAIL_LABEL}
                        </div>
                        {facilityHasUnitsConfigured ? <div
                            className="flex-1 flex table-header "
                        >
                            {UNITS_LABEL}
                        </div> : null}
                        <div
                            className="flex-1 flex table-header "
                        >
                            {PERMISSIONS_LABEL}
                        </div>
                    </div>
                    {loading ? (
                        <div
                            className="flex flex-1 justify-center items-center h-fit"
                        >
                            <CircularProgress />
                        </div>
                    ) :
                        facilityStaff.length === 0 ?
                            <EmptyShiftsState
                                title={'No hay usuarios registrados'}
                            /> :

                            <div
                                id="scrollableDiv"
                                className="space-y-small w-full pb-100 no-scrollbar"
                                ref={scrollableDivRef}
                                style={{
                                    overflow: 'auto',
                                    display: 'flex',
                                    height: 'calc(100vh - 300px)',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={facilityStaff.length}
                                    next={() => loadNextPage()}
                                    hasMore={hasMoreData}
                                    loader={<div />}
                                    scrollableTarget="scrollableDiv"
                                    className="space-y-small"
                                    scrollThreshold={0.7}
                                    style={{
                                        overflow: 'visible',
                                    }}
                                    
                                  
                                >
                                    {facilityStaff.map((item, index) => (
                                        <FacilityStaffCard
                                            key={index}
                                            faciltiyStaff={item}
                                            onClick={() => onEdit(item)}
                                            facilityHasUnitsConfigured={facilityHasUnitsConfigured}
                                        />
                                    ))}
                                </InfiniteScroll>
                            </div>

                    }
                    <div
                        className='h-large' />
                </div>
            </div>

        </div>
    )
}