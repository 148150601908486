import React from "react";
import { ModalContainer } from "./ModalContainer";
import { ActionButton } from "./ActionButton";

interface ConfirmationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  buttonTitle: string;
  dismissTitle: string;
  buttonColor?: string;
  onPress: () => void;
  onDismiss: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  children,
  buttonTitle,
  dismissTitle,
  buttonColor = '#FF5A59',
  onPress,
  onDismiss,
}) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={handleClose}
      style={{ width: '60%', maxWidth: '700px' }}
    >
      <div
        className="flex flex-col overflow-y-auto bg-white rounded-[16px] p-large mx-auto z-50  w-[448px]"
      >
        {title && (
          <h2 className="text-xl font-medium mb-3">
            {title}
          </h2>
        )}
        {subtitle && (
          <p className="text-base mb-3">
            {subtitle}
          </p>
        )}

        {children}

        <div className="mt-large flex flex-row justify-between">
          <div className="w-[200px]">
            <ActionButton
              onClick={onDismiss}
              isLoading={false}
              style={{color: "#149EF2", backgroundColor: "#FFFFFF"}}
            >
              {dismissTitle}
            </ActionButton>
          </div>
          <div className="w-[200px]">
            <ActionButton
              onClick={onPress}
              isLoading={false}
              style={{backgroundColor: buttonColor}}
            >
              {buttonTitle}
            </ActionButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
