import LivoIcon from "assets/LivoLogo";
import { useEffect, useState } from "react";

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    setValue: (time: string) => void;
    selectedValue: string;
    iconName?: string;
    placeHolder?: string;
    errorMessage?: string;
    endingLabel?: string;
    inputType?: string;
    inputStyle?: any;
    hasChanged?: boolean;
    callToActionIcon?: string;
    label?: string;
}


export const CustomInput: React.FC<CustomInputProps> = ({
    setValue,
    selectedValue,
    iconName,
    placeHolder,
    errorMessage,
    endingLabel,
    inputType,
    inputStyle,
    callToActionIcon,
    hasChanged,
    label,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);


    const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    return (
        <div
            className="flex flex-col relative"
        >
           {label && selectedValue && selectedValue !== "" ? <div
                className="absolute -top-small left-small bg-white px-tiny"
            >
                <p
                    className="info-overline text-Text-Subtle"
                >
                    {label}
                </p>
            </div> : null}
            <div
                className={`flex flex-row box-border ring-[1px] ring-solid rounded-[8px] px-small py-medium w-full space-x-small justify-start items-center bg-white flex-shrink-1 
                        ${errorMessage ? 'ring-[2px] ring-red-500' : 'ring-Divider-Subtle focus-within:ring-Action-Secondary focus-within:ring-[2px]'} `}

            >
                {hasChanged && !isFocused ? <div
                    className="w-small h-small bg-Action-Primary rounded-full"
                /> : null}
                {iconName && <div className="items-center justify-center">
                    <LivoIcon name={iconName} size={24} color={isFocused ? '#4F989E' : '#ACBBCB'} />
                </div>}
                <input
                    type={inputType ? inputType : "text"}
                    value={selectedValue}
                    onChange={handleInputValue}
                    onBlur={handleInputBlur}
                    className="body-regular placeholder-Text-Subtle focus:outline-none placeholder:body-regular placeholder:text-Text-Subtle w-full"
                    placeholder={placeHolder}
                    onFocus={handleInputFocus}
                    style={inputStyle}
                    {...props}
                />
                {
                    endingLabel ? (
                        <p className="body-regular text-Text-Subtle ml-small">
                            {endingLabel}
                        </p>
                    ) : null

                }
                {callToActionIcon && <div className="items-center justify-center">
                    <LivoIcon name={callToActionIcon} size={24} color={'#149EF2'} />
                </div>}
            </div>
            {
                errorMessage ? (
                    <p className="info-caption mt-tiny text-Negative-500">
                        {errorMessage}
                    </p>
                ) : null
            }
        </div>

    );
};
