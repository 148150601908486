interface TagComponentProps {
    label: string;
    isSelected?: boolean;
    onClick?: () => void;
    children?: any;
    className?: string;
    labelClassName?: string;
    style?: any;
}

export const TagComponent: React.FC<TagComponentProps> = ({
    label,
    isSelected,
    onClick,
    children,
    className,
    labelClassName,
    style,
}) => {
    const checkStyle = isSelected ? 'bg-[#375D68] ring-[#375D68] text-white' : 'bg-white ring-[#ACBBCB] text-[#417683]'

    return (
        <button
            onClick={onClick}
            disabled={!onClick}
            className={className ?? `flex items-center justify-center text-center py-small px-medium rounded-[100px] ring-[1px] ring-solid ${checkStyle}`}
            style={style}
        >
            <p
                className={labelClassName ?? 'action-sm'}
            >
                {label}
            </p>
            {children}
        </button>
    )
}