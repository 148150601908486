import LivoIcon from "assets/LivoLogo";
import { ShiftModalityEnum } from "types";
import { modalityTags } from "utils/constants";

interface VisibilityItemProps {
    isSelected: boolean;
    modality: ShiftModalityEnum;
    onPress: () => void;
}

export const VisibilityItem: React.FC<VisibilityItemProps> = ({
    isSelected,
    modality,
    onPress
}) => {
    const modalityProps = modalityTags[modality];

    return (
        <button
            onClick={onPress}
            className="flex py-medium px-small flex-row space-x-small justify-between items-center w-full border-[1px] border-Divider-Default rounded-[8px]">
            <div
                className="flex flex-row justify-start items-center space-x-tiny w-full"
            >

                <LivoIcon name={modalityProps.icon} size={16} color={modalityProps.color} />
                <p
                    className="body-regular"
                >
                    {modalityProps.displayText}
                </p>
            </div>

            <div className="flex items-center justify-center p-[3px]">
                <LivoIcon name={isSelected ? 'checkbox-checked' : 'checkbox-unchecked'} size={24} color={isSelected ? modalityProps.color : '#ACBBCB'} />
            </div>
        </button>
    );
}