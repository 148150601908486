import { FacilityPermission } from "services/facility-staff";
import { FacilityStaffPermissionCard } from "./FacilityStaffPermissionCard";
import LivoIcon from "assets/LivoLogo";
import { TogglerOn } from "assets/TogglerOn";
import { TogglerOff } from "assets/TogglerOff";
import { ADMIN_PERMISSIONS_TEXT, PERMISSIONS_LABEL } from "locales/facilitystaff";

interface FacilityStaffPermissionsComponentProps {
    permissions: string[];
    togglePermission: (permission: string) => void;
    permissionList: FacilityPermission[];
    toggleAdmin: () => void;
    isAdmin: boolean;
}

export const FacilityStaffPermissionsComponent: React.FC<FacilityStaffPermissionsComponentProps> = ({
    permissions,
    togglePermission,
    permissionList,
    toggleAdmin,
    isAdmin
}) => {
    return (
        <div>
            <p
                className="subtitle-regular"
            >
                {PERMISSIONS_LABEL}
            </p>
            <div
                className="flex items-center space-x-small py-small justify-between"
            >
                <p
                    className="body-regular"
                >
                   {ADMIN_PERMISSIONS_TEXT}
                </p>

                <div
                    onClick={toggleAdmin}
                    className="cursor-pointer"
                >
                    {
                        isAdmin ? <TogglerOn /> : <TogglerOff />
                    }
                </div>

            </div>
            <div
                className="w-full py-medium space-y-small"
            >
                {
                    permissionList.map((permission, index) => (
                        <FacilityStaffPermissionCard
                            key={index}
                            isEnabled={permissions.includes(permission.value)}
                            permission={permission}
                            togglePermission={() => togglePermission(permission.value)}
                        />))
                }
            </div>
        </div>
    )
}