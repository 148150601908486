import { CircularProgress } from "@mui/material";
import LivoIcon from "assets/LivoLogo";

interface ActionButtonProps {
    onClick: () => void;
    isDisabled?: boolean;
    isLoading: boolean;
    children: React.ReactNode;
    color?: string;
    inverse?: boolean;
    style?: any;
    tailwindStyle?: string;
}
export const ActionButton: React.FC<ActionButtonProps> = ({
    onClick,
    isDisabled,
    isLoading,
    children,
    color,
    inverse,
    style,
    tailwindStyle
}) => {

    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            className={`
                flex shrink-0 grow-0 items-center justify-center py-small px-xLarge
                w-full rounded-[100px]
                text-white font-bold text-[16px]
                ${isDisabled ? 'cursor-not-allowed ring-[#EEEFF3] ring-[1px]' : 'cursor-pointer'}
                ${inverse ? 'ring-Divider-Default ring-[1px]' : ''}
                ${inverse ? 'bg-transparent' : ' bg-Primary-500'}
                ${tailwindStyle || ''}
            `}
            style={{
                backgroundColor: isDisabled ? '#ACBBCB' : inverse ? '#FFFFF' : color,
                ...style
            }}
        >
            {isLoading ? (
                <CircularProgress size={24} color="inherit" />
            ) : (
                children
            )}
        </button>
    )
}