import './App.css';
import '@fontsource/roboto/300.css'; // Light
import '@fontsource/roboto/400.css'; // Regular
import '@fontsource/roboto/500.css'; // Medium
import '@fontsource/roboto/700.css'; // Bold
import '@fontsource/roboto/400-italic.css'; // Italic

import LogoImage from "./assets/livoLogoText.svg"; // Replace with the actual path to your logo image
import SignIn from 'pages/SignIn';
import useToken from 'services/authentication';
import { useLayoutEffect, useState } from 'react';
import { configureUnauthorizedApi } from 'services/api';
import { RouterComponent } from 'pages/Router';
import { AppToast } from 'components/common/toasts/AppToast';
import { setAccountInfo } from 'store/actions/accountActions';
import { useDispatch, useSelector } from 'react-redux';
import posthog from 'posthog-js';
import LivoIcon from 'assets/LivoLogo';
import { RootState } from 'store/types';

function App() {
  const dispatch = useDispatch();
  const [accountModalOpen, setAccountModalOpen] = useState(false);

  const { token, setToken, logOut } = useToken();
  const { accountInfo } = useSelector((state: RootState) => state.account)

  const signOut = () => {
    logOut()
    dispatch(setAccountInfo(null))
    posthog.reset()
  }
  useLayoutEffect(() => {
    return configureUnauthorizedApi(() => {
      signOut();
    });
  }, []);

  return (<div className='h-screen flex-col overflow-y-hidden overscroll-contain'>
    {/* App Bar */}
    <div className='bg-Secondary-700 flex items-center px-large'>
      <div
        className='flex py-medium mr-large'
      >
        <img src={LogoImage} alt="Logo" className='h-[42px] mr-large' />
        {accountInfo ? <div
          onClick={() => setAccountModalOpen(true)}
          className='flex self-start space-x-tiny p-small rounded-full ring-Divider-Default ring-[1px] cursor-pointer items-center ring-solid'
        >
          <LivoIcon name='user-circle' size={24} color='#FFFF' />
          <p
            className='subtitle-regular text-Text-Inverse'
          >
            {accountInfo.firstName} {accountInfo.lastName}
          </p>
          <LivoIcon name='chevron-right' size={24} color='#FFFF' />
        </div> : null}
      </div>


      <div
        className='flex flex-1 justify-end py-small'
      >
        <div
          className='w-1/3 max-w-1/3'
        >
          <AppToast />
        </div>
      </div>

    </div>
    {
      !token ?
        <SignIn setToken={setToken} /> :
        <RouterComponent
          accountModalOpen={accountModalOpen}
          setAccountModalOpen={setAccountModalOpen}
          logOut={signOut} />
    }
  </div>
  );
}

export default App;
