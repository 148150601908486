
interface EmptyShiftsStateProps {
    title?: string,
    subtitle?: string,
}
export const EmptyShiftsState: React.FC<EmptyShiftsStateProps> = ({
    title,
    subtitle
}) => {
    return (
        <div
            className="p-medium flex flex-col text-center mt-medium"
        >
           {title && <p
                className="heading-md text-Text-Default mb-small"
            >
                {title}
            </p>}
           {subtitle && <p
                className="body-regular text-Text-Default"
            >
                {subtitle}
            </p>}
        </div>
    )
}