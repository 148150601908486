import LivoIcon from "assets/LivoLogo";

interface PublishInputRowProps {
    iconName: string;
    children: React.ReactNode;
}

export const PublishInputRow: React.FC<PublishInputRowProps> = ({
    iconName,
    children
}) => {
    return (
        <div className="flex flex-col w-full py-medium">
            <div className="flex flex-row space-x-medium">
                <div
                    className="flex items-center justify-center"
                >
                    <LivoIcon name={iconName} size={24} color="#ACBBCB" />

                </div>
                {children}
            </div>
        </div>
    )
}