import { ShiftModalityEnum } from "types";
import { ProfessionalCardHeader } from "../../components/shiftDetails/professionalClaim/ProfessionalCardHeader";
import { DataFieldSubmission, FacilityDataFieldDefinition, InternalProfessional } from "../../types/internal";
import { CANCEL_LABEL, SAVE_CHANGES_LABEL } from "locales/internalProfessionalPage";
import { InternalProfessionalAttributes } from "components/shiftDetails/professionalClaim/InternalProfileAttributes";
import { EditInternalProfessionalAttributes } from "./EditInternalProfessionalAttributes";
import { Category } from "types/shifts";


interface InternalProfessionalDetailsProps {
    professional: InternalProfessional;
    isEditting: boolean;
    onEdit: (newProfessionalDetails: DataFieldSubmission[], newCategory: Category) => void
    onCancelEdit: () => void
    onAcceptProfessional: (dataFieldSubmission: DataFieldSubmission[], newCategory: Category) => void
    onRejectProfessional: () => void,
    dataFieldDefinitions: FacilityDataFieldDefinition[]
}
export const InternalProfessionalDetails: React.FC<InternalProfessionalDetailsProps> = ({
    professional,
    isEditting,
    onEdit,
    onCancelEdit,
    onAcceptProfessional,
    onRejectProfessional,
    dataFieldDefinitions
}) => {

    return (
        <div
            className="flex flex-col w-full h-full justify-between pb-large"
        >
            <div
                className="flex flex-col w-full space-y-large divide-y-[1px] divide-Divider-Default h-full"
            >

                {professional.invitationStatus === 'PENDING' ?

                    <EditInternalProfessionalAttributes
                        internalProfessionalAttributes={professional.dataFields}

                        hideIcons={false}
                        onAccept={(newProfessionalDetails, newCategory) => {
                            onAcceptProfessional(newProfessionalDetails, newCategory)
                        }}
                        onReject={onRejectProfessional}
                        dataFieldDefinitions={dataFieldDefinitions}
                        professional={professional}
                    /> :
                    isEditting ?
                        <EditInternalProfessionalAttributes
                            professional={professional}
                            internalProfessionalAttributes={professional.dataFields}
                            acceptTitle={SAVE_CHANGES_LABEL}
                            rejectTitle={CANCEL_LABEL}
                            hideIcons={true}
                            onAccept={(newProfessionalDetails, newCategory) => {
                                onEdit(newProfessionalDetails, newCategory)
                            }}
                            onReject={onCancelEdit}
                            dataFieldDefinitions={dataFieldDefinitions}
                        /> :
                        <div
                            className="flex flex-col w-full h-full"
                        >
                            <ProfessionalCardHeader
                                professionalProfile={professional}
                                modality={ShiftModalityEnum.INTERNAL}
                            />

                            <InternalProfessionalAttributes
                                attributes={
                                    [

                                        ...professional.dataFields.map(datafield => ({
                                            label: datafield.label,
                                            value: datafield.displayText
                                        })
                                        )
                                    ]

                                }
                            />
                        </div>
                }

            </div>
        </div >
    )
}