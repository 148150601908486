import moment from "moment";

export const EDIT_SHIFT_TITLE = 'Editar turno';
export const SELECT_CATEGORY_TITLE = '¿Qué tipo de turno quieres crear?';
export const EDIT_SINGLE_SHIFT_SUMMARY_HEADER  = (date: string) => `Se editará el turno del ${moment(date).format('DD/MM/YYYY')}`
export const EDIT_MULTIPLE_SHIFTS_SUMMARY_HEADER = (numberOfShifts:number, startDate: string, endDate: string) => `Se editarán ${numberOfShifts} turnos entre el ${startDate} y el ${endDate}`
export const EDIT_SHIFT_BUTTON = 'Editar turno';
export const EDIT_MULTIPLE_SHIFTS_BUTTON = (numberOfShifts: number) => `Editar ${numberOfShifts} turnos`;
export const EDIT_SHIFT_DATE_SELECTOR_SUBTITLE = 'Los cambios no serán aplicados a los turnos cubiertos.';
export const EDIT_RECURRENT_SHIFT_BUTTON = 'Editar turno';
export const EDIT_RECURRENT_SHIFT_GO_BACK = 'Cancelar'
export const RECURRENCY_OPTION_THIS_SHIFT = 'Este turno';
export const RECURRENCY_OPTION_ALL_SHIFTS = 'Todos los turnos';
export const RECURRENCY_OPTIONS_ALL_SHIFTS_DISCLAIMER = '(Excepto los turnos cubiertos)';
export const ERROR_EDITING_HEADER = 'Error al actualizar turno';