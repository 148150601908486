import LivoIcon from "assets/LivoLogo";

interface ShiftCardActionsProps {
    actionComponents: {
        iconName: string;
        onClick: () => void;
    }[]
}

export const ShiftCardActions: React.FC<ShiftCardActionsProps> = ({
    actionComponents
}) => {
    return (
        <div
            className="flex space-x-small py-tiny px-small rounded-[100px] bg-white shadow-custom"
        >
            {
                actionComponents.map((actionComponent, index) => (
                    <button
                        key={index}
                        onClick={actionComponent.onClick}
                        className="flex items-center justify-center hover:bg-Background-Secondary"
                    >
                        <LivoIcon
                            name={actionComponent.iconName}
                            size={16}
                            color="#707A91"
                        />
                    </button>
                ))
            }
        </div>
    )
}