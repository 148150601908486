import moment from "moment"
import { useEffect, useState } from "react"
import { ShiftModalityEnum } from "types"
import { Category, ShiftTimeInDayEnum, SpecializationDTO } from "types/shifts"
import { PublishShiftConfigurationDTO } from "types/publish-shift"
import { ApiApplicationError } from "services/api"
import { DateTimeSelector } from "components/publishShift/DateTimeSelector"
import { VisibilitySelector } from "components/publishShift/VisibilitySelector"
import { PriceSelector } from "components/publishShift/PriceSelector"
import { CapacitySelector } from "components/publishShift/CapacitySelector"
import { PublishShiftMultipleLineInput } from "components/publishShift/PublishShiftMultipleLineInput"
import { EDIT_MULTIPLE_SHIFTS_BUTTON, EDIT_MULTIPLE_SHIFTS_SUMMARY_HEADER, EDIT_SHIFT_BUTTON, EDIT_SHIFT_DATE_SELECTOR_SUBTITLE, EDIT_SINGLE_SHIFT_SUMMARY_HEADER, ERROR_EDITING_HEADER } from "locales/editShift"
import { PublishInputRow } from "components/publishShift/PublishInputRow"
import { DropDownWithInput } from "components/publishShift/DropDownWithInput"
import LivoIcon from "assets/LivoLogo"
import { ShiftPublicationRequest } from "services/publish-shift"
import { HeaderComponent } from "./HeaderComponent"
import { PUBLISH_ERROR_HEADER, PUBLISH_MULTIPLE_SHIFTS_BUTTON, PUBLISH_MULTIPLE_SHIFTS_SUMMARY_HEADER, PUBLISH_SHIFT_BUTTON, PUBLISH_SHIFT_DATE_SELECTOR_SUBTITLE, PUBLISH_SINGLE_SHIFT_SUMMARY_HEADER } from "locales/publishShift"
import { ActionButton } from "components/common/ActionButton"
import { UnitInputComponent } from "./UnitInputComponent"


export type ShiftConfiguration = {
    startTime: string;
    finishTime: string;
    shiftTimeInDay: ShiftTimeInDayEnum;
    totalPay: string;
    capacity: number;
    specialization: SpecializationDTO | null;
    unit: string;
    details: string;
    externalVisible: boolean;
    internalVisible: boolean;
    recurrentDates: string[];
    category?: Category | null;
    unitVisible: boolean;
}

interface BaseShiftFormProps {
    publishShiftConfiguration: PublishShiftConfigurationDTO;
    onSubmit: (shiftPublishRequest: ShiftPublicationRequest) => Promise<void>;
    initialConfig: ShiftConfiguration;
    onClose: () => void;
    isEditing?: boolean;
    title: string;
    goBack?: () => void; 
}



export const BaseShiftForm = ({
    publishShiftConfiguration: {
        specializations: specializationOptions,
        shiftTimeConfig: shiftTimeInDayConfig,
        livoPoolOnboarded,
        livoInternalOnboarded,
        units,
        calendarSummary,
        unitVisibleConfigurable
    },
    onSubmit,
    initialConfig,
    onClose,
    isEditing,
    title,
    goBack,

}: BaseShiftFormProps) => {
    const initialStartTime = initialConfig.startTime || moment().toISOString()
    const [selectedStartTime, setSelectedStartTime] = useState<string>(initialStartTime)
    const [selectedEndTime, setSelectedEndTime] = useState<string>(initialConfig.finishTime || moment().toISOString())
    const [selectedSpecialization, setSelectedSpecialization] = useState<string>(initialConfig.specialization?.name || '')
    const [unitVisible, setUnitVisible] = useState<boolean>(initialConfig.unitVisible)
    const [priceModality, setPriceModality] = useState<string>('total')
    const [price, setPrice] = useState<string>(initialConfig.totalPay)
    const [capacity, setCapacity] = useState<number>(initialConfig.capacity);
    const [visibility, setVisibility] = useState<ShiftModalityEnum[]>(() => {
        const initialVisibility: ShiftModalityEnum[] = []
        if (initialConfig.externalVisible) {
            initialVisibility.push(ShiftModalityEnum.EXTERNAL)
        }
        if (initialConfig.internalVisible) {
            initialVisibility.push(ShiftModalityEnum.INTERNAL)
        }
        return initialVisibility
    })
    const [details, setDetails] = useState<string>(initialConfig.details || '')
    const [unit, setUnit] = useState<string>(initialConfig.unit || '')

    const [invalidPrice, setInvalidPrice] = useState('')
    const [invalidSpecialization, setInvalidSpecialization] = useState('')
    const [invalidUnit, setInvalidUnit] = useState('')
    const [invalidVisibility, setInvalidVisibility] = useState('')
    const [loading, setLoading] = useState(false)
    const [recurrentDates, setRecurrentDates] =
        useState<string[]>(initialConfig?.recurrentDates?.filter((date) => moment(date).isAfter(moment()))
            || [moment(initialStartTime).format('YYYY-MM-DD')])

    const [publishErrorMessage, setPublishErrorMessage] = useState<string>('')

    const sortedRecurrentDates = recurrentDates.sort((a, b) => moment(a).diff(moment(b)))
    const summaryHeader = sortedRecurrentDates.length === 1 ? PUBLISH_SINGLE_SHIFT_SUMMARY_HEADER(selectedStartTime) : PUBLISH_MULTIPLE_SHIFTS_SUMMARY_HEADER(sortedRecurrentDates.length, moment(sortedRecurrentDates[0]).format('DD/MM/YYYY'), moment(recurrentDates[sortedRecurrentDates.length - 1]).format('DD/MM/YYYY'))
    const summaryHeaderEdit = sortedRecurrentDates.length === 1 ? EDIT_SINGLE_SHIFT_SUMMARY_HEADER(selectedStartTime) : EDIT_MULTIPLE_SHIFTS_SUMMARY_HEADER(sortedRecurrentDates.length, moment(sortedRecurrentDates[0]).format('DD/MM/YYYY'), moment(recurrentDates[sortedRecurrentDates.length - 1]).format('DD/MM/YYYY'))
    const buttonTitlePublish = sortedRecurrentDates.length === 1 ? PUBLISH_SHIFT_BUTTON : PUBLISH_MULTIPLE_SHIFTS_BUTTON(sortedRecurrentDates.length)
    const buttonTitleEdit = sortedRecurrentDates.length === 1 ? EDIT_SHIFT_BUTTON : EDIT_MULTIPLE_SHIFTS_BUTTON(sortedRecurrentDates.length)

    const poolAndInternalOnboarded = livoPoolOnboarded && livoInternalOnboarded;

    const validateFields = () => {
        let isValid = true
        if (specializationOptions.length > 0 && !selectedSpecialization) {
            setInvalidSpecialization('Selecciona una especialización')
            isValid = false
        } else {
            setInvalidSpecialization('')
        }
        if (!price && visibility.includes(ShiftModalityEnum.EXTERNAL)) {
            setInvalidPrice('Ingresa un precio válido')
            isValid = false
        } else {
            setInvalidPrice('')
        }
        if (!unit && visibility.includes(ShiftModalityEnum.INTERNAL)) {
            setInvalidUnit('Ingresa una unidad válida')
            isValid = false
        } else {
            setInvalidUnit('')
        }
        if (visibility.length === 0) {
            setInvalidVisibility('Selecciona al menos una audiencia')
            isValid = false
        } else {
            setInvalidVisibility('')
        }
        return isValid
    }

    useEffect(() => {
        setSelectedStartTime(initialConfig.startTime || moment().toISOString());
        setSelectedEndTime(initialConfig.finishTime || moment().toISOString());
        setSelectedSpecialization(initialConfig.specialization?.name || '');
        setPrice(initialConfig.totalPay);
        setCapacity(initialConfig.capacity);
        setVisibility(() => {
            const initialVisibility: ShiftModalityEnum[] = []
            if (isEditing) {
                if (initialConfig.externalVisible) {
                    initialVisibility.push(ShiftModalityEnum.EXTERNAL)
                }
                if (initialConfig.internalVisible) {
                    initialVisibility.push(ShiftModalityEnum.INTERNAL)
                }
            } else if (initialConfig.category != null) {
                if (initialConfig.category.visibleForLivoInternal) {
                    initialVisibility.push(ShiftModalityEnum.INTERNAL)
                }
                if (initialConfig.category.visibleForLivoPool) {
                    initialVisibility.push(ShiftModalityEnum.EXTERNAL)
                }
            } else {
                initialVisibility.push(ShiftModalityEnum.EXTERNAL)
            }

            return initialVisibility
        });
        setDetails(initialConfig.details || '');
        setUnit(initialConfig.unit || '');
        setRecurrentDates(initialConfig?.recurrentDates?.filter((date) => !moment(date).isBefore(moment(), 'day')) || [moment().format('YYYY-MM-DD')]);
        setPublishErrorMessage('');


        return () => {
            // Reset state on unmount
            setSelectedStartTime(moment().toISOString());
            setSelectedEndTime(moment().toISOString());
            setSelectedSpecialization(specializationOptions[0]?.name || '');
            setPrice('');
            setCapacity(1);
            setVisibility([ShiftModalityEnum.EXTERNAL]);
            setDetails('');
            setUnit('');
            setInvalidPrice('');
            setInvalidSpecialization('');
            setInvalidUnit('');
            setInvalidVisibility('');
            setDetails('');
            setRecurrentDates([moment().format('YYYY-MM-DD')]);
            setPublishErrorMessage('');
        };
    }, [initialConfig])

    return (
        <div
            className="flex flex-col w-full h-full"
        >
            <HeaderComponent
                title={title + (initialConfig.category ? ` - ${initialConfig.category.displayText}` : '')}
                onClose={onClose}
                goBack={goBack}
            />
            <div className="flex flex-row overflow-y-auto p-medium space-x-large" style={{ flexGrow: 1, maxHeight: "calc(80vh - 150px)" }}>
                <DateTimeSelector
                    calendarSummary={calendarSummary || []}
                    selectedStartTime={selectedStartTime}
                    selectedEndTime={selectedEndTime}
                    setStartTime={setSelectedStartTime}
                    setEndTime={setSelectedEndTime}
                    shiftTimeInDayConfig={shiftTimeInDayConfig}
                    recurrentDates={recurrentDates}
                    setRecurrentDates={setRecurrentDates}
                    isDisabled={isEditing && initialConfig.recurrentDates?.length > 1}
                    subtitle={!isEditing ?
                        PUBLISH_SHIFT_DATE_SELECTOR_SUBTITLE
                        : initialConfig.recurrentDates?.length > 1 ?
                            EDIT_SHIFT_DATE_SELECTOR_SUBTITLE : undefined
                    }
                    bulkSelectionEnabled={
                        !isEditing || initialConfig.recurrentDates?.length > 1
                            ? true : false // when editing a single shift user cannot select multiple dates
                    }
                />
                <div
                    className="flex flex-col w-full"
                >
                    {poolAndInternalOnboarded &&
                        <PublishInputRow
                            iconName="eye">
                            <VisibilitySelector
                                selectedVisibility={visibility}
                                setVisibility={setVisibility}
                                errorMessage={invalidVisibility}
                            />
                        </PublishInputRow>
}
                    {livoInternalOnboarded &&
                        <UnitInputComponent
                            units={units}
                            unit={unit}
                            setUnit={(newUnit: string) => {
                                setUnit(newUnit)
                                setInvalidUnit('')
                            }}
                            invalidUnit={invalidUnit}
                            setInvalidUnit={setInvalidUnit}
                            unitVisible={unitVisible}
                            setUnitVisible={setUnitVisible}
                            unitVisibleConfigurable={unitVisibleConfigurable}                                      
                        />
                    }
                    {specializationOptions.length > 0 ? <PublishInputRow
                        iconName="stethoscope">
                        <DropDownWithInput
                            label="Especialización"
                            selectedOptionId={selectedSpecialization}
                            setOptionId={(optionId) => {
                                setSelectedSpecialization(optionId)
                                setInvalidSpecialization('')
                            }}
                            placeHolder="Especialización"
                            options={specializationOptions.map((specialization) => ({
                                id: specialization.name,
                                name: specialization.translations.es
                            }))
                            }
                            errorMessage={invalidSpecialization}
                            disabled={isEditing}
                        />
                    </PublishInputRow> : null}

                    <PublishInputRow
                        iconName="cash-banknote"
                    >
                        <PriceSelector
                            setPrice={(newPrice) => {
                                setPrice(newPrice)
                                setInvalidPrice('')
                            }}
                            selectedPrice={price}
                            selectedModality={priceModality}
                            setSelectedModality={setPriceModality}
                            errorMessage={invalidPrice}
                            isDisabled={!visibility.includes(ShiftModalityEnum.EXTERNAL)}
                        />
                    </PublishInputRow>

                    <PublishInputRow
                        iconName="user"
                    >
                        <CapacitySelector
                            selectedCapacity={capacity}
                            maxCapacity={5}
                            setCapacity={setCapacity}
                        />
                    </PublishInputRow>
                    <div
                        className="flex items-start justify-center space-x-medium py-medium"
                    >
                        <div
                            className="flex items-center justify-center pt-small"
                        >
                            <LivoIcon name={'blockquote'} size={24} color="#ACBBCB" />
                        </div>
                        <PublishShiftMultipleLineInput // Unidad
                            selectedValue={details}
                            setValue={setDetails}
                            placeHolder="Detalles del turno..."
                            label="Detalles"

                        />
                    </div>
                </div>
            </div>
            <div
                className="flex flex-row justify-between items-center p-large border-t border-Divider-Default"
            >

                {publishErrorMessage ?
                    <div>
                        <p
                            className="body-regular text-Negative-500"
                        >
                            {isEditing ? ERROR_EDITING_HEADER : PUBLISH_ERROR_HEADER}
                        </p>
                        <p
                            className="body-regular text-Negative-500"
                        >
                            {publishErrorMessage}
                        </p>
                    </div>

                    : <div>
                        <p
                            className="body-regular text-Text-Subtle"
                        >
                            {isEditing ? summaryHeaderEdit : summaryHeader}
                        </p>
                    </div>}
                <div
                    className="flex  bg-Primary-Default text-white"
                >

                    <ActionButton
                        isDisabled={false}
                        isLoading={loading}
                        style={{

                        }}
                        onClick={() => {
                            const startTime = moment(selectedStartTime);
                            const endTime = moment(selectedEndTime);
                            if (endTime.isBefore(startTime)) {
                                endTime.add(1, 'day');
                            }
                            let totalPay = +price;
                            if (priceModality === 'hourly') {
                                const duration = moment.duration(endTime.diff(startTime));
                                const hours = duration.asHours();
                                totalPay = +price * hours;
                            }

                            const shiftConfiguration: ShiftPublicationRequest = {
                                startTime: startTime.toDate(),
                                endTime: endTime.toDate(),
                                specialization: selectedSpecialization,
                                totalPay: totalPay,
                                capacity: capacity,
                                internalVisible: visibility.includes(ShiftModalityEnum.INTERNAL),
                                externalVisible: visibility.includes(ShiftModalityEnum.EXTERNAL),
                                details: details,
                                unit: unit,
                                recurrentDates: recurrentDates,
                                category: initialConfig.category?.code,
                                unitVisible: unitVisible
                            }

                            if (!validateFields()) return
                            setLoading(true)
                            onSubmit(shiftConfiguration).catch((error) => {
                                if (error instanceof ApiApplicationError) {
                                    setPublishErrorMessage(error.message)
                                } else {
                                    setPublishErrorMessage('Ha ocurrido un error inesperado')
                                }
                            }).finally(() => {
                                setLoading(false)
                            })
                        }}
                    >
                        <p
                            className="action-regular text-center py-small"
                        >
                            {isEditing ? buttonTitleEdit : buttonTitlePublish}
                        </p>
                    </ActionButton>
                </div>

            </div>
        </div>

    )
}
