import { useState } from 'react';
import ClickableRow from 'components/common/ClickableRow';
import { OPEN_FAVORITE_PROFESSIONALS_LABEL } from 'locales/professionals/favorite';
import FavoriteProfessionalsModal from 'components/professionals/FavoriteProfessionalsModal';

export default function OpenFavoriteProfessionals() {
  const [favoriteProfessionalsModalOpen, setFavoriteProfessionalsModalOpen] = useState(false);

  return (
    <div>
      <div className="my-small">
        <ClickableRow
          leftIcon='heart'
          text={OPEN_FAVORITE_PROFESSIONALS_LABEL}
          onClick={() => setFavoriteProfessionalsModalOpen(true)}
        />
      </div>

      <FavoriteProfessionalsModal
        isOpen={favoriteProfessionalsModalOpen}
        handleClose={() => setFavoriteProfessionalsModalOpen(false)}
      />
    </div>
  )
}