
import { TagComponent } from "components/common/TagComponent"
import { FacilityStaff } from "../../services/facility-staff"
import { TagLabel } from "components/common/TagLabel"
import LivoIcon from "assets/LivoLogo"
import { TagLabelRow } from "components/common/TagLabelRow"

interface FacilityStaffCardProps {
    faciltiyStaff: FacilityStaff
    onClick: () => void,
    facilityHasUnitsConfigured?: boolean
}

export const FacilityStaffCard: React.FC<FacilityStaffCardProps> = ({
    faciltiyStaff,
    onClick,
    facilityHasUnitsConfigured
}) => {
    const gridColumnsStyle = facilityHasUnitsConfigured ? 'grid-cols-4' : 'grid-cols-3'
    return (
        <div
            className={`flex flex-row  py-small px-small items-center  bg-white rounded-[16px]`}
            style={{
                minWidth: '200px',
            }}
        >
            <div className={`flex-1 grid ${gridColumnsStyle} items-center justify-between space-x-small w-full`}>
                <div className="flex col-span-1 text-left">
                    <p className="subtitle-regular truncate">{faciltiyStaff.firstName} {faciltiyStaff.lastName}</p>
                </div>
                <div
                    className="flex col-span-1 text-left text-nowrap"
                >
                    <p className="body-regular truncate">{faciltiyStaff.email}</p>
                </div>
                {facilityHasUnitsConfigured ?
                    <div
                        className="col-span-1 flex justify-start"
                    >
                        <TagLabelRow
                            tags={faciltiyStaff.units.map((unit) => unit.displayName)}
                        />
                    </div>
                    : null}

                <div
                    className="flex col-span-1"
                >
                    <TagLabelRow
                        tags={faciltiyStaff.permissions.map((perm) => perm.tabText)}
                    />

                </div>
            </div>
            <div
                onClick={onClick}
                className="rounded-full bg-Primary-100 p-tiny cursor-pointer ml-small"
            >
                <LivoIcon name='pencil' size={16} color={'#149EF2'} />
            </div>
        </div>
    )
}