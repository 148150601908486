import { Dispatch, AnyAction } from 'redux';
import { fetchActionableShifts } from 'services/activity';
import { RootState } from "store/types"
import { Shift } from "types/shifts"

export const setActivityShiftListSelectedShiftId = (shiftId: number | null ) => {
    return {
        type: 'SET_ACTIVITY_SHIFT_LIST_SELECTED_SHIFT_ID',
        payload: shiftId
    }
}

export const setActivityShiftListShifts = (shifts: Shift[]) => {
    return {
        type: 'SET_ACTIVITY_SHIFT_LIST_SHIFTS',
        payload: shifts
    }
}

export const fetchActivity = () => async (dispatch: Dispatch, getState: () => RootState):Promise<Shift[]> => {
    return  fetchActionableShifts().then((response) => {
        dispatch(setActivityShiftListShifts(response))
        return response
    }).catch((error) => {
        console.error(error)
        return []
    })
  };
  