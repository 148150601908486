import { ClaimRequest } from "types/claims"
import LivoIcon from "assets/LivoLogo";
import { CONFIRMED_LABEL, PROFESSIONALS_LABEL, REASONS_LABEL } from "locales/shiftClaimDetails/es";
import { EditCapacityModal } from "./EditCapacityModal";
import { useState } from "react";
import { ShiftCardTag } from "components/shifts/ShiftCardTag";
import { SlotReasons } from "./acceptedClaims/SlotReasons";
import { Toggle } from "components/common/buttons/Toggle";
import { AcceptedProfessionalsList } from "./acceptedClaims/AcceptedProfessionalsList";

interface AcceptedClaimsComponentProps {
    claims: ClaimRequest[];
    capacity: number;
    updateCapacity: (newCapacity: number) => void;
    editable: boolean;
    onDecreaseCapacity: () => void;
    selectClaim: (claim: ClaimRequest) => void
    shouldShowSlotReasonList: boolean,
    selectedOption: string,
    setSelectedOption: (newSelection: string) => void
}
export const AcceptedClaimsComponent: React.FC<AcceptedClaimsComponentProps> = ({
    claims,
    capacity,
    updateCapacity,
    editable,
    onDecreaseCapacity,
    selectClaim,
    shouldShowSlotReasonList,
    selectedOption,
    setSelectedOption
}) => {
    const [isCapacityModalOpen, setCapacityModalOpen] = useState(false)

    const cancellationRequests = claims.filter(claim => claim.cancellationRequest)
    return (
        <div
            className="flex flex-col px-medium py-large"
        >
            <div
                className="flex flex-col mb-large"
            >
                <div
                    className="flex flex-row justify-between items-center space-x-small"
                >
                    <div
                        className="flex flex-row items-center space-x-small"
                    >
                        <LivoIcon name='user' size={24} color='#ACBBCB' />
                        <p
                            className="body-regular"
                        >
                            {PROFESSIONALS_LABEL}
                        </p>
                        {
                            cancellationRequests.length > 0 ?
                                <ShiftCardTag
                                    totalPendingClaims={cancellationRequests.length}
                                    isFilled={false}
                                />
                                : null
                        }
                    </div>
                    <div
                        className="flex flex-row items-center"
                    >
                        <p className="body-regular ml-tiny">
                            {claims.length}

                        </p>
                        <p className="body-regular text-Text-Light">
                            /{capacity}
                        </p>
                    </div>
                </div>
                {shouldShowSlotReasonList ? <Toggle
                    style={{
                        marginTop: '8px'
                    }}
                    option1={{
                        label: CONFIRMED_LABEL,
                        value: 'professionals'
                    }}
                    option2={{
                        label: REASONS_LABEL,
                        value: 'reasons'
                    }}
                    selectedOption={selectedOption}
                    setSelectedOption={(newSelection) => {
                        setSelectedOption(newSelection)
                    }}
                    unselectedColor='#EEEFF3'
                    unselectedTextColor='#375D68'
                    selectedColor='#375D68'
                    selectedTextColor='#FFFFFF'
                /> : null}
            </div>
            {selectedOption === 'professionals' ? <AcceptedProfessionalsList
                claims={claims}
                selectClaim={selectClaim}
                capacity={capacity}
                editable={editable}
                onDecreaseCapacity={onDecreaseCapacity}
                setCapacityModalOpen={setCapacityModalOpen}
            /> :
                <SlotReasons
                    claims={claims}
                    onPress={selectClaim}
                />}
            <EditCapacityModal
                isOpen={isCapacityModalOpen}
                maxCapacity={5 - capacity}
                goBack={() => setCapacityModalOpen(false)}
                addCapacity={(newCapacity: number) => {
                    updateCapacity(newCapacity + capacity)
                    setCapacityModalOpen(false)
                }}
            />
        </div>
    )
}