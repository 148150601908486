import LivoIcon from "assets/LivoLogo";

interface HeaderComponentProps {
    title: string;
    onClose: () => void;
    goBack?: () => void;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = (
    { title, onClose, goBack }
) => {
    return (
        <div
            className="flex flex-row w-full justify-between items-center p-large border-b border-Divider-Default"
        >
            <div
                className="flex space-x-small items-center"
            >
                {goBack ? <div
                    onClick={goBack}
                >
                    <LivoIcon name='chevron-left' size={24} color='#2C3038' />
                </div> : null}
                <p
                    className="heading-sm"
                >
                    {title}
                </p>
            </div>

            <button
                onClick={onClose}
                className="flex items-center justify-center"
            >
                <LivoIcon name='close' size={24} color='#2C3038' />
            </button>
        </div>
    )
}