import { NotificationsBadge } from "components/common/NotificationsBadge"
import { ProfilePicture } from "../../components/shiftDetails/ProfilePicture"
import { FacilityDataFieldDefinition, InternalProfessional } from "../../types/internal"
import { CategoryTag } from "components/common/CategoryTag"

interface InternalProfessionalCardProps {
    professional: InternalProfessional
    isSelected?: boolean,
    onClick?: () => void,
    notifications?: number,
    dataFieldDefinitions: FacilityDataFieldDefinition[]
}

export const InternalProfessionalCard: React.FC<InternalProfessionalCardProps> = ({
    professional,
    isSelected,
    onClick,
    notifications,
    dataFieldDefinitions
}) => {
    return (
        <div
            onClick={onClick}
            className={`flex flex-row  py-small px-small items-center ${isSelected ? 'bg-Action-Secondary text-Text-Inverse' : 'bg-white'} rounded-[16px] relative ${onClick ? 'cursor-pointer' : ''} overflow-hidden`}
            style={{
                minWidth: '200px',
            }}
        >
            <div className="flex flex-1 items-center justify-between space-x-small w-full">
                <div className="flex flex-[2] text-left">
                    <div
                        className="flex flex-row items-center space-x-small"
                    >
                        <ProfilePicture profilePictureUrl={professional.profilePictureUrl} size={36} modality={null} />
                        {
                            professional.category ?
                                <CategoryTag
                                    text={professional.category.acronym}
                                /> : null
                        }
                        <p className="subtitle-regular">{professional.firstName} {professional.lastName}</p>
                    </div>
                </div>
                {
                    dataFieldDefinitions.map((dataFieldDefinition) => {
                        const selectedValue = professional.dataFields.find((dataField) => dataField.key === dataFieldDefinition.key)?.displayText
                        return (
                            <div
                                key={dataFieldDefinition.key}
                                className="flex flex-1 text-left"
                            >
                                {
                                    selectedValue || '-'
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div
                className="w-[20px]"
            >
                {notifications && <NotificationsBadge
                    notifications={notifications}
                />}
            </div>

        </div>
    )
}