import { ActivityShiftListAction, ActivityShiftListState, PublishShiftListAction, PublishShiftListState } from "store/types"

const initialState: ActivityShiftListState = {
    selectedShiftId: null,
    shifts: []
}

const activityShiftListReducer = (state = initialState, action: ActivityShiftListAction) => {
    switch (action.type) {
        case 'SET_ACTIVITY_SHIFT_LIST_SELECTED_SHIFT_ID':
            return {
                ...state,
                selectedShiftId: action.payload
            }
        case 'SET_ACTIVITY_SHIFT_LIST_SHIFTS': {
            return {
                ...state,
                shifts: action.payload
            }
        }
        default:
            return state
    }
}

export default activityShiftListReducer;