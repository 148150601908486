import LivoIcon from "assets/LivoLogo"
import { COMPENSATION_OPTION_TYPE_LABEL } from "locales/shiftClaimDetails/es";
import { CompensationOption } from "types/claims";

interface CompensationOptionProps {
    compensationOption: CompensationOption;
}

export const CompensationOptionComponent: React.FC<CompensationOptionProps> = ({
    compensationOption
}) => {
    return (
        <div
            className="flex flex-col w-full p-medium"
        >
            <div
                className="flex flex-row space-x-small mb-large"
            >
                <LivoIcon name='report-medical' size={24} color='#ACBBCB' />
                <p
                    className="body-regular"
                >
                    {COMPENSATION_OPTION_TYPE_LABEL}
                </p>
            </div>
            <div
                className="flex flex-col w-full p-medium  ring-solid ring-[1px] ring-Divider-Subtle rounded-[8px] justify-center  text-center"
            >
                 <p
                    className="bodyRegular"
                >
                    {compensationOption.label}
                </p>
                <p
                    className="heading-md"
                >
                    {compensationOption.compensationValue}
                </p>
            </div>
        </div>
    )
}