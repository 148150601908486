import { ClaimRequest } from "types/claims";
import { ProfilePicture } from "./ProfilePicture";
import LivoIcon from "assets/LivoLogo";
import { ShiftModalityEnum } from "types";
import { CANCELLATION_REQUEST, NO_REVIEWS_LABEL } from "locales/shiftClaimDetails/es";
import { FIRST_SHIFTER_LABEL, VIEW_MORE } from "locales/professionalClaim";
import { TagComponent } from "components/common/TagComponent";
import { TagLabel } from "components/common/TagLabel";
import FavoriteTag from "components/professionals/FavoriteTag";

interface ClaimRowProps {
    claim: ClaimRequest;
    onClick: () => void;
    style?: any;

}

export const ClaimRow: React.FC<ClaimRowProps> = ({ claim, style, onClick }) => {
    const subtitleComponent = claim.modality === ShiftModalityEnum.EXTERNAL || !claim.modality ?
        claim.professionalProfile?.firstShifterForFacility ?
            <p className="info-caption text-[#987ED4]">
                {FIRST_SHIFTER_LABEL}
            </p> :
            claim.professionalProfile?.favorite ?
                <FavoriteTag /> :
            claim.professionalProfile?.professionalReview.totalReviews > 0 ?
                <div
                    className="flex flex-row items-center space-x-tiny w-full justify-start :hovered:bg-Background-Secondary"
                >
                    <LivoIcon name='star-filled' size={12} color='#FF911C' />
                    <p className="body-sm text-Text-Default">{claim.professionalProfile?.professionalReview.averageRating}</p>

                    <p className="body-sm text-Text-Subtle">
                        {`(${claim.professionalProfile?.professionalReview.totalReviews} ${claim.professionalProfile.professionalReview.totalReviews > 1 ? 'Reseñas' : 'Reseña'})`}
                    </p>
                </div>
                :
                <p className="info-caption text-Text-Subtle">
                    {NO_REVIEWS_LABEL}
                </p> :

        claim.cancellationRequest ?
            <p className="info-caption text-Negative-500">
                {CANCELLATION_REQUEST}
            </p> :
            <div
                className="flex flex-col justify-center w-full"
            >
                <div
                    className="flex flex-row items-center space-x-tiny w-full gap-y-tiny flex-wrap"
                >
                    {claim.professionalProfile.tags?.map((tag, index) => (
                        <TagLabel
                            key={index}
                            text={tag.label}
                            backgroundColor={tag.styling?.backgroundColor || '#EEEFF3'}
                            color={tag.styling?.textColor || undefined}
                        />
                    ))}
                </div>
                {claim.compensationOption && 
                <p className="info-caption text-Text-Subtle">
                    {claim.compensationOption?.label}: {claim.compensationOption.compensationValue}
                </p>}
            </div>;


    return (
        <div
            className="flex flex-row items-center space-x-medium h-full py-small"
        >
            <ProfilePicture profilePictureUrl={claim.professionalProfile?.profilePictureUrl} modality={claim.modality} size={48} style={style} />
            <div
                className="flex flex-col justify-center flex-1"
            >
                <p className="body-regular text-Text-Default truncate">{claim.professionalProfile?.firstName} {claim.professionalProfile.lastName}</p>
                {subtitleComponent}
                <p
                    onClick={onClick}
                    className="action-sm text-Action-Primary cursor-pointer"
                >
                    {VIEW_MORE}
                </p>
            </div>
        </div>
    )
}