import LivoIcon from "assets/LivoLogo"
import { ActionButton } from "components/common/ActionButton";
import { CustomInput } from "components/common/CustomInput";
import { ERROR_INVALID_PASSWORD, ERROR_PASSWORDS_DO_NOT_MATCH, SETTINGS_SCREEN_CHANGE_PASSWORD_BUTTON, SETTINGS_SCREEN_CHANGE_PASSWORD_CONFIRM, SETTINGS_SCREEN_CHANGE_PASSWORD_NEW, SETTINGS_SCREEN_CHANGE_PASSWORD_OLD } from "locales/settings";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { changePasswordRequest } from "services/account";
import { showToastAction } from "store/actions/appConfigurationActions";

interface ChangePasswordComponentProps {
    onSubmit: () => void;
    errorMessage: string;
}

export const ChangePasswordComponent: React.FC<ChangePasswordComponentProps> = ({
    onSubmit,
    errorMessage
}) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [invalidOldPassword, setInvalidOldPassword] = useState('');
    const [invalidNewPassword, setInvalidNewPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();


    const handleSaveNewPassword = async () => {
        const validOldPassword = oldPassword.length > 0;
        const passwordsMatch = newPassword === confirmPassword;
        const passwordLength = newPassword.length > 0;
        if (!validOldPassword) {
            setInvalidOldPassword(ERROR_INVALID_PASSWORD);
        }
        else if (!passwordsMatch) {
            setInvalidNewPassword(ERROR_PASSWORDS_DO_NOT_MATCH);
        } else if (!passwordLength) {
            setInvalidNewPassword(ERROR_INVALID_PASSWORD);
        }
        else {
            setIsLoading(true);
            await changePasswordRequest({
                oldPassword,
                newPassword,
            })
                .then(() => {
                    onSubmit()
                    setOldPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                    setIsLoading(false)
                    dispatch(showToastAction({
                        message: 'Contraseña cambiada con éxito',
                        severity: 'success'
                      }))
                })
                .catch(error => {
                    setIsLoading(false)
                    setInvalidOldPassword(error.message)
                });
        }
    };

    return (
        <div
            className="flex flex-col"
        >
            <div
                className="flex flex-col p-large space-y-medium bg-Background-Secondary"
            >
                <div
                    className="flex flex-col p-medium rounded-[8px] bg-white space-y-medium w-full"
                >
                    <p
                        className="heading-sm text-Text-Subtle"
                    >
                        {SETTINGS_SCREEN_CHANGE_PASSWORD_OLD}
                    </p>
                    <CustomInput
                        placeHolder='Contraseña actual'
                        inputType='password'
                        selectedValue={oldPassword}
                        setValue={setOldPassword}
                        errorMessage={invalidOldPassword}
                    />
                </div>
                <div
                    className="flex flex-col p-medium rounded-[8px] bg-white space-y-medium w-full"
                >
                    <p
                        className="heading-sm text-Text-Subtle"
                    >
                        {SETTINGS_SCREEN_CHANGE_PASSWORD_NEW}
                    </p>
                    <CustomInput
                        placeHolder={SETTINGS_SCREEN_CHANGE_PASSWORD_NEW}
                        inputType='password'
                        selectedValue={newPassword}
                        setValue={setNewPassword}
                        errorMessage={invalidNewPassword}
                    />
                    <CustomInput
                        placeHolder={SETTINGS_SCREEN_CHANGE_PASSWORD_CONFIRM}
                        inputType='password'
                        selectedValue={confirmPassword}
                        setValue={setConfirmPassword}
                    />
                </div>


            </div>
            <div
                className="flex flex-row justify-between items-center p-large border-t border-Divider-Default bg-white"
            >
                <div
                    className="flex flex-col space-y-[8px] mr-[8px]"
                >
                    <p
                        className="body-regular text-Negative-500"
                    >
                        {errorMessage.length > 0 ? 'Error al crear el turno' : ''}
                    </p>
                    <p
                        className="body-regular text-Negative-500"
                    >
                        {errorMessage}
                    </p>
                </div>
                <div
                    className="flex items-end"
                >
                    <ActionButton
                        onClick={async () => {
                            handleSaveNewPassword()
                        }}
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        style={{
                            width: '250px'
                        }}
                    >
                        <p
                            className="action-regular text-center py-tiny"
                        >
                            {SETTINGS_SCREEN_CHANGE_PASSWORD_BUTTON}
                        </p>
                    </ActionButton>
                </div>

            </div>
        </div>


    )
}