import React from "react";
import LivoIcon from "../../assets/LivoLogo";

interface CapacityComponentProps {
    acceptedClaims: number
    emptyClaims: number
}

export const CapacityComponent: React.FC<CapacityComponentProps> = ({
    acceptedClaims,
    emptyClaims
}) => {
    return (
        <div
            className="flex"
        >

            {
                Array.from({ length: acceptedClaims }, (_, i) => i + 1).map((acceptedClaim, index) => (
                    <LivoIcon key={index} name='user-check' color='#33B240' size={20} />)
                )
            }
              {
                Array.from({ length: emptyClaims }, (_, i) => i + 1).map((emptyClaim, index) => (
                    <LivoIcon  key={index} name='user' color='#ACBBCB' size={20} />)
                )
            }
        </div>
    )
}