import { CustomInput } from "../../components/common/CustomInput";
import { DataFieldSubmission, DataFieldType, FacilityDataFieldDefinition } from "../../types/internal";
import { MultiSelectDataField } from "./MultiSelectDataField";
import { SingleSelectDataField } from "./SingleSelectDataField";


interface FacilityDataFieldProps {
    facilityDataFieldDefinition: FacilityDataFieldDefinition;
    setDataFieldSubmission: (dataFieldSubmission: DataFieldSubmission) => void;
    dataFieldSubmission?: DataFieldSubmission;
    hasChanged?: boolean;
}

export const FacilityDataField: React.FC<FacilityDataFieldProps> = ({
    facilityDataFieldDefinition,
    dataFieldSubmission,
    setDataFieldSubmission,
    hasChanged
}) => {

    const selectedValues = dataFieldSubmission?.selectedValues || [];

    return (
        <div
        >
                {facilityDataFieldDefinition.type === DataFieldType.SINGLE_SELECT && (
                    <SingleSelectDataField
                        hasChanged={hasChanged}
                        dataFieldDefinition={facilityDataFieldDefinition}
                        selectedValue={selectedValues.length > 0 ? selectedValues[0] : ''}
                        title={facilityDataFieldDefinition.label}
                        setSelectedValue={(selectedValue) => {
                            setDataFieldSubmission(
                                {
                                    key: facilityDataFieldDefinition.key,
                                    selectedValues: [selectedValue]
                                }
                            );
                        }}
                    />
                )}
                {facilityDataFieldDefinition.type === DataFieldType.MULTI_SELECT && (
                    <MultiSelectDataField
                        hasChanged={hasChanged}
                        title={facilityDataFieldDefinition.label}
                        dataFieldDefinition={facilityDataFieldDefinition}
                        selectedValues={selectedValues}
                        setSelectedValues={(selectedValues) => {
                            setDataFieldSubmission(
                                {
                                    key: facilityDataFieldDefinition.key,
                                    selectedValues: selectedValues
                                }
                            );
                        }}
                    />
                )}
                {facilityDataFieldDefinition.type === DataFieldType.FREE_TEXT && (
                    <CustomInput
                        hasChanged={hasChanged}
                        setValue={(text) => {
                            setDataFieldSubmission(
                                {
                                    key: facilityDataFieldDefinition.key,
                                    selectedValues: [text]
                                }
                            );
                        }}
                        selectedValue={selectedValues.length > 0 ? selectedValues[0] : ''}
                        placeHolder={facilityDataFieldDefinition.label}
                        label={facilityDataFieldDefinition.label}
                    />)
                }
        </div>
    )
}