import moment from "moment";

export const HOURLY_RATE = "Por hora";
export const TOTAL_RATE = "Total";
export const PUBLISH_SINGLE_SHIFT_SUMMARY_HEADER  = (date: string) => `Se creará un turno el ${moment(date).format('DD/MM/YYYY')}`
export const PUBLISH_MULTIPLE_SHIFTS_SUMMARY_HEADER = (numberOfShifts:number, startDate: string, endDate: string) => `Se crearán ${numberOfShifts} turnos entre el ${startDate} y el ${endDate}`
export const PUBLISH_SHIFT_BUTTON = 'Crear turno';
export const PUBLISH_MULTIPLE_SHIFTS_BUTTON = (numberOfShifts: number) => `Crear ${numberOfShifts} turnos`;
export const PUBLISH_SHIFT_TITLE = 'Nuevo turno';
export const RECURRENT_LABEL = 'Recurrente';
export const PUBLISH_SHIFT_DATE_SELECTOR_SUBTITLE = 'Selecciona los días que quieras crear el turno';
export const PUBLISH_ERROR_HEADER = 'Error al crear el turno';