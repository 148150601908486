import { ActionButton } from "components/common/ActionButton"
import { ACTIVITY_DETAILS_BUTTON, ACTIVITY_DETAILS_EMPTY_STATE_SUBTITLE, ACTIVITY_DETAILS_EMPTY_STATE_TITLE } from "locales/calendar"
import { useNavigate } from "react-router-dom"

export const EmptyDetailsComponent = () => {
    const navigate = useNavigate()

    return (
        <div
            className="p-24 flex flex-col text-center w-full h-full bg-white items-center"
        >
            <p
                className="text-[72px] mb-small"
            >
                🙌
            </p>
            <p
                className="heading-md text-Text-Default mb-small"
            >
                {ACTIVITY_DETAILS_EMPTY_STATE_TITLE}
            </p>
            <p
                className="body-regular text-Text-Default mb-medium"
            >
               {ACTIVITY_DETAILS_EMPTY_STATE_SUBTITLE}
            </p>
            <div
                className="flex items-center flex-col"
            >
                <ActionButton
                    isDisabled={false}
                    isLoading={false}
                    onClick={() => {
                       navigate('/turnos-publicados')
                    }}
                    style={{
                        padding: '12px'
                    }}
                >
                    <div
                        className="flex items-center text-center"
                    >
                        <p
                            className="action-regular"
                        >
                            {ACTIVITY_DETAILS_BUTTON}
                        </p>
                    </div>
                </ActionButton>
            </div>

        </div>
    )
}