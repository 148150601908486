import { useEffect, useState } from "react"
import moment from "moment"
import { Shift } from "types/shifts"
import { PublishShiftConfigurationDTO, RecurrencyOptionsEnum } from "types/publish-shift"

import { CancelShiftReasonsModal } from "./CancelShiftReasonsModal"
import { CancelRecurrentShiftModal } from "./CancelRecurrentShiftModal"
import { CANCEL_SHIFT_MODAL_TITLE, CANCEL_SHIFT_MODAL_TITLE_MULTIPLE } from "locales/shiftClaimDetails/es"

interface CancelShiftModalProps {
    onClose: () => void;
    isOpen: boolean;
    isRecurrent: boolean;
    cancelShift: (reason: string, details: string, bulkOperation?: RecurrencyOptionsEnum) => void;
    shiftDetails: Shift;
}

export const CancelShiftModal: React.FC<CancelShiftModalProps> = ({
    onClose,
    isOpen,
    isRecurrent,
    cancelShift,
    shiftDetails
}) => {
    // select the the start time according to shift time in day

    const [recurrentOption, setRecurrentOption] = useState<RecurrencyOptionsEnum>(RecurrencyOptionsEnum.THIS_SHIFT)
    const [cancelRecurrentShiftModalOpen, setCancelRecurrentShiftModalOpen] = useState(false)
    const [cancelShiftReasonModalOpen, setCancelShiftReasonModalOpen] = useState(false)

    useEffect(() => {
        if (isOpen) {
            if (isRecurrent) {
                setCancelRecurrentShiftModalOpen(true)

            } else {
                setCancelShiftReasonModalOpen(true)
            }
        } else {
            setCancelRecurrentShiftModalOpen(false)
            setCancelShiftReasonModalOpen(false)
        }
    }, [isOpen])



    return (
        <div>
            <CancelShiftReasonsModal
                title={recurrentOption === RecurrencyOptionsEnum.ALL_SHIFTS ? CANCEL_SHIFT_MODAL_TITLE_MULTIPLE : CANCEL_SHIFT_MODAL_TITLE}
                isOpen={cancelShiftReasonModalOpen}
                goBack={() => {
                    setCancelShiftReasonModalOpen(false)
                    onClose()
                }
                }
                cancelShift={(reason, details) => {
                    const bulkOperation = recurrentOption === RecurrencyOptionsEnum.THIS_SHIFT ? undefined : recurrentOption
                    cancelShift(reason, details, bulkOperation)
                }}
            />
            <CancelRecurrentShiftModal
                isOpen={cancelRecurrentShiftModalOpen}
                cancelShift={() => {
                    setCancelRecurrentShiftModalOpen(false)
                    setCancelShiftReasonModalOpen(true)
                }}
                selectedOption={recurrentOption}
                setSelectedOption={setRecurrentOption}
                goBack={() => {
                    setCancelRecurrentShiftModalOpen(false)
                    onClose()
                }
                }
                shiftDetails={shiftDetails}
            />
        </div>

    )
}