import LivoIcon from "assets/LivoLogo";
import { CategoryTag } from "components/common/CategoryTag";
import { TagLabel } from "components/common/TagLabel";
import { ModalityTag } from "components/internal/ModalityTag";
import { ShiftModalityEnum } from "types";
import { Category } from "types/shifts";
import { CategoryAndVisibilityTags } from "components/common/CategoryAndVisibilities";

interface ShiftCardHeaderProps {
    title: string;
    recurrent: boolean;
    style?: any
    skills: {
        displayText: string;
        value: string;
    }[]
    category?: Category;
    internalVisible: boolean;
    externalVisible: boolean;

}

export const ShiftCardHeader: React.FC<ShiftCardHeaderProps> = ({
    title,
    recurrent,
    skills,
    style,
    category,
    internalVisible,
    externalVisible
}) => {
    return (
        <div
            className="flex flex-col w-full"
        >
            <CategoryAndVisibilityTags
                category={category}
                internalVisible={internalVisible}
                externalVisible={externalVisible}
            />
            <div
                className="flex items-center flex-row justify-start space-x-tiny mb-tiny"
                style={style}
            >
                {recurrent ? <LivoIcon name='repeat' size={16} color='#ACBBCB' /> : null}
                <h1 className="subtitle-regular flex flex-1 truncate">
                    {title}
                </h1>
            </div>
            <div
                className="flex-row space-x-small items-center justify-start gap-tiny flex-wrap inline-flex"
            >
                {skills.map((skill, index) =>
                    <TagLabel
                        key={index}
                        text={skill.displayText}
                    />)
                }
            </div>
        </div>

    )
}