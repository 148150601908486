import LivoIcon from "assets/LivoLogo";
import { TagLabel } from "./TagLabel";
import { useEffect, useRef, useState } from "react";

interface TagLabelRowProps {
    tags: string[];
}

export const TagLabelRow: React.FC<TagLabelRowProps> = ({ tags }) => {
    const [showRestModalOpen, setShowRestModalOpen] = useState(false);
    const [visibleCount, setVisibleCount] = useState(2); // Initial visible tags count

    const containerRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const visibleTags = tags.slice(0, visibleCount);
    const restTags = tags.slice(visibleCount);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setShowRestModalOpen(false);
            }
        };

        if (showRestModalOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showRestModalOpen]);



    useEffect(() => {
        const container = containerRef.current;

        const handleResize = () => {
            if (container) {
                const containerWidth = container.offsetWidth;
                calculateVisibleTags(containerWidth);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (container) {
            resizeObserver.observe(container);
            handleResize(); // Initial check
        }

        return () => {
            if (container) {
                resizeObserver.unobserve(container);
            }
        };
    }, []);


    const calculateVisibleTags = (containerWidth: number) => {
        let totalWidth = 0;
        let count = 0;
        const tagWidths = Array.from(containerRef.current?.children || []).map(child =>
            (child as HTMLElement).offsetWidth
        );

        for (const width of tagWidths) {
            if (totalWidth + width <= containerWidth) {
                totalWidth += width;
                count++;
            } else {
                break;
            }
        }

        // Deduct 1 to account for the "more" button if there's any hidden tag
        setVisibleCount(count - (restTags.length > 0 ? 1 : 0));
    };

    return (
        <div ref={containerRef} className="flex flex-row space-x-tiny items-center">
            {visibleTags.map((tag, index) => (
                <TagLabel key={index} text={tag} />
            ))}
            {restTags.length > 0 && (
                <div className="relative">
                    <button
                        ref={buttonRef}
                        onClick={() => setShowRestModalOpen(!showRestModalOpen)}
                        className="p-[4px] ring-[1px] rounded-[4px] ring-Divider-Default cursor-pointer"
                    >
                        <LivoIcon name='dots' size={16} color='#707A91' />
                    </button>
                    {showRestModalOpen && (
                        <div
                            ref={modalRef}
                            className="p-small rounded-[8px] mb-[14px] absolute z-10 bg-white shadow-custom top-full mt-[4px] space-y-small -right-1/2"
                        >
                            {restTags.map((tag, index) => (
                                <TagLabel key={index} text={tag} />
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
