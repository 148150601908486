interface CapacityItemProps {
    isSelected: boolean;
    capacity: string;
    onPress: () => void;
}

export const CapacityItem: React.FC<CapacityItemProps> = ({
    isSelected,
    capacity,
    onPress
}) => {
    return (
        <div
            onClick={onPress}
            className={`
                flex items-center w-full h-full px-medium py-small rounded-[8px]  ${isSelected ? 'bg-Action-Secondary' : 'bg-white hover:bg-Background-Secondary ring-Divider-Subtle ring-[1px] ring-solid'}  cursor-pointer`}
        >
            <p
                className="action-sm text-center w-full"
                style={{
                    color: isSelected ? '#FFFFFF' : '#055186'
                }}
            >
                {capacity}
            </p>

        </div>
    );
}