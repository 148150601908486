// NoInternetPage.jsx
import LivoIcon from 'assets/LivoLogo';
import { NO_INTERNET_MESSAGE, NO_INTERNET_TITLE } from 'locales/noInternet';

const NoInternetPage = () => {
  return (
    <div
      className='content flex flex-col w-full p-large text-center items-center justify-center'>
      <LivoIcon name='cloud-off' size={64} color='#707A91' />
      <p
        className='heading-md text-Text-Default mb-small'
      >{NO_INTERNET_TITLE}</p>
      <p
        className='body-regular text-Text-Default'
      >{NO_INTERNET_MESSAGE}</p>
    </div>
  );
};

export default NoInternetPage;