interface NotificationsBadgeProps {
    notifications: number;
    style?: any;
}


export const NotificationsBadge: React.FC<NotificationsBadgeProps> = ({
    notifications,
    style
}) => {
    return (
        <div
            className="flex items-center justify-center flex-col bg-Negative-500 rounded-[100px] p-[2px]"
            style={style}
        >
            <p
                className="subtitle-sm text-white w-[16px] text-center"
            >
                {notifications}
            </p>
        </div>
    )
}