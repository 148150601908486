import { RootState, Toast } from "store/types";
import { CommonToast } from "./CommonToastComponent"
import { useDispatch, useSelector } from "react-redux";

interface AppToastProps {
    style?: any;
}

export const AppToast: React.FC<AppToastProps> = ({
    style
}) => {
    const { toast } = useSelector((state: RootState) => state.appConfiguration);
    const dispatch = useDispatch();

    return toast ?
        toast.severity === 'success' ?
            <CommonToast
                backgroundColor="#F0FCF2"
                iconColor="#33B240"
                iconName="circle-check-filled"
                message={toast.message}
                onClose={() => {
                    dispatch({ type: 'HIDE_TOAST' });
                }}
                style={style}
            /> :
            <CommonToast
                backgroundColor="#FFF0F1"
                iconColor="#FA3D3B"
                iconName="alert-triangle-filled"
                message={toast.message}
                onClose={() => {
                    dispatch({ type: 'HIDE_TOAST' });
                }}
                style={style}
            /> :
            null
}