import { ExpandableText } from "components/common/ExpandableText";
import LivoIcon from "../../../assets/LivoLogo";
import { SlotReason, } from "../../../types/claims";
import { EMPTY_SLOT_REASON } from "locales/shiftClaimDetails/es";

interface SlotReasonComponentProps {
    reason: SlotReason | null;
    professionalName: string;
    onPress: () => void;
}

export const SlotReasonComponent: React.FC<SlotReasonComponentProps> = ({
    reason,
    professionalName,
    onPress
}) => {
    return (
        <div
            onClick={onPress}
            className="flex flex-col w-full cursor-pointer hover:bg-Background-Secondary"
        >
            <p
                className="body-small"
            >
                {
                    professionalName
                }
            </p>
            <div
                className="flex flex-row items-start space-x-tiny"
            >
                <div
                    className="flex items-center justify-center"
                >
                    <LivoIcon name="replace" size={24} color="#ACBBCB" />
                </div>
                <div
                    className="flex flex-col w-full"
                >
                    {reason ?
                        <>
                            <p
                                className="subtitle-regular"
                            >
                                {reason.displayText}
                            </p>
                            <ExpandableText
                                textClassName="info-caption text-Text-Subtle"
                                text={reason.comment || ' \n'}
                                maxLines={1}
                            />
                        </>
                        :
                        <>
                            <p
                                className="subtitle-regular text-Text-Light"
                            >
                                {EMPTY_SLOT_REASON}
                            </p>
                            <p
                                className="info-caption text-Text-Light"
                            >{'\n'}</p>
                        </>

                    }
                </div>
                <LivoIcon name="chevron-right" size={24} color="#149EF2" />
            </div>
        </div>
    )
}