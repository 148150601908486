import { CalendarMonth } from "components/calendar/CalendarMonth";
import moment from "moment";
import React, { useEffect } from "react";
import { CalendarSummary } from "types/publish-shift";

interface SingleDateSelectorProps {
    setDate: (date: string) => void;
    selectedDate: string;
    calendarSummary: CalendarSummary[];
}

export const SingleDateSelector: React.FC<SingleDateSelectorProps> = ({
    setDate,
    selectedDate,
    calendarSummary

}) => {
    const selectedMonth = moment(selectedDate).startOf('month').format('YYYY-MM-DD');
    const [currentMonth, setCurrentMonth] = React.useState<string>(selectedMonth)
    useEffect(() => {
        if (moment(selectedDate).month() !== moment(currentMonth).month()) { // change month on when the selected date is on another month
            const newSelectedMonth = moment(selectedMonth).startOf('month').format('YYYY-MM-DD')
            setCurrentMonth(newSelectedMonth)
        }
    }, [selectedDate])

    return (
        <CalendarMonth
            onDayPress={(day: string) => {
                setDate(day)
            }}
            calendarSummary={calendarSummary.map((day) => ({
                ...day,
                hasAlert: false,
                totalShifts: 0,
            }))}
            monthToRender={currentMonth}
            daysSelected={[selectedDate]}
            onMonthChange={(month) => { setCurrentMonth(month) }}
            disablePastDates={true}
        />
    )
}