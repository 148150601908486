import { UNIT_LABEL, UNIT_VISIBLE_FOR_PROFESSIONALS_LABEL } from "locales/internalProfessionalPage"
import { DropDownWithInput } from "./DropDownWithInput"
import { PublishInputRow } from "./PublishInputRow"
import { CustomInput } from "components/common/CustomInput"
import LivoIcon from "assets/LivoLogo";
import { TogglerOn } from "assets/TogglerOn";
import { TogglerOff } from "assets/TogglerOff";

interface UnitInputComponentProps {
    unit: string;
    setUnit: (unit: string) => void;
    invalidUnit: string;
    setInvalidUnit: (invalidUnit: string) => void;
    units: string[] | null;
    unitVisible: boolean;
    setUnitVisible: (visible: boolean) => void;
    unitVisibleConfigurable: boolean;
}

export const UnitInputComponent: React.FC<UnitInputComponentProps> = ({
    unit,
    setUnit,
    invalidUnit,
    setInvalidUnit,
    units,
    unitVisible = false,
    setUnitVisible,
    unitVisibleConfigurable= false,
}) => {
    return(
    <div  className="flex flex-col">
    <PublishInputRow
        iconName="nurse">
        <div
            className="flex flex-col w-full"
        >
            {units != null && units.length > 0 ?
                <DropDownWithInput
                    label={UNIT_LABEL}
                    selectedOptionId={unit}
                    setOptionId={(optionId) => {
                        setUnit(optionId)
                    }}
                    placeHolder={UNIT_LABEL}
                    options={units.map((unit) => ({
                        id: unit,
                        name: unit
                    }))
                    }
                    errorMessage={invalidUnit}
                /> :
                <CustomInput // Unidad
                    label={UNIT_LABEL}
                    selectedValue={unit}
                    setValue={(newUnit) => {
                        setUnit(newUnit)
                        setInvalidUnit('')
                    }}
                    placeHolder={UNIT_LABEL}
                    errorMessage={invalidUnit}
                />}

        </div>

    </PublishInputRow>
                    {unitVisibleConfigurable && (
                        <div
                            onClick={() => setUnitVisible(!unitVisible)}
                            className="flex flex-row space-x-small ml-10 cursor-pointer items-center"
                        >
                            {unitVisible ? <TogglerOn /> : <TogglerOff />}
                            <p className="info-overline text-Text-Subtle">
                                {UNIT_VISIBLE_FOR_PROFESSIONALS_LABEL}
                            </p>
                        </div>
                    )}
                    </div>)
}
