import LivoIcon from "assets/LivoLogo";
import { TogglerOff } from "assets/TogglerOff";
import { TogglerOn } from "assets/TogglerOn";
import { RECURRENT_LABEL } from "locales/publishShift";

interface RecurentTogglerProps {
    isRecurrent: boolean;
    setIsRecurrent: (isRecurrent: boolean) => void;
}

export const RecurrentToggler: React.FC<RecurentTogglerProps> = ({
    isRecurrent,
    setIsRecurrent,
}) => {
    return (
        <div
            className="flex flex-1 w-full items-center justify-between py-medium px-small ring-solid ring-[1px] ring-Divider-Default rounded-[8px] space-x-small"
        >
            <LivoIcon name='repeat' size={24} color='#ACBBCB' />
            <p
                className="body-regular text-Text-Subtle w-full text-left"
            >
                {RECURRENT_LABEL}
            </p>
            <button
                onClick={() => setIsRecurrent(!isRecurrent)}
            >
              {
                isRecurrent ? <TogglerOn /> : <TogglerOff />
              }
            </button>
        </div>
    );
}