import { ClaimRequest } from "types/claims";
import { ProfilePicture } from "./ProfilePicture";
import LivoIcon from "assets/LivoLogo";
import { ShiftModalityEnum } from "types";
import { ADD_CAPACITY_LABEL } from "locales/shiftClaimDetails/es";

interface IncreaseCapacityProps {
    onClick: () => void;
}

export const IncreaseCapacity: React.FC<IncreaseCapacityProps> = ({ onClick }) => {
    return (
        <div
            className="flex flex-row items-center space-x-small h-full w-full py-small"
        >
            <button
                onClick={onClick}
                className={`flex items-center justify-center rounded-[8px] bg-Primary-100 p-medium`}>
                <LivoIcon name='plus' size={24} color='#149EF2' />
            </button>
            <div
                className="flex flex-col justify-center w-full"
            >
                <p className="body-regular text-Text-Default">{ADD_CAPACITY_LABEL}</p>
            </div>
        </div>
    )
}