export const CHANGE_PASSWORD_TITLE = 'Cambiar contraseña';
export const PERSONAL_DATA_TITLE = 'Datos personales';
export const SETTINGS_MODAL_HEADER_TITLE = 'Configuración';
export const ERROR_PASSWORDS_DO_NOT_MATCH = 'Las contraseñas no coinciden';
export const ERROR_INVALID_PASSWORD = 'La contraseña no puede estar vacía';
export const ERROR_CHANGE_PASSWORD = 'Hubo un error al cambiar la contraseña';
export const ERROR_TITLE = 'Error';
export const PASSWORD_CHANGE_SUCCESSFUL_TITLE = 'Contraseña cambiada';
export const PASSWORD_CHANGE_SUCCESSFUL_MESSAGE = 'Tu contraseña ha sido cambiada con éxito';
export const SETTINGS_SCREEN_CHANGE_PASSWORD_HEADER = 'Cambiar contraseña';
export const SETTINGS_SCREEN_CHANGE_PASSWORD_OLD = 'Contraseña actual';
export const SETTINGS_SCREEN_CHANGE_PASSWORD_NEW = 'Contraseña nueva';
export const SETTINGS_SCREEN_CHANGE_PASSWORD_CONFIRM = 'Confirmar contraseña';
export const SETTINGS_SCREEN_CHANGE_PASSWORD_BUTTON = 'Cambiar contraseña';
export const CONFIGURATION_SCREEN_TITLE = 'Configuración';
export const SETTINGS_SCREEN_INFORMATION = 'Tu información';
export const SETTINGS_SCREEN_SETTINGS_CONFIG = 'Ajustes';
export const SETTINGS_SCREEN_FACILITY_INFO = 'Información del centro';
export const LAST_TIME_SIGN_IN = 'Último inicio de sesión: ';