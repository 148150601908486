import { api, handleApiError } from "./api";
import { ClaimRequest } from "types/claims";

export function fetchClaimInfo(shiftId: number, shiftClaimId: number): Promise<ClaimRequest> {
  const uri = `/facility/portal/shifts/${shiftId}/claims/${shiftClaimId}`;
  return api
    .get(uri)
    .then(res => res.data)
    .catch(handleApiError);
}

export function shiftClaimAccept(
    shiftId: number,
    shiftClaimId: number,
    reason?: string,
    comment?: string,
  ) {
  
    const uri = `/facility/portal/shifts/${shiftId}/claims/${shiftClaimId}/accept`;
    
    return api
      .post(uri, reason ? { slotReason: reason, slotReasonComment: comment } : null)
      .then(res => res.data)
      .catch(handleApiError);
  }
  
  export function shiftClaimReject(shiftId: number, shiftClaimId: number, rejectReason: string, reasonDetail: string): Promise<boolean | void> {
    const uri = `/facility/portal/shifts/${shiftId}/claims/${shiftClaimId}/reject-claim`
    const body = {
      reason: rejectReason,
      reasonDetail
    };
  
    return api
      .post(uri, body)
      .then(res => res.status === 200)
      .catch((handleApiError))
  }

  export function fetchShiftClaimRejectReasons() {
    const uri = '/facility/common/shift-claim-reject-reasons';
    return api
      .get(uri)
      .then(res => res.data)
      .catch(handleApiError);
  }

  
  export function shiftCancellationResolve(
    shiftId: number,
    shiftClaimId: number,
  ) {
  
  const uri = `/facility/portal/shifts/${shiftId}/claims/${shiftClaimId}/resolve-cancellation`;
  
  return api
    .post(uri, null)
    .then(res => res.data)
    .catch(handleApiError);
}

export function shiftCancellationAccept(
  shiftId: number,
  shiftClaimId: number,
) {

  const uri = `/facility/portal/shifts/${shiftId}/claims/${shiftClaimId}/accept-cancellation`;

  return api
    .post(uri, null)
    .then(res => res.data)
    .catch(handleApiError);
}

export function updateClaimSlotReason(
  shiftId: number,
  shiftClaimId: number,
  reason: string,
  comment: string,
) {

  const uri = `/facility/portal/shifts/${shiftId}/claims/${shiftClaimId}/update-reason`;

  return api
    .post(uri, {
      slotReason: reason,
      slotReasonComment: comment
    })
    .then(res => res.data)
    .catch(handleApiError);
}
export function deleteClaimRequest(
  shiftId: number,
  shiftClaimId: number,
  reason: string,
) {

  const uri = `/facility/portal/shifts/${shiftId}/claims/${shiftClaimId}/cancel-approved-claim`;
  const body = { reason } ;
  return api
    .post(uri, body)
    .then(res => res.data)
    .catch(handleApiError);
}
